import { UPDATE_PASSWORD_SIZE, UPDATE_PASSWORD_CLEAR, UPDATE_PASSWORD_CONTAINERS_SIZE } from '../constants/password.constants';

function updatePasswordSize(passwordTemplate) {
  const { currentPassword, passwordHolder } = passwordTemplate.data;
  return (dispatch) => {
    dispatch({
      type: UPDATE_PASSWORD_SIZE,
      payload: {
        currentPassword,
        passwordHolder,
        passwordResult: passwordTemplate.template(passwordTemplate.data),
        passwordFinal: passwordTemplate.data.passwordFinal,
        chancesLeft: passwordTemplate.data.chancesLeft,
      },
    });
  };
}

function updatePasswordClear() {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PASSWORD_CLEAR,
    });
  };
}

function updatePasswordContainersSize() {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PASSWORD_CONTAINERS_SIZE,
    });
  };
}

export { updatePasswordSize, updatePasswordClear, updatePasswordContainersSize };
