import usernameHoldersList from '../../constants/username.constants';
import { UPDATE_USERNAME_SIZE, UPDATE_USERNAME_CLEAR, UPDATE_USERNAME_CONTAINERS_SIZE } from '../constants/username.constants';

const initialData = {
  currentUsername: '',
  usernameResults: [[]],
  usernameHolders: [[usernameHoldersList.username]],
  usernameSize: [1],
};

const usernameReducer = (state = initialData, action) => {
  switch (action.type) {
    case UPDATE_USERNAME_SIZE: {
      const { currentUsername, usernameFinal } = action.payload;
      let { usernameResult, usernameHolder } = action.payload;
      const usernameSize = [...state.usernameSize];
      const usernamePos = usernameSize.length - 1;
      const size = usernameSize[usernamePos];
      const usernameHolders = [...state.usernameHolders];
      const usernameResults = [...state.usernameResults];

      if (!usernameHolder && usernameHolder !== '') usernameHolder = usernameHolders[usernamePos][usernameHolders[usernamePos].length - 1];
      if (!usernameResult) usernameResult = '';

      usernameResults[usernamePos].push(usernameResult);
      if (usernameFinal) {
        return {
          ...state,
          currentUsername: !currentUsername ? state.currentUsername : currentUsername,
          usernameHolders,
          usernameResults,
          usernameSize,
        };
      }

      usernameSize[usernamePos] = size + 1;
      usernameHolders[usernamePos].push(usernameHolder);

      return {
        ...state,
        currentUsername: !currentUsername ? state.currentUsername : currentUsername,
        usernameHolders,
        usernameResults,
        usernameSize,
      };
    }
    case UPDATE_USERNAME_CONTAINERS_SIZE: {
      const usernameSize = [...state.usernameSize];
      const usernameHolders = [...state.usernameHolders];
      const usernameResults = [...state.usernameResults];

      usernameSize.push(1);
      usernameHolders.push([usernameHoldersList.username]);
      usernameResults.push([]);
      return {
        currentUsername: '',
        usernameResults,
        usernameHolders,
        usernameSize,
      };
    }
    case UPDATE_USERNAME_CLEAR: {
      return {
        currentUsername: '',
        usernameResults: [[]],
        usernameHolders: [[usernameHoldersList.username]],
        usernameSize: [1],
      };
    }
    default:
      return { ...state };
  }
};

export default usernameReducer;
