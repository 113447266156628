import { getDirectoryByPath, getFile } from '../../../services/directory.service';
import { allStatusCode, availableStatus } from '../../../constants/status.constants';
import { EmailStart } from '../../../templates/phaseOne/email';
import { CatCorrectArg, CatFalseArgs, CatFolderArg, CatNoArgs, CatAccessDeny, CatAudioArgs } from '../../../templates/phaseZero/cat';
import { ExceptionTemplate } from '../../../templates/exceptions';

const { lsStatusCode } = allStatusCode;

class Cat {
  constructor(command, parameters) {
    this.command = command;
    this.parameters = parameters;
  }

  catExecuteDot(currentPath) {
    const currentPaths = currentPath.split('/');
    const argumentPaths = this.parameters[0].split('/');

    argumentPaths.map((paths, index) => {
      switch (paths) {
        case '.':
          argumentPaths[index] = '';
          break;
        case '..':
          if (currentPaths.length > 0) {
            currentPaths.pop();
          }
          argumentPaths[index] = '';
          break;
        default:
          return { currentPaths, argumentPaths };
      }
      return { currentPaths, argumentPaths };
    });
    return { currentPaths, argumentPaths };
  }

  async catWithArgument(current, userRole, accessToken, isAuthorized) {
    let currentPath = current;
    let res = null;
    let isFolder = null;
    let argument = this.parameters[0];
    let roleAccess = false;

    if (argument.slice(0, 3) === '...') {
      return {
        template: CatFalseArgs,
        data: {
          argument,
        },
      };
    }

    const path = this.catExecuteDot(currentPath);
    currentPath = '';
    argument = '';
    const argumentTmp = path.argumentPaths.filter((i) => i);
    const currentPathTmp = path.currentPaths.filter((i) => i);
    const isLastPathEmpty = currentPathTmp.length === 0;
    const firstArgument = argumentTmp[0];
    let checkEmail = false;
    let role = userRole;
    if (!isAuthorized && role === 3) {
      role = 2;
    }
    while (argumentTmp.length > 1) {
      currentPathTmp.push(argumentTmp.shift());
    }
    currentPathTmp.forEach((paths) => {
      currentPath = `${currentPath}/${paths}`;
    });
    argumentTmp.forEach((paths) => {
      argument = `${argument}/${paths}`;
    });
    if (argument !== '') {
      argument = argument.slice(1);
    }
    if (currentPath !== '') {
      currentPath = currentPath.slice(1);
    }
    if (argument.slice(0, 1) === '.') {
      return {
        template: CatFalseArgs,
        data: {
          argument,
        },
      };
    }
    if (argument === '') {
      return {
        template: CatFolderArg,
        data: {
          directory: this.parameters[0],
        },
      };
    }
    let template = {
      template: CatFalseArgs,
      data: {
        argument,
      },
    };
    if (argument !== '') {
      if ((firstArgument === 'guest' && current === '') || (firstArgument === 'guest' && isLastPathEmpty)) {
        checkEmail = true;
      }
      res = await getDirectoryByPath(currentPath, accessToken, isAuthorized);
      const promise = new Promise((resolve, reject) => {
        try {
          if (res && !availableStatus.includes(res.status)) {
            template = {
              template: ExceptionTemplate,
              data: {},
            };
          } else if (res === undefined || res.status === lsStatusCode.unauthorized || res.status === lsStatusCode.notfound) {
            template = {
              template: CatFalseArgs,
              data: {
                argument,
              },
            };
            if (checkEmail && role === 0) {
              template = {
                template: EmailStart,
                data: {
                  accessDenied: true,
                },
              };
            } else if (res !== undefined && res.status === lsStatusCode.unauthorized) {
              template = {
                template: CatAccessDeny,
                data: {
                  argument,
                },
              };
            }
          } else {
            res?.data?.forEach((f) => {
              if (f.name === argument && f.is_folder) {
                isFolder = true;
                if (f.role <= role) {
                  roleAccess = true;
                }
              } else if (f.name === argument && !f.isFolder) {
                isFolder = false;
              }
            });
          }
          resolve(isFolder);
        } catch (error) {
          reject(error);
        }
      });
      promise.then((resp) => {
        isFolder = resp;
      });
      if (isFolder) {
        if (roleAccess) {
          template = {
            template: CatFolderArg,
            data: {
              directory: argument,
            },
          };
        } else if (checkEmail && role === 0) {
          template = {
            template: EmailStart,
            data: {
              accessDenied: true,
            },
          };
        } else {
          template = {
            template: CatAccessDeny,
            data: {
              argument,
            },
          };
        }
      } else if (isFolder === false) {
        await getFile(`${currentPath}/${argument}`, accessToken, isAuthorized).then((result) => {
          const { data } = result;
          if (data.is_audio_file) {
            template = {
              template: CatAudioArgs,
              data: {
                argument,
              },
            };
          } else {
            template = {
              template: CatCorrectArg,
              data: {
                fileContent: data.content,
              },
            };
          }
        });
      }
    }
    return template;
  }

  async execCommand(args) {
    const { currentPath, userRole, accessToken, isAuthorized } = args;
    let template = {
      template: () => {},
      data: {},
    };
    if (this.parameters.length === 0) {
      template = {
        template: CatNoArgs,
        data: {},
      };
    } else {
      template = await this.catWithArgument(currentPath, userRole, accessToken, isAuthorized);
    }
    return template;
  }
}

export default Cat;
