import axios from './authAxios';
import { Options } from '../interfaces/services.interface';
import getOptions from '../utils/services.utils';

export async function getAllProcesses(accessToken: string): Promise<unknown> {
  const backendUrl = '/process/ps/';
  const options: Options = getOptions(accessToken);

  try {
    const res = await axios(options).get(backendUrl);
    return res;
  } catch (error) {
    return error.response?.data;
  }
}

export async function killAProcess(pid: string, accessToken: string, isRootAccess = false): Promise<unknown> {
  const backendUrl = '/process/kill/';
  const options: Options = getOptions(accessToken, isRootAccess);

  try {
    const res = await axios(options).post(backendUrl, { pid });
    return res;
  } catch (error) {
    return error.response;
  }
}

export async function checkProcessKilled(accessToken: string): Promise<unknown> {
  const backendUrl = '/process/check/';
  const options: Options = getOptions(accessToken);

  try {
    const res = await axios(options).get(backendUrl);
    return res;
  } catch (error) {
    return error.response;
  }
}
