import axios from './authAxios';
import { Options } from '../interfaces/services.interface';
import getOptions from '../utils/services.utils';

export default async function moveFile(file: string, destination: string, accessToken: string, isRootAccess = false): Promise<unknown> {
  const backendUrl = `directory/file/move/`;
  const options: Options = getOptions(accessToken, isRootAccess);

  try {
    const res = await axios(options).post(backendUrl, { file, destination });
    return res;
  } catch (error) {
    return error.response;
  }
}
