import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../../components/Input';
import { PromptContainer } from '../../styles';
import { updateUsernameContainersSize, updateUsernameSize } from '../../../../redux/actions/username.actions';
import { updateEmptyTerminal } from '../../../../redux/actions/terminal.actions';
import { clearUserData, updateUserRole } from '../../../../redux/actions/user.actions';
import Username from '../../../../models/commands/phaseTwo/Username';

const UsernameContainers = React.forwardRef((props, ref) => {
  const { usernamePosition, isInputUsername, setIsInputUsername } = props;
  const { terminal } = useSelector((state) => state.terminalReducer);
  const { accessToken, refreshToken } = useSelector((state) => state.userReducer);
  const { currentEmail } = useSelector((state) => state.emailReducer);
  const { offset } = terminal.terminalSize;
  const dispatch = useDispatch();
  const { currentUsername, usernameHolders, usernameResults, usernameSize } = useSelector((state) => state.usernameReducer);

  function handleUsernameControlCPressed() {
    const tempUsername = [...isInputUsername];
    tempUsername[tempUsername.length - 1] = true;
    setIsInputUsername(tempUsername);
    dispatch(updateUsernameContainersSize());
    dispatch(updateEmptyTerminal());
  }

  async function handleUsernameInput(textContent) {
    const username = new Username(textContent, currentEmail);
    const templateResult = await username.execCommand({ username: currentUsername, accessToken });
    if (templateResult.data.isInvalid) {
      dispatch(updateUsernameSize(templateResult));
      handleUsernameControlCPressed();
    } else if (templateResult.data.usernameFinal && templateResult.data.isValid) {
      if (templateResult.data.isResetSession) {
        dispatch(clearUserData(accessToken, refreshToken));
      }
      dispatch(updateUsernameSize(templateResult));
      dispatch(updateUserRole(accessToken, false));
      handleUsernameControlCPressed();
    } else {
      dispatch(updateUsernameSize(templateResult));
    }
  }

  function renderUsername() {
    const newArray = [];
    for (let index = 0; index < usernameSize[usernamePosition]; index += 1) {
      const inputUsernameEle = (
        <Fragment key={`input-username-${index}-${offset}`}>
          <Input
            type="text"
            ref={ref}
            textHolder={usernameHolders[usernamePosition][index]}
            onHandleCommand={handleUsernameInput}
            onControlCPressed={handleUsernameControlCPressed}
          />
          <PromptContainer>{usernameResults[usernamePosition][index]}</PromptContainer>
        </Fragment>
      );
      newArray.push(inputUsernameEle);
    }
    return newArray;
  }

  return <>{renderUsername()}</>;
});

export default UsernameContainers;
