import { combineReducers } from 'redux';
import terminalReducer from './reducers/terminal.reducer';
import emailReducer from './reducers/email.reducer';
import userReducer from './reducers/user.reducer';
import passwordReducer from './reducers/password.reducer';
import usernameReducer from './reducers/username.reducer';
import directoryReducer from './reducers/directory.reducer';

const rootReducer = combineReducers({
  terminalReducer,
  emailReducer,
  userReducer,
  passwordReducer,
  usernameReducer,
  directoryReducer,
});

export default rootReducer;
