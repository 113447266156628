import { ReactNode } from 'react';
import { FlexContainer, ImportantText, HiddenText } from '../../pages/MainPage/styles';

const LoginCorrectArg = ({ passwords }: { passwords: string[] }): ReactNode => (
  <div>
    <b>Challenge: Find the hidden password</b>
    <p>Out of {passwords.length} passwords below , only ONE can be used to login.</p>
    <p>The correct password must:</p>
    <ol>
      <li>Contain 3 letters and 4 numbers</li>
      <li>
        Each letter must be 5 characters away in the alphabetical order. For example: JXD (...W
        <ImportantText>X</ImportantText>
        YZABC
        <ImportantText>D</ImportantText>
        EFGHI
        <ImportantText>J</ImportantText>
        KLMNOP...)
      </li>
      <li>
        Sum of 4 numbers in the range [0, 9] should be:
        <ul>
          <li>Even, if there is at least 1 letter is vowel</li>
          <li>Odd, if there is no vowel</li>
        </ul>
      </li>
    </ol>
    <p>List of passwords:</p>
    <HiddenText>Caution! Your computer is being exposed to malicious software.</HiddenText>
    <HiddenText>Data is being sent to a server.</HiddenText>
    <FlexContainer
      onPaste={(e) => {
        e.preventDefault();
        return false;
      }}
      onCopy={(e) => {
        e.preventDefault();
        return false;
      }}
    >
      {passwords?.map((password, idx) => (
        <p key={`password-${password}-${idx}`}>{password}</p>
      ))}
    </FlexContainer>
  </div>
);

const LoginUnauth = (): ReactNode => <>login: Unauthorized access</>;

const LoginFalseArg = (): ReactNode => <>login: Operation is no longer valid</>;

const LoginTooManyArg = (): ReactNode => <>login: Too many arguments</>;

export { LoginCorrectArg, LoginFalseArg, LoginUnauth, LoginTooManyArg };
