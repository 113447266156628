import styled from 'styled-components';
import colors from '../../constants/colors';

const TerminalPageContainer = styled.div`
  width: 100%;
  height: 100vh;
  padding: 20px;
  color: ${colors.white};
  overflow: ${(props) => (props.isEditing ? 'hidden' : 'unset')};
`;

const TextContainer = styled.div`
  h3 {
    font-weight: 600;
    margin-bottom: 10px;
  }

  h5 {
    color: ${colors.red};
  }
`;

const InputContainer = styled.div`
  position: relative;
  margin: 4px 0;
  height: 60%;
  color: ${colors.textColor};
  word-break: break-all;
  white-space: pre-wrap;
  outline: none;
  &:focus-visible,
  &:focus {
    outline: none;
  }
  &:before {
    content: ${(props) => `'${props.textHolder}'`};
  }

  &:empty {
    padding-left: ${(props) => `${0.6 * props.textLength}rem`};
  }
  &:empty:before {
    display: inline-block;
    margin-left: ${(props) => `-${0.6 * props.textLength}rem`};
    text-indent: 0;
  }
`;

const PromptContainer = styled.div`
  color: ${colors.resultColor};
  margin: 4px 0;
  word-break: break-all;
  white-space: pre-wrap;
`;

const TerminalContainer = styled.div`
  display: flex;
`;

const GridContainer = styled.div`
  display: grid;
  width: 100%;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  @media (min-width: 576px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (min-width: 992px) {
    width: 70%;
  }
`;

const GridColumnContainer = styled.div`
  display: grid;
  width: 100%;
  gap: 5px;
  grid-auto-flow: column;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(2, 1fr);
  @media (min-width: 576px) {
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 992px) {
    width: 80%;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > p {
    margin-right: 0.5rem;
  }
`;

const ImportantText = styled.b`
  color: ${colors.yellow};
`;

const Table = styled.table`
  border-collapse: collapse;
  th {
    font-weight: normal;
  }
  th,
  td {
    padding: 0 0.4rem;
  }
`;

const TableMini = styled(Table)`
  thead {
    tr th:nth-child(odd) {
      text-align: right;
    }
    tr th:nth-child(even) {
      text-align: left;
    }
    tr th:nth-child(2) {
      padding-right: 1.8rem;
    }
  }

  tbody {
    tr td:nth-child(odd) {
      text-align: right;
    }
    tr td:nth-child(even) {
      text-align: left;
    }
    tr td:nth-child(2) {
      padding-right: 1.8rem;
    }
  }
`;

const TableLarge = styled(Table)`
  th,
  td {
    padding: 0 0.8rem;
  }
  thead {
    tr th:first-child {
      padding-left: 0;
    }
    tr th:nth-child(odd),
    tr th:nth-child(even) {
      text-align: right;
    }
    tr th:nth-child(1),
    tr th:nth-child(5),
    tr th:nth-child(8),
    tr th:nth-child(11) {
      text-align: left;
    }
  }

  tbody {
    tr td:first-child {
      padding-left: 0;
    }
    tr td:nth-child(odd),
    tr td:nth-child(even) {
      text-align: right;
    }
    tr td:nth-child(1),
    tr td:nth-child(5),
    tr td:nth-child(8),
    tr td:nth-child(11) {
      text-align: left;
    }
  }
`;

const HiddenText = styled.p`
  font-weight: bold;
  display: none;
`;

const RankingTable = styled(Table)`
  border-collapse: separate;
  th,
  td {
    padding: 0.5rem 0.6rem;
    max-width: 10rem;
  }
  thead {
    th:first-child,
    th:nth-child(2) {
      text-align: left;
    }
    th:nth-child(2) {
      display: none;
    }
    th {
      padding-top: 0;
      border-bottom: 1px dashed ${colors.white};
      text-transform: capitalize;
    }
  }

  thead {
    th:first-child,
    th:nth-child(2),
    th:nth-last-child(2) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  tbody {
    tr td:first-child {
      padding-left: 0;
      padding-right: 0;
    }

    tr td:nth-last-child(2) {
      text-align: center;
    }

    td {
      vertical-align: top;
    }
  }

  @media (min-width: 420px) {
    tbody {
      tr td:first-child {
        padding-right: 1.2rem;
      }
    }
  }
  @media (min-width: 500px) {
    th,
    td {
      max-width: unset;
    }

    thead {
      th:last-child {
        padding-right: 1.5em;
        padding-left: 3em;
      }
    }

    tbody {
      tr td:last-child {
        padding-right: 1.5em;
        padding-left: 3em;
      }
    }
  }
  @media (min-width: 576px) {
    thead tr th:first-child {
      display: none;
    }
    thead tr th:nth-child(2) {
      display: initial;
    }
  }

  @media (min-width: 768px) {
    th,
    td {
      padding: 0.5rem 4rem;
    }
    tr td:nth-last-child(2) {
      padding: 0.5rem 0rem;
    }
  }
`;

export {
  TerminalPageContainer,
  TextContainer,
  InputContainer,
  PromptContainer,
  TerminalContainer,
  GridContainer,
  GridColumnContainer,
  FlexContainer,
  ImportantText,
  Table,
  TableMini,
  TableLarge,
  HiddenText,
  RankingTable,
};
