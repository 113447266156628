import { RankingTable } from '../../pages/MainPage/styles';

const WIN = 'win';
const WIN_YES = 'Yes';
const WIN_NO = 'No';

const getRankValue = (key, value) => {
  if (key === WIN) {
    if (value) return WIN_YES;
    return WIN_NO;
  }
  return value;
};

const RankingCorrectArg = ({ rankingList }) => {
  if (rankingList.length !== 0) {
    const rankingKeys = Object.keys(rankingList[0]);
    return (
      <RankingTable>
        <thead>
          <tr>
            <th>#</th>
            <th>Ranking</th>
            <th>Username</th>
            <th>Win</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {rankingList.map((rank, rankIdx) => {
            if (rank) {
              return (
                <tr key={rankIdx}>
                  {rankingKeys.map((key) => {
                    return <td key={`rank-${rank[key]}`}>{getRankValue(key, rank[key])}</td>;
                  })}
                </tr>
              );
            }
            return (
              <tr key={rankIdx}>
                <td>...</td>
                <td />
                <td />
                <td />
              </tr>
            );
          })}
        </tbody>
      </RankingTable>
    );
  }
  return (
    <RankingTable>
      <thead>
        <tr>
          <th>#</th>
          <th>Ranking</th>
          <th>Username</th>
          <th>Win</th>
          <th>Time</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan="3">No user yet</td>
        </tr>
      </tbody>
    </RankingTable>
  );
};

const RankingTooManyArg = () => <>ranking: Too many arguments</>;

export { RankingCorrectArg, RankingTooManyArg };
