import React, { useEffect } from 'react';
import disableDefaultEvent from '../utils/event.utils';
import {
  handleKeyUpCallback,
  handleOnClickFocusCallback,
  handlePasteCallback,
  inputCallbackCreator,
  removePreviousInputEvent,
} from '../utils/input.callback';
import { InputContainer } from '../pages/MainPage/styles';

const Input = React.forwardRef((props, ref) => {
  const { onHandleCommand, onControlCPressed, onControlXPressed, onHandleTab, onArrowUpPressed, onArrowDownPressed } = props;
  const { textHolder } = props;

  function handleControlC(e) {
    if ((e.metaKey || e.ctrlKey) && String.fromCharCode(e.which).toLowerCase() === 'c') {
      e.preventDefault();
      if (onControlCPressed) {
        const commandText = ref.current.textContent;
        removePreviousInputEvent(ref);
        onControlCPressed(commandText);
      }
    }
  }

  function handleControlX(e) {
    if ((e.metaKey || e.ctrlKey) && String.fromCharCode(e.which).toLowerCase() === 'x') {
      e.preventDefault();
      if (onControlXPressed) {
        const commandText = ref.current.textContent;
        removePreviousInputEvent(ref);
        onControlXPressed(commandText);
      }
    }
  }

  async function handleKeyDown(e) {
    switch (true) {
      // Combinations
      case e.ctrlKey && String.fromCharCode(e.which).toLowerCase() === 'c': {
        // Control+C
        handleControlC(e);
        break;
      }
      case e.ctrlKey && String.fromCharCode(e.which).toLowerCase() === 'x': {
        // Control+X
        handleControlX(e);
        break;
      }
      case e.key === 'Enter': {
        const commandText = ref.current.textContent;
        e.preventDefault();
        removePreviousInputEvent(ref);
        if (props.onInput) props.onInput('');
        if (onHandleCommand) await onHandleCommand(commandText);
        break;
      }
      case e.key === 'ArrowUp':
        disableDefaultEvent(e);
        if (onArrowUpPressed) onArrowUpPressed(e);
        break;
      case e.key === 'ArrowDown':
        disableDefaultEvent(e);
        if (onArrowDownPressed) onArrowDownPressed(e);
        break;
      case e.key === 'ArrowRight':
        handleKeyUpCallback(ref);
        break;
      case e.key === 'ArrowLeft':
        handleKeyUpCallback(ref);
        break;
      case e.key === 'Tab': {
        e.preventDefault();
        const commandText = ref.current.textContent;
        if (!commandText && onHandleTab) {
          removePreviousInputEvent(ref);
          onHandleTab(commandText, 'tab');
        } else if (onHandleTab) {
          onHandleTab(commandText, 'tab');
        }
        break;
      }
      default:
        if (onHandleTab) {
          onHandleTab('', 'breakTabComplete');
        }
        break;
    }
  }

  function handleSetInput(e) {
    if (props.onInput) props.onInput(e.currentTarget.textContent);
  }

  useEffect(() => {
    inputCallbackCreator(ref);
  }, [ref]);

  return (
    <InputContainer
      contentEditable="true"
      autoCapitalize="off"
      spellCheck="false"
      autoComplete="off"
      ref={ref}
      onInput={handleSetInput}
      onKeyDown={handleKeyDown}
      onKeyUp={() => handleKeyUpCallback(ref)}
      onPaste={handlePasteCallback}
      textLength={textHolder.length}
      textHolder={textHolder}
      onMouseDown={() => handleOnClickFocusCallback(ref)}
      onClick={() => handleOnClickFocusCallback(ref)}
    />
  );
});

export default Input;
