import { allStatusCode } from '../../../constants/status.constants';
import { getAllProcesses } from '../../../services/process.service';
import { PsCorrectArg, PsFalseArg, PsNoArg, PsErrorArg, PsTwoManyArg } from '../../../templates/phaseOne/ps';

const { psStatusCode } = allStatusCode;

class Ps {
  constructor(command, parameters) {
    this.command = command;
    this.parameters = parameters.map((parameter) => parameter.toLowerCase());
  }

  async execCommand(args) {
    const { accessToken } = args;
    if (this.parameters.length === 1 && this.parameters[0] === 'aux') {
      const res = await getAllProcesses(accessToken);
      if (res && res.status === psStatusCode.success) {
        return {
          template: PsCorrectArg,
          data: {
            allProcesses: res.data?.processes,
          },
        };
      }
      return {
        template: PsErrorArg,
        data: {},
      };
    }
    if (this.parameters.length === 0) {
      return {
        template: PsNoArg,
        data: {},
      };
    }
    if (this.parameters.length > 1 && this.parameters[0] === 'aux') {
      return {
        template: PsTwoManyArg,
        data: {},
      };
    }
    return {
      template: PsFalseArg,
      data: {
        argument: this.parameters[0],
      },
    };
  }
}

export default Ps;
