import { UPDATE_TERMINAL_CLEAR, UPDATE_CURRENT_PROMPT, UPDATE_EMPTY_TERMINAL, UPDATE_COMMAND_AND_RESULTS } from '../constants/terminal.constants';

const initialData = {
  currentPhase: 0,
  emailPos: [],
  passwordPos: [],
  usernamePos: [],
  terminal: {
    terminalSize: {
      size: 1,
      offset: 0,
    },
    directoryString: [''],
    commandAndResults: {
      commands: [],
      results: [],
      trackableCommands: [],
    },
  },
  isCleared: false,
};

const terminalReducer = (state = initialData, action) => {
  switch (action.type) {
    case UPDATE_TERMINAL_CLEAR: {
      const { currentCommand, isClearCmd } = action.payload;
      const terminal = { ...state.terminal };
      const { terminalSize } = terminal;
      const trackableCommands = [...terminal.commandAndResults.trackableCommands];
      const directoryString = [...state.terminal.directoryString];
      const directoryFinalString = directoryString[directoryString.length - 1];

      if (currentCommand.trim() !== '') {
        trackableCommands.push(currentCommand);
      }
      return {
        ...state,
        emailPos: [],
        passwordPos: [],
        usernamePos: [],
        isCleared: isClearCmd,
        terminal: {
          ...state.terminal,
          directoryString: [directoryFinalString],
          commandAndResults: {
            results: [],
            commands: [],
            trackableCommands,
          },
          terminalSize: {
            size: 1,
            offset: terminalSize.offset + 1,
          },
        },
      };
    }
    case UPDATE_COMMAND_AND_RESULTS: {
      const { currentCommand, templateResult, type } = action.payload;
      const terminal = { ...state.terminal };
      const emailPos = [...state.emailPos];
      const reversedEmailPos = [...emailPos].reverse();

      const passwordPos = [...state.passwordPos];
      const reversedPasswordPos = [...passwordPos].reverse();

      const usernamePos = [...state.usernamePos];
      const reversedUsernamePos = [...usernamePos].reverse();

      const results = [...terminal.commandAndResults.results];
      const commands = [...terminal.commandAndResults.commands];
      const trackableCommands = [...terminal.commandAndResults.trackableCommands];
      if (type === 'email') {
        if (emailPos.length === 0) {
          results.push(templateResult.template(templateResult.data));
          emailPos.push(0);
          passwordPos.push(-1);
          usernamePos.push(-1);
        } else {
          results.push(templateResult.template(templateResult.data));
          const emailContainerIdx = reversedEmailPos.findIndex((pos) => pos !== -1);
          if (emailContainerIdx === -1) {
            emailPos.push(0);
            passwordPos.push(-1);
            usernamePos.push(-1);
          } else {
            passwordPos.push(-1);
            usernamePos.push(-1);
            emailPos.push(reversedEmailPos[emailContainerIdx] + 1);
          }
        }
      } else if (type === 'password') {
        if (passwordPos.length === 0) {
          results.push(templateResult.template(templateResult.data));
          passwordPos.push(0);
          emailPos.push(-1);
          usernamePos.push(-1);
        } else {
          results.push(templateResult.template(templateResult.data));
          const passwordContainerIdx = reversedPasswordPos.findIndex((pos) => pos !== -1);
          if (passwordContainerIdx === -1) {
            emailPos.push(-1);
            usernamePos.push(-1);
            passwordPos.push(0);
          } else {
            emailPos.push(-1);
            usernamePos.push(-1);
            passwordPos.push(reversedPasswordPos[passwordContainerIdx] + 1);
          }
        }
      } else if (type === 'password-error') {
        results.push(templateResult.template(templateResult.data));
        passwordPos.push(-1);
        emailPos.push(-1);
        usernamePos.push(-1);
      } else if (type === 'username') {
        if (usernamePos.length === 0) {
          results.push(templateResult.template(templateResult.data));
          usernamePos.push(0);
          emailPos.push(-1);
          passwordPos.push(-1);
        } else {
          results.push(templateResult.template(templateResult.data));
          const usernameContainerIdx = reversedUsernamePos.findIndex((pos) => pos !== -1);
          if (usernameContainerIdx === -1) {
            emailPos.push(-1);
            passwordPos.push(-1);
            usernamePos.push(0);
          } else {
            emailPos.push(-1);
            passwordPos.push(-1);
            usernamePos.push(reversedUsernamePos[usernameContainerIdx] + 1);
          }
        }
      }
      if (currentCommand.trim() !== '') {
        trackableCommands.push(currentCommand);
      }
      commands.push(currentCommand);
      return {
        ...state,
        emailPos,
        passwordPos,
        usernamePos,
        terminal: {
          ...terminal,
          commandAndResults: {
            commands,
            results,
            trackableCommands,
          },
        },
      };
    }
    case UPDATE_CURRENT_PROMPT: {
      const { currentCommand, command, result, template } = action.payload;
      const { terminalSize } = state.terminal;
      const emailPos = [...state.emailPos];
      const passwordPos = [...state.passwordPos];
      const usernamePos = [...state.usernamePos];

      const results = [...state.terminal.commandAndResults.results];
      const commands = [...state.terminal.commandAndResults.commands];
      const trackableCommands = [...state.terminal.commandAndResults.trackableCommands];
      const directoryString = [...state.terminal.directoryString];
      directoryString.push(directoryString[directoryString.length - 1]);

      emailPos.push(-1);
      passwordPos.push(-1);
      usernamePos.push(-1);

      if (
        (command.command.toLowerCase() === 'cd' && command.acceptCdCommand === true) ||
        (command.command === 'sudo' && command.sudoCmd === 'cd' && template.data.acceptCdCommand)
      ) {
        directoryString[directoryString.length - 1] = template.data.argument;
        results.push('');
        commands.push(currentCommand);
        if (currentCommand.trim() !== '') {
          trackableCommands.push(currentCommand);
        }
        return {
          ...state,
          emailPos,
          passwordPos,
          usernamePos,
          terminal: {
            terminalSize: {
              ...terminalSize,
              size: terminalSize.size + 1,
            },
            directoryString,
            commandAndResults: {
              commands,
              results,
              trackableCommands,
            },
          },
        };
      }
      results.push(result);
      commands.push(currentCommand);
      if (currentCommand.trim() !== '') {
        trackableCommands.push(currentCommand);
      }
      return {
        ...state,
        emailPos,
        passwordPos,
        usernamePos,
        terminal: {
          terminalSize: {
            ...terminalSize,
            size: terminalSize.size + 1,
          },
          directoryString,
          commandAndResults: {
            commands,
            results,
            trackableCommands,
          },
        },
      };
    }
    case UPDATE_EMPTY_TERMINAL: {
      const directoryString = [...state.terminal.directoryString];
      const terminalSize = { ...state.terminal.terminalSize };
      directoryString.push(directoryString[directoryString.length - 1]);
      return {
        ...state,
        terminal: {
          ...state.terminal,
          terminalSize: {
            ...terminalSize,
            size: terminalSize.size + 1,
          },
          directoryString,
        },
      };
    }
    default:
      return { ...state };
  }
};

export default terminalReducer;
