import { HelpCorrectArgs, HelpDefault, HelpFalseArgs } from '../../../templates/phaseZero/help';
import getUsableCommands from '../../../utils/command.utils';

class Help {
  constructor(command, parameters) {
    this.command = command;
    this.parameters = parameters.map((parameter) => parameter.toLowerCase());
  }

  execCommand(args) {
    const { userRole } = args;
    const usableCommands = getUsableCommands(userRole);
    const { description } = usableCommands;
    if (this.parameters.length === 0) {
      return {
        template: HelpDefault,
        data: {
          commands: Object.keys(description).sort((itemA, itemB) => itemA.localeCompare(itemB)),
        },
      };
    }
    if (Object.keys(description).find((command) => command === this.parameters[0])) {
      return {
        template: HelpCorrectArgs,
        data: {
          description: description[this.parameters[0]],
        },
      };
    }
    return {
      template: HelpFalseArgs,
      data: {
        parameter: this.parameters[0],
      },
    };
  }
}

export default Help;
