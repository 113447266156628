import { getDirectoryByPath, getFile } from '../../../services/directory.service';
import { availableStatus, allStatusCode } from '../../../constants/status.constants';
import {
  EditorAccessDeny,
  EditorAudioArgs,
  EditorCorrectArg,
  EditorFalseArgs,
  EditorFolderArg,
  EditorNoArg,
} from '../../../templates/phaseTwo/editor';
import { ExceptionTemplate } from '../../../templates/exceptions';

const { lsStatusCode } = allStatusCode;

class Editor {
  constructor(command, parameters) {
    this.command = command;
    this.parameters = parameters;
  }

  editorExecuteDot(currentPath) {
    const currentPaths = currentPath.split('/');
    const argumentPaths = this.parameters[0].split('/');

    argumentPaths.map((paths, index) => {
      switch (paths) {
        case '.':
          argumentPaths[index] = '';
          break;
        case '..':
          if (currentPaths.length > 0) {
            currentPaths.pop();
          }
          argumentPaths[index] = '';
          break;
        default:
          return { currentPaths, argumentPaths };
      }
      return { currentPaths, argumentPaths };
    });
    return { currentPaths, argumentPaths };
  }

  async editorWithArgument(current, userRole, accessToken, isAuthorized) {
    let currentPath = current;
    let res = null;
    let isFolder = null;
    let argument = this.parameters[0];

    if (argument.slice(0, 3) === '...') {
      return {
        template: EditorFalseArgs,
        data: {
          argument,
        },
      };
    }

    const path = this.editorExecuteDot(currentPath);
    currentPath = '';
    argument = '';
    const argumentTmp = path.argumentPaths.filter((i) => i);
    const currentPathTmp = path.currentPaths.filter((i) => i);
    let role = userRole;
    if (!isAuthorized && role === 3) {
      role = 2;
    }
    while (argumentTmp.length > 1) {
      currentPathTmp.push(argumentTmp.shift());
    }
    currentPathTmp.forEach((paths) => {
      currentPath = `${currentPath}/${paths}`;
    });
    argumentTmp.forEach((paths) => {
      argument = `${argument}/${paths}`;
    });
    if (argument !== '') {
      argument = argument.slice(1);
    }
    if (currentPath !== '') {
      currentPath = currentPath.slice(1);
    }
    if (argument.slice(0, 1) === '.') {
      return {
        template: EditorFalseArgs,
        data: {
          argument,
        },
      };
    }
    if (argument === '') {
      return {
        template: EditorFolderArg,
        data: {
          directory: this.parameters[0],
        },
      };
    }
    let template = {
      template: EditorFalseArgs,
      data: {
        argument,
      },
    };
    if (argument !== '') {
      res = await getDirectoryByPath(currentPath, accessToken, isAuthorized);
      const promise = new Promise((resolve, reject) => {
        try {
          if (res && !availableStatus.includes(res.status)) {
            template = {
              template: ExceptionTemplate,
              data: {},
            };
          } else if (res === undefined || res.status === lsStatusCode.unauthorized || res.status === lsStatusCode.notfound) {
            template = {
              template: EditorFalseArgs,
              data: {
                argument,
              },
            };

            if (res !== undefined && res.status === lsStatusCode.unauthorized) {
              template = {
                template: EditorAccessDeny,
                data: {
                  argument,
                },
              };
            }
          } else {
            res?.data?.forEach((f) => {
              if (f.name === argument && f.is_folder) {
                isFolder = true;
              } else if (f.name === argument && !f.isFolder) {
                isFolder = false;
              }
            });
          }
          resolve(isFolder);
        } catch (error) {
          reject(error);
        }
      });
      promise.then((resp) => {
        isFolder = resp;
      });
      if (isFolder) {
        template = {
          template: EditorFolderArg,
          data: {
            directory: argument,
          },
        };
      } else if (isFolder === false) {
        await getFile(`${currentPath}/${argument}`, accessToken, isAuthorized).then((result) => {
          const { data } = result;
          if (data.is_audio_file) {
            template = {
              template: EditorAudioArgs,
              data: {
                argument,
              },
            };
          } else {
            template = {
              template: EditorCorrectArg,
              data: {
                filePath: `${currentPath}/${argument}`,
                fileContent: data.content,
                isFileEditor: true,
                isAuthorized,
              },
            };
          }
        });
      }
    }
    return template;
  }

  async execCommand(args) {
    const { currentPath, userRole, accessToken, isAuthorized } = args;
    let template = {
      template: () => {},
      data: {},
    };
    if (this.parameters.length === 0) {
      template = {
        template: EditorNoArg,
        data: {},
      };
    } else {
      template = await this.editorWithArgument(currentPath, userRole, accessToken, isAuthorized);
    }
    return template;
  }
}

export default Editor;
