import { allStatusCode } from '../../../constants/status.constants';
import { checkGeneratedPassword } from '../../../services/login.service';
import { PasswordFalse, PasswordFalseFinal, PasswordSendError, PasswordValid } from '../../../templates/phaseOne/password';

const { passwordStatusCode } = allStatusCode;

class Password {
  constructor(inputFromUser) {
    this.inputFromUser = inputFromUser.trimStart().trimEnd();
  }

  async execCommand(args) {
    const { accessToken } = args;
    const password = this.inputFromUser;
    const template = {
      template: () => {},
      data: {
        passwordFinal: false,
      },
    };
    if (password.trim('') === '') return template;

    const res = await checkGeneratedPassword(password, accessToken);

    if (res) {
      if (res.status === passwordStatusCode.success) {
        return {
          template: PasswordValid,
          data: {
            passwordFinal: true,
            isValid: true,
          },
        };
      }
      if (res.status === passwordStatusCode.preconditionfailed) {
        return {
          template: PasswordFalse,
          data: {
            chancesLeft: res.data.chanceLeft,
            passwordFinal: false,
          },
        };
      }
      if (res.status === passwordStatusCode.unauthorized) {
        return {
          template: PasswordFalseFinal,
          data: {
            chancesLeft: 0,
            passwordFinal: true,
            isInvalid: true,
          },
        };
      }
    }
    return {
      template: PasswordSendError,
      data: {
        passwordFinal: false,
      },
    };
  }
}

export default Password;
