import { MvFalseArg, MvAccessDeny, MvNotModified, MvNotMoved, MvCorrectArg } from '../../../templates/phaseOne/mv';
import { allStatusCode, availableStatus } from '../../../constants/status.constants';
import { checkFileInAdmin, getDirectoryByPath } from '../../../services/directory.service';
import moveFile from '../../../services/move.service';
import { fileToBeTransferred, adminFolder, restrictedFolder } from '../../../constants/directotry.constants';
import { ExceptionTemplate } from '../../../templates/exceptions';
import moveAFileEvent from '../../../utils/dataLayer/moveAFile';

const { lsStatusCode } = allStatusCode;

class Mv {
  constructor(command, parameters) {
    this.command = command;
    this.parameters = parameters.map((parameter) => parameter.toLowerCase());
  }

  static mvExecuteDot(currentPath, argument) {
    const currentPaths = currentPath.split('/');
    const argumentPaths = argument.split('/');
    argumentPaths.map((paths, index) => {
      switch (paths) {
        case '.':
          argumentPaths[index] = '';
          break;
        case '..':
          if (currentPaths.length > 0) {
            currentPaths.pop();
          }
          argumentPaths[index] = '';
          break;
        default:
          return { currentPaths, argumentPaths };
      }
      return { currentPaths, argumentPaths };
    });
    return { currentPaths, argumentPaths };
  }

  static mvExecutePaths(path) {
    let currentPath = '';
    let argument = '';
    const argumentTmp = path.argumentPaths.filter((i) => i);
    const currentPathTmp = path.currentPaths.filter((i) => i);
    while (argumentTmp.length > 1) {
      currentPathTmp.push(argumentTmp.shift());
    }
    currentPathTmp.forEach((paths) => {
      currentPath = `${currentPath}/${paths}`;
    });
    argumentTmp.forEach((paths) => {
      argument = `${argument}/${paths}`;
    });
    if (argument !== '') {
      argument = argument.slice(1);
    }
    if (currentPath !== '') {
      currentPath = currentPath.slice(1);
    }
    return { currentPath, argument };
  }

  async mvWithArgument(current, userRole, accessToken, isAuthorized) {
    let res = null;
    let promise1 = null;
    let promise2 = null;
    const currentPath = current;
    let roleAccess1 = true;
    let roleAccess2 = true;
    let argument1 = this.parameters[0];
    let argument2 = this.parameters[1];
    let arg1Correct = false;
    let arg2Correct = false;
    let role = userRole;
    if (!isAuthorized && role === 3) {
      role = 2;
    }
    if (argument1.slice(0, 3) === '...' || argument2.slice(0, 3) === '...') {
      moveAFileEvent(current, argument1, argument2, false);
      return {
        template: MvFalseArg,
        data: {},
      };
    }
    let path1 = Mv.mvExecuteDot(currentPath, argument1);
    path1 = Mv.mvExecutePaths(path1);
    const currentPath1 = path1.currentPath;
    argument1 = path1.argument;

    let path2 = Mv.mvExecuteDot(currentPath, argument2);
    path2 = Mv.mvExecutePaths(path2);
    const currentPath2 = path2.currentPath;
    argument2 = path2.argument;

    if (argument1.slice(0, 1) === '.') {
      moveAFileEvent(current, argument1, argument2, false);
      return {
        template: MvFalseArg,
        data: {},
      };
    }
    if (argument1 === '') {
      moveAFileEvent(current, argument1, argument2, false);
      return {
        template: MvFalseArg,
        data: {},
      };
    }

    if (argument2.slice(0, 1) === '.') {
      moveAFileEvent(current, argument1, argument2, false);
      return {
        template: MvFalseArg,
        data: {},
      };
    }

    let template = {
      template: MvFalseArg,
      data: {},
    };

    if (argument1 !== '') {
      res = await getDirectoryByPath(`${currentPath1}`, accessToken, isAuthorized);
      promise1 = new Promise((resolve, reject) => {
        arg1Correct = false;
        try {
          if (res && !availableStatus.includes(res.status)) {
            template = {
              template: ExceptionTemplate,
              data: {},
            };
          } else if (res === undefined || res.status === lsStatusCode.unauthorized || res.status === lsStatusCode.notfound) {
            template = {
              template: MvFalseArg,
              data: {},
            };
            if (res !== undefined && res.status === lsStatusCode.unauthorized) {
              const argument = argument1;
              template = {
                template: MvAccessDeny,
                data: {
                  argument,
                },
              };
            }
          } else {
            res?.data?.forEach((f) => {
              if (f.name === argument1 && !f.is_folder) {
                arg1Correct = true;
                if (f.role > role) {
                  roleAccess1 = false;
                }
              } else if (f.name === argument1 && f.is_folder) {
                arg1Correct = false;
                if (f.role > role) {
                  roleAccess1 = false;
                }
              }
            });
          }
          resolve(arg1Correct);
        } catch (error) {
          reject(error);
        }
      });
    }
    if (!roleAccess1) {
      const argument = argument1;
      moveAFileEvent(current, argument1, argument2, false);
      return {
        template: MvAccessDeny,
        data: { argument },
      };
    }

    if (promise1) {
      promise1.then((resp) => {
        arg1Correct = resp;
      });
    }

    if (argument2 !== '') {
      res = await getDirectoryByPath(`${currentPath2}`, accessToken, isAuthorized);
      promise2 = new Promise((resolve, reject) => {
        arg2Correct = false;
        try {
          if (res && !availableStatus.includes(res.status)) {
            template = {
              template: ExceptionTemplate,
              data: {},
            };
          } else if (res === undefined || res.status === lsStatusCode.unauthorized || res.status === lsStatusCode.notfound) {
            template = {
              template: MvFalseArg,
              data: {},
            };
            if (res !== undefined && res.status === lsStatusCode.unauthorized) {
              template = {
                template: MvAccessDeny,
                data: {
                  argument2,
                },
              };
            }
          } else {
            res?.data?.forEach((f) => {
              if (f.name === argument2 && f.is_folder) {
                arg2Correct = true;
                if (f.role > role) {
                  roleAccess2 = false;
                }
              } else if (f.name === argument2 && !f.isFolder) {
                arg2Correct = false;
                if (f.role > role) {
                  roleAccess1 = false;
                }
              }
            });
          }
          resolve(arg2Correct);
        } catch (error) {
          reject(error);
        }
      });
    }

    if (!roleAccess2) {
      const argument = argument2;
      moveAFileEvent(current, argument1, argument2, false);
      return {
        template: MvAccessDeny,
        data: { argument },
      };
    }

    if (promise2) {
      promise2.then((resp) => {
        arg2Correct = resp;
      });
    }

    if (arg1Correct && arg2Correct) {
      let file = '';
      let destination = '';
      if (currentPath1 !== '') {
        file = `${currentPath1}`;
      }
      if (currentPath2 !== '') {
        destination = `${currentPath2}`;
      }
      if (file === restrictedFolder) {
        moveAFileEvent(current, argument1, argument2, false);
        return {
          template: MvNotMoved,
          data: {
            argument: argument1,
          },
        };
      }
      if (argument2 === restrictedFolder) {
        moveAFileEvent(current, argument1, argument2, false);
        return {
          template: MvNotModified,
          data: {
            argument: restrictedFolder,
          },
        };
      }
      res = await moveFile(`serverheist/${file}/${argument1}`, `serverheist${destination}/${argument2}`, accessToken, isAuthorized);
      if (argument1 === fileToBeTransferred && argument2 === adminFolder) {
        res = await checkFileInAdmin(accessToken);
        if (res && !availableStatus.includes(res.status)) {
          template = {
            template: ExceptionTemplate,
            data: {},
          };
        } else if (res?.data) {
          template = {
            template: () => {},
            data: {
              allowRoot: 1,
            },
          };
        }
      } else if (argument1 === fileToBeTransferred && argument2 !== adminFolder) {
        template = {
          template: () => {},
          data: {
            allowRoot: -1,
          },
        };
      } else {
        template = {
          template: MvCorrectArg,
          data: {},
        };
      }
    }
    switch (template.template) {
      case MvFalseArg:
        moveAFileEvent(current, argument1, argument2, false);
        break;
      case MvAccessDeny:
        moveAFileEvent(current, argument1, argument2, false);
        break;
      case MvNotModified:
        moveAFileEvent(current, argument1, argument2, false);
        break;
      case MvNotMoved:
        moveAFileEvent(current, argument1, argument2, false);
        break;
      case MvCorrectArg:
        moveAFileEvent(current, argument1, argument2, false);
        break;
      default:
        moveAFileEvent(current, argument1, argument2, false);
        break;
    }
    return template;
  }

  async execCommand(args) {
    const { isAuthorized, currentPath, userRole, accessToken } = args;
    let template = {
      template: () => {},
      data: {},
    };

    if (this.parameters.length !== 2) {
      moveAFileEvent(currentPath, this.parameters.length === 1 ? this.parameters[0] : '(not set)', '(not set)', false);
      template = {
        template: MvFalseArg,
        data: {},
      };
      return template;
    }
    template = await this.mvWithArgument(currentPath, userRole, accessToken, isAuthorized);
    return template;
  }
}

export default Mv;
