import { getDirectoryByPath } from '../../../services/directory.service';
import { allStatusCode } from '../../../constants/status.constants';
import { EmailStart } from '../../../templates/phaseOne/email';
import { CdFalseArgs, CdFolderArg, CdCorrectArg, CdFileArg, CdAccessDeny } from '../../../templates/phaseZero/cd';
import addToAccessAPathEvent from '../../../utils/dataLayer/accessAPath';

const { lsStatusCode } = allStatusCode;

class Cd {
  constructor(command, parameters) {
    this.command = command;
    this.parameters = parameters;
    this.acceptCdCommand = false;
  }

  cdExecuteDot(currentPath) {
    const currentPaths = currentPath.split('/');
    const argumentPaths = this.parameters[0].split('/');
    argumentPaths.map((paths, index) => {
      switch (paths) {
        case '.':
          argumentPaths[index] = '';
          break;
        case '..':
          if (currentPaths.length > 0) {
            currentPaths.pop();
          }
          argumentPaths[index] = '';
          break;
        default:
          return { currentPaths, argumentPaths };
      }
      return { currentPaths, argumentPaths };
    });
    return { currentPaths, argumentPaths };
  }

  async cdWithArgument(current, userRole, accessToken, isAuthorized) {
    let currentPath = current;
    let checkEmail = false;
    let res = null;
    let argument = this.parameters[0];
    let role = userRole;
    if (!isAuthorized && role === 3) {
      role = 2;
    }
    let template = {
      template: () => {},
      data: {},
    };
    if (argument.slice(0, 3) === '...') {
      addToAccessAPathEvent(argument, current, false, false);
      return {
        template: CdFalseArgs,
        data: {
          argument,
        },
      };
    }
    const path = this.cdExecuteDot(currentPath);
    currentPath = '';
    argument = '';
    const argumentTmp = path.argumentPaths.filter((i) => i);
    const currentPathTmp = path.currentPaths.filter((i) => i);
    const isLastPathEmpty = currentPathTmp.length === 0;
    const firstArgument = argumentTmp[0];
    while (argumentTmp.length > 1) {
      currentPathTmp.push(argumentTmp.shift());
    }
    currentPathTmp.forEach((paths) => {
      currentPath = `${currentPath}/${paths}`;
    });
    argumentTmp.forEach((paths) => {
      argument = `${argument}/${paths}`;
    });

    if (argument !== '') {
      argument = argument.slice(1);
    }
    if (currentPath !== '') {
      currentPath = currentPath.slice(1);
    }
    if ((firstArgument === 'guest' && current === '') || (firstArgument === 'guest' && isLastPathEmpty)) {
      checkEmail = true;
    }
    res = await getDirectoryByPath(currentPath, accessToken, isAuthorized);
    const promise = new Promise((resolve, reject) => {
      try {
        if (res === undefined || res.status === lsStatusCode.unauthorized || res.status === lsStatusCode.notfound) {
          this.acceptCdCommand = false;
          template = {
            template: CdFalseArgs,
            data: {
              argument,
            },
          };
          if (checkEmail && role === 0) {
            template = {
              template: EmailStart,
              data: {
                accessDenied: true,
              },
            };
          } else if (res !== undefined && res.status === lsStatusCode.unauthorized) {
            template = {
              template: CdAccessDeny,
              data: {
                argument,
              },
            };
          }
        } else if (argument !== '') {
          template = {
            template: CdFalseArgs,
            data: {
              argument,
            },
          };
          res.data?.map((f) => {
            if (f.is_folder && f.name === argument) {
              if (f.role <= role) {
                this.acceptCdCommand = true;
                template = {
                  template: CdFolderArg,
                  data: {
                    currentPath,
                    acceptCdCommand: this.acceptCdCommand,
                    argument: currentPath !== '' ? `${currentPath}/${argument}` : argument,
                  },
                };
              } else if (argument === 'guest' && role === 0) {
                template = {
                  template: EmailStart,
                  data: {
                    accessDenied: true,
                  },
                };
              } else {
                template = {
                  template: CdAccessDeny,
                  data: {
                    currentPath,
                    argument: currentPath !== '' ? `${currentPath}/${argument}` : argument,
                  },
                };
              }
            } else if (!f.is_folder && f.name === argument && f.role <= role) {
              template = {
                template: CdFileArg,
                data: {
                  argument,
                },
              };
            }
            return template;
          });
        } else {
          this.acceptCdCommand = true;
          template = {
            template: CdCorrectArg,
            data: {
              currentPath,
              argument: currentPath,
              acceptCdCommand: this.acceptCdCommand,
            },
          };
          addToAccessAPathEvent(argument, current, true, true);
        }

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
    promise.then((result) => result);
    switch (template.template) {
      case CdFalseArgs:
        addToAccessAPathEvent(argument, current, false, false);
        break;
      case CdFileArg:
        addToAccessAPathEvent(argument, current, false, false);
        break;
      case CdCorrectArg:
        addToAccessAPathEvent(argument, current, true, true);
        break;
      case CdAccessDeny:
        addToAccessAPathEvent(argument, current, false, true);
        break;
      case EmailStart:
        addToAccessAPathEvent(argument, current, false, true);
        break;
      default:
        addToAccessAPathEvent(argument, current, true, true);
        break;
    }
    return template;
  }

  async execCommand(args) {
    const { currentPath, userRole, accessToken, isAuthorized } = args;
    let template = {
      template: () => {},
      data: {},
    };
    if (this.parameters.length === 0) {
      addToAccessAPathEvent('(not set)', currentPath, true, true);
      this.acceptCdCommand = true;
      return {
        template: () => {},
        data: {
          argument: '',
          acceptCdCommand: this.acceptCdCommand,
        },
      };
    }
    template = await this.cdWithArgument(currentPath, userRole, accessToken, isAuthorized);
    return template;
  }
}

export default Cd;
