/**
 * Function to add leading zero for single digit number
 *
 * @param {number} singleDigit number with only one digit
 * @return string with leading zero
 */
function addLeadingZero(singleDigit) {
  if (singleDigit <= 9) return `0${singleDigit}`;
  return singleDigit;
}

function getTimeAndDay(inputDate) {
  return {
    hourPM: inputDate.getHours(),
    hourAM: (inputDate.getHours() + 24) % 12 || 12,
    minute: inputDate.getMinutes(),
    second: inputDate.getSeconds(),
  };
}

/**
 * Function to convert normal Date format to specified format
 *
 * @param {Date} inputDate
 * @return string of date with required format
 */
function getFormattedDate(inputDate) {
  const timeAndDate = getTimeAndDay(inputDate);
  const { hourPM, hourAM, minute } = timeAndDate;
  const minuteZero = addLeadingZero(minute);

  let timeAM = true;
  if (hourPM >= 12) {
    timeAM = false;
  }

  const timeSection = `${hourAM}:${minuteZero}${timeAM ? 'AM' : 'PM'}`;
  return timeSection;
}

export default getFormattedDate;
