import { allStatusCode } from '../../constants/status.constants';
import { blacklistToken, getUserRole, refreshAccessToken } from '../../services/player.service';
import { UPDATE_USER_ROLE, CLEAR_USER_DATA, SET_USER_DATA, UPDATE_ACCESS_TOKEN } from '../constants/user.constants';

const { blacklistStatusCode, userStatusCode } = allStatusCode;

function updateUserRole(accessToken, allowRoot, refreshToken) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_ROLE,
      payload: {
        accessToken,
        allowRoot,
        refreshToken,
      },
    });
  };
}

function updateAccessToken(accessToken) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACCESS_TOKEN,
      payload: {
        accessToken,
      },
    });
  };
}

const clearUserData = (accessToken, refreshToken) => async (dispatch) => {
  try {
    // If token still exits on localStorage
    if (localStorage.getItem('token')) {
      const refreshTokenLocal = localStorage.getItem('token');
      if (refreshTokenLocal !== '') {
        const res = await blacklistToken(accessToken, refreshTokenLocal);
        if (res && res.status === blacklistStatusCode.success) {
          localStorage.clear();
          await dispatch({
            type: CLEAR_USER_DATA,
            payload: {},
          });
          return res;
        }
      } else {
        localStorage.clear();
      }
    }

    // If token is not in localStorage, get token in userReducer
    if (refreshToken) {
      const res = await blacklistToken(accessToken, refreshToken);
      if (res && res.status === blacklistStatusCode.success) {
        localStorage.clear();
        await dispatch({
          type: CLEAR_USER_DATA,
          payload: {},
        });
        return res;
      }
    }

    localStorage.clear();
    return {};
  } catch (err) {
    return {};
  }
};

const setUserData = (refreshToken) => async (dispatch) => {
  let res;
  try {
    // If token still exits on localStorage
    if (localStorage.getItem('token')) {
      const refreshTokenLocal = localStorage.getItem('token');
      if (refreshTokenLocal !== '') {
        res = await refreshAccessToken(refreshTokenLocal);
        if (res && res.status === userStatusCode.success) {
          const resRole = await getUserRole(res.data.access);
          if (resRole?.status === userStatusCode.success) {
            await dispatch({
              type: SET_USER_DATA,
              payload: {
                accessToken: res.data.access,
                refreshToken,
                userRole: resRole.data.role,
              },
            });
            return res;
          }
        }
      } else {
        localStorage.clear();
      }
    }

    if (refreshToken) {
      // If token is not in localStorage, get token in userReducer
      res = await refreshAccessToken(refreshToken);
      if (res && res.status === userStatusCode.success) {
        const resRole = await getUserRole(res.data.access);
        if (resRole?.status === userStatusCode.success) {
          await dispatch({
            type: SET_USER_DATA,
            payload: {
              accessToken: res.data.access,
              refreshToken,
              userRole: resRole.data.role,
            },
          });
          return res;
        }
      }
    }
    await dispatch({
      type: CLEAR_USER_DATA,
      payload: {},
    });
    localStorage.clear();
    return res;
  } catch (err) {
    return {};
  }
};

export { updateUserRole, clearUserData, setUserData, updateAccessToken };
