import resetGTMDataLayer from './dataLayer.utils';

function addToAccessAPathEvent(pathTyped, atFolder, accessSuccess, pathExisted) {
  resetGTMDataLayer();
  const eventObj = { event: 'access_a_path', pathTyped, atFolder, accessSuccess, pathExisted };
  if (atFolder === '') window.dataLayer.push({ ...eventObj, atFolder: 'serverheist' });
  if (!atFolder) return;
  window.dataLayer.push(eventObj);
}

export default addToAccessAPathEvent;
