import { ReactNode } from 'react';

const PasswordValid = (): ReactNode => <p>Login successfully</p>;

const PasswordFalse = ({ chancesLeft }: { chancesLeft: number }): ReactNode => {
  return <p>Wrong password. {chancesLeft} chance(s) left.</p>;
};

const PasswordFalseFinal = (): ReactNode => {
  return <p>Login failed</p>;
};

const PasswordSendError = (): ReactNode => <p>Password was not checked successfully.</p>;

export { PasswordValid, PasswordFalse, PasswordFalseFinal, PasswordSendError };
