import { EMAIL_DOMAIN } from '../../constants/regex/user.regex';
import resetGTMDataLayer from './dataLayer.utils';

function addToGuestLoginEvent(userEmail) {
  resetGTMDataLayer();
  const emailDomain = `@${userEmail.match(EMAIL_DOMAIN)[0]}`;
  window.dataLayer.push({ event: 'guest_login', emailDomain, userEmail });
}

export default addToGuestLoginEvent;
