import resetGTMDataLayer from './dataLayer.utils';

function moveAFileEvent(atFolder, fileName, destinationDirectory, moveSuccess) {
  resetGTMDataLayer();
  const eventObj = { event: 'move_a_file', atFolder, fileName, destinationDirectory, moveSuccess };
  if (atFolder === '') window.dataLayer.push({ ...eventObj, atFolder: 'serverheist' });
  if (!atFolder) return;
  window.dataLayer.push(eventObj);
}

export default moveAFileEvent;
