import { ReactNode } from 'react';

const UsernameValid = (): ReactNode => <p>Your record has been saved.</p>;

const ExistedUsername = ({ username }: { username: string }): ReactNode => <>&quot;{username}&quot; has been taken.</>;

const UsernameInvalid = (): ReactNode => (
  <div>
    <p>Accepted username must:</p>
    <p>- Contain 3 - 20 characters</p>
    <p>- Allowed characters: letters (a-z, A-Z), numbers, @, underscores ( _ ) and dash ( - )</p>
    <p>- Include at least 2 letters</p>
  </div>
);

export { UsernameValid, UsernameInvalid, ExistedUsername };
