import { ReactNode } from 'react';

const MvFalseArg = (): ReactNode => <>Usage: mv [file_name] [destination_directory]</>;

const MvCorrectArg = ({ fileContent }: { fileContent: string }): ReactNode => <>{fileContent}</>;

const MvAccessDeny = ({ argument }: { argument: string }): ReactNode => <>{argument}: [Permission denied] Could not perform action.</>;

const MvNotFound = (): ReactNode => <>mv: Command not found</>;

const MvNotModified = ({ argument }: { argument: string }): ReactNode => <>{argument}: Folder cannot be modified</>;

const MvNotMoved = ({ argument }: { argument: string }): ReactNode => <>{argument}: File cannot be moved</>;

export { MvFalseArg, MvCorrectArg, MvAccessDeny, MvNotFound, MvNotModified, MvNotMoved };
