// Opera 8.0+
const isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]"
const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  ((p) => {
    return p.toString() === '[object SafariRemoteNotification]';
  })(!window.safari || (typeof window.safari !== 'undefined' && window.safari.pushNotification));

// Internet Explorer 6-11
const isIE = /* @cc_on!@ */ false || !!document.documentMode;

// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 71
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Blink engine detection
const isBlink = (isChrome || isOpera) && !!window.CSS;

const browserCheck = {
  chrome: isChrome,
  safari: isSafari,
  firefox: isFirefox,
  edge: isEdge,
  IE: isIE,
  blink: isBlink,
  opera: isOpera,
};

const checkBrowser = () => {
  let curBrowser = 'chrome';
  Object.keys(browserCheck).every((browser) => {
    if (browserCheck[browser]) {
      curBrowser = browser;
      return false;
    }
    return true;
  });
  return curBrowser;
};

export { isOpera, isFirefox, isSafari, isIE, isEdge, isChrome, isBlink, checkBrowser };
