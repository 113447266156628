import { allStatusCode } from '../../../constants/status.constants';
import { getRanking } from '../../../services/player.service';
import { ExceptionTemplate } from '../../../templates/exceptions';
import { RankingCorrectArg, RankingTooManyArg } from '../../../templates/phaseTwo/ranking';

const { rankingStatusCode } = allStatusCode;

class Ranking {
  constructor(command, parameters) {
    this.command = command;
    this.parameters = parameters;
  }

  async execCommand() {
    if (this.parameters.length > 0) {
      return {
        template: RankingTooManyArg,
        data: {},
      };
    }
    if (this.parameters.length === 0) {
      const rankingRes = await getRanking();
      if (rankingRes?.status === rankingStatusCode.success) {
        return {
          template: RankingCorrectArg,
          data: {
            rankingList: rankingRes?.data,
          },
        };
      }
    }

    return {
      template: ExceptionTemplate,
      data: {},
    };
  }
}

export default Ranking;
