import roleMapping from '../../constants/user.constants';
import { UPDATE_USER_ROLE, CLEAR_USER_DATA, SET_USER_DATA, UPDATE_ACCESS_TOKEN } from '../constants/user.constants';

const initialData = {
  userRole: 0,
  accessToken: null,
  refreshToken: null,
};

const userReducer = (state = initialData, action) => {
  switch (action.type) {
    case UPDATE_USER_ROLE: {
      const { accessToken, allowRoot } = action.payload;
      if (allowRoot && allowRoot === 1) {
        return {
          ...state,
          userRole: roleMapping.admin,
        };
      }
      if (allowRoot && allowRoot === -1) {
        return {
          ...state,
          userRole: roleMapping.user,
        };
      }
      if (state.userRole === roleMapping.admin) {
        return {
          ...state,
          accessToken,
        };
      }
      return {
        ...state,
        accessToken,
        userRole: state.userRole + 1,
      };
    }
    case CLEAR_USER_DATA: {
      return {
        userRole: 0,
        accessToken: null,
        refreshToken: null,
      };
    }
    case SET_USER_DATA: {
      const { accessToken, userRole, refreshToken } = action.payload;
      return {
        userRole,
        accessToken,
        refreshToken,
      };
    }
    case UPDATE_ACCESS_TOKEN: {
      const { accessToken } = action.payload;
      return {
        ...state,
        accessToken,
      };
    }
    default:
      return { ...state };
  }
};

export default userReducer;
