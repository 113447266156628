/* eslint-disable no-unused-vars */
import axios from './authAxios';
import { Options } from '../interfaces/services.interface';
import getOptions from '../utils/services.utils';

export async function getDirectoryByPath(path: string, accessToken: string, isRootAccess = false): Promise<unknown> {
  const backendUrl = '/directory/children/serverheist';
  const options: Options = getOptions(accessToken, isRootAccess);

  try {
    let endpoint = `${backendUrl}`;
    if (path !== '') {
      endpoint = `${backendUrl}/${path}`;
    }
    const res = await axios(options).get(endpoint);
    return res;
  } catch (error) {
    return error.response;
  }
}

export async function getFile(path: string, accessToken: string, isRootAccess = false): Promise<unknown> {
  const backendUrl = '/directory/file/content/serverheist';
  const options: Options = getOptions(accessToken, isRootAccess);

  try {
    let endpoint = `${backendUrl}`;
    if (path !== '') {
      endpoint = `${backendUrl}/${path}`;
    }
    const res = await axios(options).get(endpoint);
    return res;
  } catch (error) {
    return error.response;
  }
}

export async function checkFileInAdmin(accessToken: string): Promise<unknown> {
  const backendUrl = '/directory/file/check';
  const options: Options = getOptions(accessToken);

  try {
    const res = await axios(options).get(backendUrl);
    return res;
  } catch (error) {
    return error.response;
  }
}
