const statusCode: { [key: string]: number } = {
  success: 200,
  accepted: 202,
  unauthorized: 401,
  gone: 410,
  notfound: 404,
  found: 302,
  preconditionfailed: 412,
  notacceptable: 406,
  notallowed: 405,
  conflict: 409,
};

const availableStatus: number[] = [
  statusCode.success,
  statusCode.accepted,
  statusCode.unauthorized,
  statusCode.gone,
  statusCode.notfound,
  statusCode.found,
  statusCode.preconditionfailed,
  statusCode.notacceptable,
  statusCode.notallowed,
];

const loginStatusCode: { [key: string]: number } = {
  success: statusCode.success,
  unauthorized: statusCode.unauthorized,
};

const emailStatusCode: { [key: string]: number } = {
  success: statusCode.success,
  expired: statusCode.expired,
  notFound: statusCode.notfound,
  unauthorized: statusCode.unauthorized,
};

const passwordStatusCode: { [key: string]: number } = {
  success: statusCode.success,
  preconditionfailed: statusCode.preconditionfailed,
  unauthorized: statusCode.unauthorized,
};

const usernameStatusCode: { [key: string]: number } = {
  success: statusCode.success,
  accepted: statusCode.accepted,
  notacceptable: statusCode.notacceptable,
  conflict: statusCode.conflict,
};

const mvStatusCode: { [key: string]: number } = {
  success: statusCode.success,
  unauthorized: statusCode.unauthorized,
  notfound: statusCode.notfound,
};

const openStatusCode: { [key: string]: number } = {
  success: statusCode.success,
  unauthorized: statusCode.unauthorized,
  notfound: statusCode.notfound,
};

const psStatusCode: { [key: string]: number } = {
  success: statusCode.success,
};

const editorStatusCode: { [key: string]: number } = {
  success: statusCode.success,
  unauthorized: statusCode.unauthorized,
  notfound: statusCode.notfound,
};

const killStatusCode: { [key: string]: number } = {
  success: statusCode.success,
  notacceptable: statusCode.notacceptable,
  notfound: statusCode.notfound,
  found: statusCode.found,
  accepted: statusCode.accepted,
};

const rankingStatusCode: { [key: string]: number } = {
  success: statusCode.success,
};

const restartStatusCode: { [key: string]: number } = {
  success: statusCode.success,
};

const ufwStatusCode: { [key: string]: number } = {
  success: statusCode.success,
  found: statusCode.found,
  accepted: statusCode.accepted,
  preconditionfailed: statusCode.preconditionfailed,
  notacceptable: statusCode.notacceptable,
};

const catStatusCode: { [key: string]: number } = {
  success: statusCode.success,
  notfound: statusCode.notfound,
  unauthorized: statusCode.unauthorized,
};

const cdStatusCode: { [key: string]: number } = {
  success: statusCode.success,
  notfound: statusCode.notfound,
  unauthorized: statusCode.unauthorized,
};

const lsStatusCode: { [key: string]: number } = {
  success: statusCode.success,
  notfound: statusCode.notfound,
  unauthorized: statusCode.unauthorized,
};

const firewallStatusCode: { [key: string]: number } = {
  success: statusCode.success,
  notfound: statusCode.notfound,
  unauthorized: statusCode.unauthorized,
  notacceptable: statusCode.notacceptable,
  accepted: statusCode.accepted,
};

const blacklistStatusCode: { [key: string]: number } = {
  success: statusCode.success,
};

const userStatusCode: { [key: string]: number } = {
  success: statusCode.success,
};

const specialFileStatusCode: { [key: string]: number } = {
  success: statusCode.success,
  notacceptable: statusCode.notacceptable,
  preconditionfailed: statusCode.preconditionfailed,
};

const allStatusCode = {
  loginStatusCode,
  emailStatusCode,
  passwordStatusCode,
  usernameStatusCode,
  mvStatusCode,
  openStatusCode,
  psStatusCode,
  editorStatusCode,
  killStatusCode,
  rankingStatusCode,
  restartStatusCode,
  ufwStatusCode,
  catStatusCode,
  cdStatusCode,
  lsStatusCode,
  firewallStatusCode,
  blacklistStatusCode,
  userStatusCode,
  specialFileStatusCode,
};

export { statusCode, availableStatus, allStatusCode };
