import { PwdFalseArgs, PwdCorrectArg } from '../../../templates/phaseZero/pwd';

class Pwd {
  constructor(command, parameters) {
    this.command = command;
    this.parameters = parameters.map((parameter) => parameter.toLowerCase());
  }

  execCommand(args) {
    const { currentPath } = args;
    if (this.parameters.length > 0) {
      return {
        template: PwdFalseArgs,
        data: {},
      };
    }

    if (currentPath === '') {
      return {
        template: PwdCorrectArg,
        data: {
          currentPath: 'serverheist',
        },
      };
    }

    return {
      template: PwdCorrectArg,
      data: {
        currentPath: `serverheist/${currentPath}`,
      },
    };
  }
}

export default Pwd;
