import axios from './authAxios';
import { Options } from '../interfaces/services.interface';
import getOptions from '../utils/services.utils';

async function sendUsername(username: string, accessToken: string): Promise<unknown> {
  const backendUrl = 'player/set_username/';
  const options: Options = getOptions(accessToken);

  try {
    const res = await axios(options).post(backendUrl, { username });
    return res;
  } catch (error) {
    return error.response;
  }
}
export default sendUsername;
