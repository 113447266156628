const RestartInactive = () => <>The machine is not ready.</>;

const RestartActive = ({ milestones, username }) => (
  <>
    <p>The system has been cleaned up.</p>
    <p>Congratulations! You’ve successfully regained the website’s access</p>
    <div>
      <p>Your record:</p>
      <p>Total time to reboot the machine: {milestones[6].time_record}</p>
    </div>
    <div>
      <p>You spent:</p>
      <div>
        <p>{milestones[1].time_record} - solving the secret letter</p>
        <p>{milestones[2].time_record} - finding the correct password</p>
        <p>{milestones[3].time_record} - decoding the hint from Morse code and base64 message </p>
        <p>{milestones[4].time_record} - finding the virus file and successfully killing it</p>
        <p>{milestones[5].time_record} - solving the algorithm</p>
      </div>
      {username ? <p>Your record has been saved onto username &quot;{username}&quot;</p> : <p>We will save your record.</p>}
    </div>
  </>
);

const RestartFalseArg = () => <>restart: Too many arguments</>;

const RestartDenied = () => <>restart: Access denied</>;

export { RestartInactive, RestartActive, RestartFalseArg, RestartDenied };
