import TabCorrect from '../../../templates/phaseZero/tab';
import getUsableCommands from '../../../utils/command.utils';

class Tab {
  constructor(command) {
    this.command = command;
  }

  execCommand(args) {
    const { userRole } = args;
    const { description } = getUsableCommands(userRole);
    if (!this.command)
      return {
        template: TabCorrect,
        data: {
          commands: Object.keys(description).sort((itemA, itemB) => itemA.localeCompare(itemB)),
        },
      };
    return {
      template: () => {},
      data: {},
    };
  }
}

export default Tab;
