import { emptyCommand, invalidCommand, unauthCommand } from '../../../templates/exceptions';

class ExceptionCommand {
  constructor(command, parameters, isAvailableCommand) {
    this.command = command;
    this.parameters = parameters.map((parameter) => parameter.toLowerCase());
    this.isAvailableCommand = isAvailableCommand;
  }

  execCommand() {
    if (this.command === '') {
      return {
        template: emptyCommand,
        data: {},
      };
    }
    if (this.isAvailableCommand) {
      return {
        template: unauthCommand,
        data: {
          unauth: true,
          command: this.command,
        },
      };
    }

    return {
      template: invalidCommand,
      data: {
        command: this.command,
      },
    };
  }
}

export default ExceptionCommand;
