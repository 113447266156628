import resetGTMDataLayer from './dataLayer.utils';

function addToTypingACommandEvent(command, userRole, commandArg, sudoCmd) {
  resetGTMDataLayer();
  if (!command || command === '') return;
  let sudoUsed = false;
  let commandTyped = command;
  if (command === 'sudo') {
    sudoUsed = true;
    if (!sudoCmd) commandTyped = '(not provided)';
    else commandTyped = sudoCmd;
  }

  let commandArguments = commandArg.join(' ');
  if (!commandArg || commandArg.length === 0) commandArguments = '(not provided)';
  const fullCommand = `${sudoCmd ? 'sudo ' : ''}${commandTyped}${commandArg.length === 0 ? '' : ' '}${commandArg.join(' ')}`;
  window.dataLayer.push({
    event: 'typing_a_command',
    sudoUsed,
    commandTyped,
    userRole,
    commandArguments,
    fullCommand,
  });
}

export default addToTypingACommandEvent;
