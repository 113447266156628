const passwordProcess: { [key: string]: string } = {
  firstValid: 'firstValid',
  firstInvalid: 'firstInvalid',
  secondInvalid: 'secondInvalid',
  thirdInvalid: 'thirdInvalid',
  expiredInvalid: 'expiredInvalid',
  finalValid: 'finalValid',
  initial: 'initial',
};

const passwordHoldersList: { [key: string]: string } = {
  password: 'Enter the correct password to login: ',
  passwordRetry: 'Enter the correct password to login: ',
};

export { passwordProcess, passwordHoldersList };
