import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../../components/Input';
import { PromptContainer } from '../../styles';
import { updatePasswordContainersSize, updatePasswordSize } from '../../../../redux/actions/password.actions';
import { updateEmptyTerminal } from '../../../../redux/actions/terminal.actions';
import { updateUserRole } from '../../../../redux/actions/user.actions';
import Password from '../../../../models/commands/phaseOne/Password';
import addToLoginSuccessEvent from '../../../../utils/dataLayer/loginSuccess';

const PasswordContainers = React.forwardRef((props, ref) => {
  const { passwordPosition, isInputPassword, setIsInputPassword } = props;
  const { terminal } = useSelector((state) => state.terminalReducer);
  const { accessToken } = useSelector((state) => state.userReducer);
  const { offset } = terminal.terminalSize;
  const dispatch = useDispatch();
  const { currentPassword, passwordHolders, passwordResults, passwordSize, passwordAttempts } = useSelector((state) => state.passwordReducer);

  function handlePasswordControlCPressed() {
    const tempPassword = [...isInputPassword];
    tempPassword[tempPassword.length - 1] = true;
    setIsInputPassword(tempPassword);
    dispatch(updatePasswordContainersSize());
    dispatch(updateEmptyTerminal());
  }

  async function handlePasswordInput(textContent) {
    const password = new Password(textContent);
    const templateResult = await password.execCommand({ password: currentPassword, accessToken });
    if (templateResult.data.isInvalid) {
      dispatch(updatePasswordSize(templateResult));
      handlePasswordControlCPressed();
    } else if (templateResult.data.passwordFinal && templateResult.data.isValid) {
      dispatch(updatePasswordSize(templateResult));
      dispatch(updateUserRole(accessToken, false));
      handlePasswordControlCPressed();
      addToLoginSuccessEvent(passwordAttempts);
    } else {
      dispatch(updatePasswordSize(templateResult));
    }
  }

  function renderPasswords() {
    const newArray = [];
    for (let index = 0; index < passwordSize[passwordPosition]; index += 1) {
      const inputPasswordEle = (
        <Fragment key={`input-password-${index}-${offset}`}>
          <Input
            type="password"
            ref={ref}
            textHolder={passwordHolders[passwordPosition][index]}
            onHandleCommand={handlePasswordInput}
            onControlCPressed={handlePasswordControlCPressed}
          />
          <PromptContainer>{passwordResults[passwordPosition][index]}</PromptContainer>
        </Fragment>
      );
      newArray.push(inputPasswordEle);
    }
    return newArray;
  }

  return <>{renderPasswords()}</>;
});

export default PasswordContainers;
