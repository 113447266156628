import { allStatusCode } from '../../../constants/status.constants';
import { checkFirewall, checkResolveFile, turnOnFirewall } from '../../../services/firewall.service';
import { checkProcessKilled } from '../../../services/process.service';
import { ExceptionTemplate, ExceptionTemplateOOM } from '../../../templates/exceptions';
import {
  UfwNotActive,
  UfwActive,
  UfwEnableNotKilled,
  UfwResolved,
  UfwFalseArg,
  UfwDenied,
  UfwFailedTestCase,
  UfwCompilerError,
  UfwInvalid,
  UfwMissingArg,
  FirewallException,
  UfwEmptyArg,
} from '../../../templates/phaseTwo/ufw';

const { firewallStatusCode, specialFileStatusCode, killStatusCode } = allStatusCode;

class Ufw {
  constructor(command, parameters) {
    this.command = command;
    this.parameters = parameters.map((parameter) => parameter.toLowerCase());
  }

  static async ufwEnable(accessToken, isAuthorized) {
    const resIsActive = await checkFirewall(accessToken, isAuthorized);
    if (resIsActive?.status === firewallStatusCode.success) {
      return {
        template: UfwResolved,
        data: {
          firewall: true,
        },
      };
    }
    if (resIsActive?.status === firewallStatusCode.notacceptable) {
      const resIsKilled = await checkProcessKilled(accessToken);
      if (resIsKilled?.status === killStatusCode.found) {
        return {
          template: UfwEnableNotKilled,
          data: {},
        };
      }
      if (resIsKilled?.status === killStatusCode.success) {
        const resIsResolved = await checkResolveFile(accessToken, isAuthorized);
        if (resIsResolved?.status === specialFileStatusCode.success) {
          const resFirewall = await turnOnFirewall(accessToken, isAuthorized);
          if (resFirewall?.status === firewallStatusCode.accepted) {
            return {
              template: UfwResolved,
              data: {
                firewall: true,
              },
            };
          }
          return {
            template: FirewallException,
            data: {},
          };
        }
        if (resIsResolved?.status === specialFileStatusCode.notacceptable) {
          return {
            template: UfwFailedTestCase,
            data: {
              testCases: resIsResolved.data.testcases,
              numberHidden: resIsResolved.data.number_of_hidden_testcases,
              numberTestCase: resIsResolved.data.number_of_testcases,
              passHidden: resIsResolved.data.pass_hidden_testcases,
              passTestCase: resIsResolved.data.pass_testcases,
            },
          };
        }
        if (resIsResolved?.status === specialFileStatusCode.preconditionfailed) {
          return {
            template: UfwCompilerError,
            data: {
              error: resIsResolved.data.Error,
            },
          };
        }
        return {
          template: ExceptionTemplateOOM,
          data: {},
        };
      }
    }

    return {
      template: ExceptionTemplate,
      data: {},
    };
  }

  static async ufwVerbose(accessToken, isAuthorized) {
    const resIsKilled = await checkProcessKilled(accessToken);
    if (resIsKilled?.status === killStatusCode.found) {
      return {
        template: UfwNotActive,
        data: {},
      };
    }
    if (resIsKilled?.status === killStatusCode.success) {
      const resIsActive = await checkFirewall(accessToken, isAuthorized);
      if (resIsActive?.status === firewallStatusCode.success) {
        return {
          template: UfwActive,
          data: {},
        };
      }
      if (resIsActive?.status === firewallStatusCode.notacceptable) {
        return {
          template: UfwNotActive,
          data: {},
        };
      }
    }
    return {
      template: ExceptionTemplate,
      data: {},
    };
  }

  async execCommand(args) {
    const { accessToken, isAuthorized } = args;
    if (isAuthorized) {
      if (this.parameters.length === 1 && this.parameters[0] === 'enable') {
        return Ufw.ufwEnable(accessToken, isAuthorized);
      }
      if (this.parameters.length === 2 && this.parameters[0] === 'status' && this.parameters[1] === 'verbose') {
        return Ufw.ufwVerbose(accessToken, isAuthorized);
      }
      if (this.parameters.length === 0) {
        return {
          template: UfwEmptyArg,
          data: {},
        };
      }
      if (this.parameters.length === 1) {
        return {
          template: UfwFalseArg,
          data: {
            argument: this.parameters.length > 0 ? this.parameters[0] : '',
          },
        };
      }
      if (this.parameters.length === 2 && this.parameters[0] === 'status') {
        return {
          template: UfwFalseArg,
          data: {
            argument: this.parameters[1],
          },
        };
      }
      if (this.parameters.length === 1 && this.parameters[0] === 'status') {
        return {
          template: UfwMissingArg,
          data: {
            argument: this.parameters[0],
          },
        };
      }
      return {
        template: UfwInvalid,
        data: {},
      };
    }
    return {
      template: UfwDenied,
      data: {},
    };
  }
}

export default Ufw;
