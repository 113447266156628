import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emailProcess } from '../../../../constants/email.constants';
import Email from '../../../../models/commands/phaseOne/Email';
import { setEmail, updateEmailContainersSize, updateEmailSize } from '../../../../redux/actions/email.actions';
import { updateEmptyTerminal } from '../../../../redux/actions/terminal.actions';
import { updateUserRole } from '../../../../redux/actions/user.actions';
import Input from '../../../../components/Input';
import { PromptContainer } from '../../styles';
import addToGuestLoginEvent from '../../../../utils/dataLayer/guestLogin';

const EmailContainer = React.forwardRef((props, ref) => {
  const { emailPosition, isInputEmail, setIsInputEmail } = props;
  const { terminal } = useSelector((state) => state.terminalReducer);
  const { accessToken } = useSelector((state) => state.userReducer);
  const { offset } = terminal.terminalSize;
  const dispatch = useDispatch();
  const { currentEmail, emailPhase, emailHolders, emailResults, emailSize } = useSelector((state) => state.emailReducer);

  function handleEmailControlCPressed() {
    const tempEmail = [...isInputEmail];
    tempEmail[tempEmail.length - 1] = true;
    setIsInputEmail(tempEmail);
    dispatch(updateEmailContainersSize());
    dispatch(updateEmptyTerminal());
  }

  async function handleEmailInput(textContent) {
    const email = new Email(textContent);
    const templateResult = await email.execCommand({ email: currentEmail, emailPhase, accessToken });
    const templateEmailPhase = templateResult.data.emailPhase;
    if (templateEmailPhase === emailProcess.finalValid) {
      dispatch(updateEmailSize(templateResult));
      handleEmailControlCPressed();
      dispatch(updateUserRole(templateResult.data.accessToken, false, templateResult.data.refreshToken));
      addToGuestLoginEvent(currentEmail);
    } else if (
      templateEmailPhase === emailProcess.thirdInvalid ||
      templateEmailPhase === emailProcess.finalValid ||
      templateEmailPhase === emailProcess.expiredInvalid
    ) {
      dispatch(updateEmailSize(templateResult));
      handleEmailControlCPressed();
    } else if (templateEmailPhase === emailProcess.firstValid) {
      dispatch(setEmail(textContent));
      dispatch(updateEmailSize(templateResult));
    } else {
      dispatch(updateEmailSize(templateResult));
    }
  }

  function renderEmails() {
    const newArray = [];
    for (let index = 0; index < emailSize[emailPosition]; index += 1) {
      const inputEmailEle = (
        <Fragment key={`input-email-${index}-${offset}`}>
          <Input
            type="email"
            ref={ref}
            textHolder={emailHolders[emailPosition][index]}
            onHandleCommand={handleEmailInput}
            onControlCPressed={handleEmailControlCPressed}
          />
          <PromptContainer>{emailResults[emailPosition][index]}</PromptContainer>
        </Fragment>
      );
      newArray.push(inputEmailEle);
    }
    return newArray;
  }

  return <>{renderEmails(emailPosition)}</>;
});

export default EmailContainer;
