import { ReactNode } from 'react';
import parse from 'html-react-parser';
import { GridColumnContainer } from '../../pages/MainPage/styles';

const HelpDefault = ({ commands }: { commands: string[] }): ReactNode => {
  const pathItems: ReactNode = commands?.map((command: string) => <p key={`cmd-${command}`}>{command}</p>);
  return (
    <>
      <p>Use “help [command name]” to display specific information about a command.</p>
      <p>Available commands are:</p>
      <GridColumnContainer>{pathItems}</GridColumnContainer>
    </>
  );
};

const HelpCorrectArgs = ({ description }: { description: string }): ReactNode => {
  return <p>{parse(description)}</p>;
};

const HelpFalseArgs = ({ parameter }: { parameter: string }): ReactNode => <p>help: {parameter}: Invalid argument</p>;

export { HelpDefault, HelpCorrectArgs, HelpFalseArgs };
