import { SET_DIRECTORY_FOLDERS, SET_INNER_FILES, SET_SECRET_FILES } from '../constants/directory.constants';

const initialData = {
  directories: {},
};

const directoryReducer = (state = initialData, action) => {
  switch (action.type) {
    case SET_DIRECTORY_FOLDERS: {
      const { directories } = action.payload;
      return { ...state, directories };
    }
    case SET_SECRET_FILES: {
      return { ...state };
    }
    case SET_INNER_FILES: {
      return { ...state };
    }
    default: {
      return { ...state };
    }
  }
};

export default directoryReducer;
