const emailProcess: { [key: string]: string } = {
  firstValid: 'firstValid',
  firstInvalid: 'firstInvalid',
  secondInvalid: 'secondInvalid',
  thirdInvalid: 'thirdInvalid',
  expiredInvalid: 'expiredInvalid',
  finalValid: 'finalValid',
  initial: 'initial',
};

const emailHoldersList: { [key: string]: string } = {
  activationCode: 'Enter activation code: ',
  email: 'Enter your email: ',
  emailRetry: 'Enter your email again: ',
};

export { emailProcess, emailHoldersList };
