const PATH_FULL_DOTS = /^\.+$/;
const PATH_OND_DOT_SLASH = /^(\.\/){1,}(\.|)$/;
const PATH_DOTS_SLASH = /^(\.{1,2}\/){1,}(\.{1,2}|)$/;
const PATH_DOTS_SLASH_TWO_WORD = /^(\.{1,2}\/){1,}((\.{1,2})|((?!\.)[\w\-._]+(?!\.)\/(?!\.)[\w\-._]+)(?!\.))$/;
const PATH_DOTS_SLASH_TWO_WORD_1 = /^(\.{1,2}\/){1,}((\.{1,2})|((?!\.)[\w\-._]+(?!\.)(?!\.).*\w*))$/;
const PATH_DOTS_SLASH_TWO_WORD_2 = /^(\.{1,2}\/){0,}((\.{1,2})|((?!\.)[\w\-._]+\/(?!\.)(?!\.).*\w*))$/;
const PATH_DOTS_SLASH_ONE_WORD = /^(\.{1,2}\/){1,}(((?!\.)[\w\-.]+(?!\.)))$/;
const PATH_DOTS_SLASH_DOTS_WORD = /^(\.{1,2}\/){1,}((\.{1,2})|((?!\.)[\w\-.]+(?!\.)))$/;

export {
  PATH_FULL_DOTS,
  PATH_OND_DOT_SLASH,
  PATH_DOTS_SLASH,
  PATH_DOTS_SLASH_TWO_WORD,
  PATH_DOTS_SLASH_TWO_WORD_1,
  PATH_DOTS_SLASH_TWO_WORD_2,
  PATH_DOTS_SLASH_ONE_WORD,
  PATH_DOTS_SLASH_DOTS_WORD,
};
