import { ReactNode } from 'react';

const EditorNoArg = (): ReactNode => <>editor: Missing argument</>;

const EditorWithArg = ({ argument }: { argument: string }): ReactNode => <>editor: Editing {argument}</>;

const EditorFolderArg = ({ directory }: { directory: string }): ReactNode => <>editor: {directory}: Is a directory</>;

const EditorFalseArgs = ({ argument }: { argument: string }): ReactNode => <>editor: {argument}: No such file or directory</>;

const EditorCorrectArg = (): ReactNode => <></>;

const EditorAudioArgs = ({ argument }: { argument: string }): ReactNode => <>editor: {argument}: Operation not allowed</>;

const EditorAccessDeny = ({ argument }: { argument: string }): ReactNode => <>editor: {argument}: Access denied</>;

export { EditorNoArg, EditorWithArg, EditorFolderArg, EditorFalseArgs, EditorCorrectArg, EditorAudioArgs, EditorAccessDeny };
