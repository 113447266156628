import axios from './authAxios';
import { Options } from '../interfaces/services.interface';
import getOptions from '../utils/services.utils';

export async function saveFileContent(filePath: string, content: string, accessToken: string, isRootAccess = false): Promise<unknown> {
  const backendUrl = `directory/file/save/`;
  const options: Options = getOptions(accessToken, isRootAccess);

  try {
    const file = `serverheist/${filePath}`;
    const res = await axios(options).post(backendUrl, { file, content });
    return res;
  } catch (error) {
    return error.response;
  }
}

export async function checkResolveFile(accessToken: string, isRootAccess = false): Promise<unknown> {
  const backendUrl = `algorithm/test_code/`;
  const options: Options = getOptions(accessToken, isRootAccess);

  try {
    const res = await axios(options).post(backendUrl);
    return res;
  } catch (error) {
    return error.response;
  }
}

export async function turnOnFirewall(accessToken: string, isRootAccess = false): Promise<unknown> {
  const backendUrl = `firewall/enable/`;
  const options: Options = getOptions(accessToken, isRootAccess);

  try {
    const res = await axios(options).post(backendUrl);
    return res;
  } catch (error) {
    return error.response;
  }
}

export async function checkFirewall(accessToken: string, isRootAccess = false): Promise<unknown> {
  const backendUrl = `firewall/check/`;
  const options: Options = getOptions(accessToken, isRootAccess);

  try {
    const res = await axios(options).get(backendUrl);
    return res;
  } catch (error) {
    return error.response;
  }
}

export async function restartFirewall(accessToken: string, isRootAccess = false): Promise<unknown> {
  const backendUrl = `firewall/restart/`;
  const options: Options = getOptions(accessToken, isRootAccess);

  try {
    const res = await axios(options).post(backendUrl);
    return res;
  } catch (error) {
    return error.response;
  }
}
