import { allStatusCode, availableStatus } from '../../../constants/status.constants';
import { getDirectoryByPath } from '../../../services/directory.service';
import { EmailStart } from '../../../templates/phaseOne/email';
import { ExceptionTemplate } from '../../../templates/exceptions';
import { LsDefault, LsInnerFile, LsMultipleFolder, LsNoFile, LsUnauthorized, MultipleLsTemplate } from '../../../templates/phaseZero/ls';
import { lsWithDots } from '../../../utils/lsCmd';
import addToAccessAPathEvent from '../../../utils/dataLayer/accessAPath';
import {
  PATH_FULL_DOTS,
  PATH_DOTS_SLASH,
  PATH_DOTS_SLASH_TWO_WORD,
  PATH_DOTS_SLASH_TWO_WORD_1,
  PATH_DOTS_SLASH_TWO_WORD_2,
  PATH_DOTS_SLASH_ONE_WORD,
} from '../../../constants/regex/path.regex';

const { lsStatusCode } = allStatusCode;
class Ls {
  constructor(command, parameters) {
    this.command = command;
    this.parameters = parameters;
  }

  static lsCheckInnerFile(data, parameter, currentPath) {
    const pathArr = parameter.split('/');
    const innerFile = pathArr[pathArr.length - 1];
    if (innerFile === '') {
      addToAccessAPathEvent(parameter, currentPath, true, true);
      return {
        template: LsDefault,
        data: {
          folderContent: data,
        },
      };
    }
    const hasInnerFileIdx = data.findIndex((file) => file.name === innerFile);
    if (hasInnerFileIdx === -1 || !data) {
      addToAccessAPathEvent(parameter, currentPath, false, false);
      return {
        template: LsNoFile,
        data: {
          parameter,
        },
      };
    }
    addToAccessAPathEvent(parameter, currentPath, true, true);
    return {
      template: LsInnerFile,
      data: {
        file: data[hasInnerFileIdx].name,
      },
    };
  }

  lsWithArguments(currentPath, parameters, results) {
    if (results) {
      const resultsWithParameters = results?.map((result, idx) => {
        return {
          data: availableStatus.includes(result.status) ? result?.data : undefined,
          status: result?.status,
          parameter: parameters[idx],
        };
      });

      const resultsWithNothing = resultsWithParameters.filter(({ data }) => data?.length === 0);
      let resultsWithData = resultsWithParameters.filter(({ data }) => data?.length !== 0);
      resultsWithData = resultsWithData.sort(({ parameter: parameterA }, { parameter: parameterB }) => {
        if (parameterA > parameterB) {
          return 1;
        }
        if (parameterB > parameterA) {
          return -1;
        }
        return 0;
      });

      const finalResults = [...resultsWithNothing, ...resultsWithData];
      const templateData = [];

      if (finalResults.length === 1 && finalResults[0].status === lsStatusCode.unauthorized && finalResults[0].parameter.includes('guest')) {
        addToAccessAPathEvent(finalResults[0].parameter, currentPath, false, true);
        return {
          template: EmailStart,
          data: {
            accessDenied: true,
          },
        };
      }

      for (let i = 0; i < finalResults.length; i += 1) {
        const { data, status, parameter } = finalResults[i];
        if (!availableStatus.includes(status)) {
          templateData.push({
            template: ExceptionTemplate,
            data: {},
          });
        } else if (status === lsStatusCode.unauthorized) {
          addToAccessAPathEvent(parameter, currentPath, false, true);
          templateData.push({
            template: LsUnauthorized,
            data: {
              parameter,
            },
          });
        } else if (status === lsStatusCode.notfound || data === undefined) {
          addToAccessAPathEvent(parameter, currentPath, false, false);
          templateData.push({
            template: LsNoFile,
            data: {
              parameter,
            },
          });
        } else if (PATH_FULL_DOTS.test(parameter) || PATH_DOTS_SLASH.test(parameter)) {
          addToAccessAPathEvent(parameter, currentPath, true, true);
          templateData.push({
            template: LsMultipleFolder,
            data: {
              parameters: this.parameters,
              parameter,
              folderContent: data,
            },
          });
        } else if (PATH_DOTS_SLASH_ONE_WORD.test(parameter)) {
          const pathArr = parameter.split('/');
          let count = 0;
          let countTwoDots = 0;
          pathArr.forEach((item) => {
            if (item === '.') {
              count += 1;
            }
            if (item === '..') {
              countTwoDots += 1;
            }
          });
          if ((currentPath === '' && pathArr.length < 2) || (currentPath !== '' && count === 1 && countTwoDots === 0)) {
            // ./theheist.txt
            templateData.push(Ls.lsCheckInnerFile(data, parameter, currentPath));
          } else {
            // ../begin
            addToAccessAPathEvent(parameter, currentPath, true, true);
            templateData.push({
              template: LsMultipleFolder,
              data: {
                parameters: this.parameters,
                parameter,
                folderContent: data,
              },
            });
          }
        } else if (PATH_DOTS_SLASH_TWO_WORD.test(parameter)) {
          templateData.push(Ls.lsCheckInnerFile(data, parameter, currentPath));
        } else if (PATH_DOTS_SLASH_TWO_WORD_1.test(parameter)) {
          templateData.push(Ls.lsCheckInnerFile(data, parameter, currentPath));
        } else if (PATH_DOTS_SLASH_TWO_WORD_2.test(parameter)) {
          templateData.push(Ls.lsCheckInnerFile(data, parameter, currentPath));
        } else if (currentPath === '') {
          addToAccessAPathEvent(parameter, currentPath, true, true);
          templateData.push({
            template: LsMultipleFolder,
            data: {
              parameters: this.parameters,
              parameter,
              folderContent: data,
            },
          });
        } else {
          templateData.push(Ls.lsCheckInnerFile(data, parameter, currentPath));
        }
      }

      return {
        template: MultipleLsTemplate,
        data: {
          templateData,
        },
      };
    }
    return {
      template: ExceptionTemplate,
      data: {},
    };
  }

  async execCommand(args) {
    const { currentPath, accessToken, isAuthorized } = args;
    let template = {
      template: () => {},
      data: {},
    };
    if (this.parameters.length === 0) {
      const res = await getDirectoryByPath(currentPath, accessToken, isAuthorized);
      if (res && res.status === lsStatusCode.success) {
        addToAccessAPathEvent('(not set)', currentPath, true, true);
        return {
          template: LsDefault,
          data: {
            folderContent: res.data,
          },
        };
      }
      if (res && res.status === lsStatusCode.unauthorized) {
        addToAccessAPathEvent('(not set)', currentPath, false, true);
        return {
          template: LsUnauthorized,
          data: {
            parameter: currentPath,
          },
        };
      }
      return {
        template: ExceptionTemplate,
        data: {},
      };
    }
    const { actualPath, parameters } = lsWithDots(currentPath, this.parameters);
    const results = await Promise.all(
      actualPath.map(async (path) => {
        const pathResponse = await getDirectoryByPath(path, accessToken, isAuthorized);
        return pathResponse;
      })
    );
    template = this.lsWithArguments(currentPath, parameters, results);

    return template;
  }
}

export default Ls;
