import axios from './authAxios';
import { Options } from '../interfaces/services.interface';
import getOptions from '../utils/services.utils';

export async function getGeneratedPasswords(accessToken: string): Promise<unknown> {
  const backendUrl = '/player/password';
  const options: Options = getOptions(accessToken);

  try {
    const res = await axios(options).get(backendUrl);
    return res;
  } catch (error) {
    return error.response;
  }
}

export async function checkGeneratedPassword(password: string, accessToken: string): Promise<unknown> {
  const backendUrl = `/player/login/`;
  const options: Options = getOptions(accessToken);

  try {
    const res = await axios(options).post(backendUrl, { password });
    return res;
  } catch (error) {
    return error.response;
  }
}
