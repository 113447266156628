import { Headers, Options } from '../interfaces/services.interface';

export default function getOptions(accessToken: string, isRootAccess?: boolean): Options {
  let headers: Headers = {};

  if (accessToken && accessToken !== '') {
    headers = {
      accesstoken: `Bearer ${accessToken}`,
    };
  }
  let options: Options = { headers };

  if (isRootAccess) {
    options = {
      headers: {
        ...headers,
        'Is-Root-Access': true,
      },
    };
  }

  return options;
}
