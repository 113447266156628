import { passwordHoldersList } from '../../constants/password.constants';
import { UPDATE_PASSWORD_SIZE, UPDATE_PASSWORD_CLEAR, UPDATE_PASSWORD_CONTAINERS_SIZE } from '../constants/password.constants';

const initialData = {
  currentPassword: '',
  passwordResults: [[]],
  passwordHolders: [[passwordHoldersList.password]],
  passwordSize: [1],
  passwordAttempts: 0,
};

const passwordReducer = (state = initialData, action) => {
  switch (action.type) {
    case UPDATE_PASSWORD_SIZE: {
      const { currentPassword, passwordFinal, chancesLeft } = action.payload;
      let { passwordResult, passwordHolder } = action.payload;
      let { passwordAttempts } = state;
      const passwordSize = [...state.passwordSize];
      const passwordPos = passwordSize.length - 1;
      const size = passwordSize[passwordPos];
      const passwordHolders = [...state.passwordHolders];
      const passwordResults = [...state.passwordResults];

      if (!passwordHolder && passwordHolder !== '') passwordHolder = passwordHolders[passwordPos][passwordHolders[passwordPos].length - 1];
      if (!passwordResult) passwordResult = '';

      passwordResults[passwordPos].push(passwordResult);
      if (passwordFinal) {
        passwordAttempts = state.passwordAttempts + 1;
        return {
          ...state,
          currentPassword: !currentPassword ? state.currentPassword : currentPassword,
          passwordHolders,
          passwordResults,
          passwordSize,
          passwordAttempts,
        };
      }

      passwordSize[passwordPos] = size + 1;
      passwordHolders[passwordPos].push(passwordHolder);

      if (!passwordFinal && chancesLeft) {
        passwordAttempts = state.passwordAttempts + 1;
      }

      return {
        ...state,
        currentPassword: !currentPassword ? state.currentPassword : currentPassword,
        passwordHolders,
        passwordResults,
        passwordSize,
        passwordAttempts,
      };
    }
    case UPDATE_PASSWORD_CONTAINERS_SIZE: {
      const passwordSize = [...state.passwordSize];
      const passwordHolders = [...state.passwordHolders];
      const passwordResults = [...state.passwordResults];

      passwordSize.push(1);
      passwordHolders.push([passwordHoldersList.password]);
      passwordResults.push([]);
      return {
        ...state,
        currentPassword: '',
        passwordResults,
        passwordHolders,
        passwordSize,
      };
    }
    case UPDATE_PASSWORD_CLEAR: {
      return {
        ...state,
        currentPassword: '',
        passwordResults: [[]],
        passwordHolders: [[passwordHoldersList.password]],
        passwordSize: [1],
      };
    }
    default:
      return { ...state };
  }
};

export default passwordReducer;
