import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import MainPage from './pages/MainPage';
import { setUserData } from './redux/actions/user.actions';
import disableDefaultEvent from './utils/event.utils';
import { statusCode } from './constants/status.constants';
import NotFoundPage from './pages/NotFoundPage';
import CookieHandlerPage from './pages/CookieHandlerPage';

let setUserDataServiceInterval;

function App() {
  const dispatch = useDispatch();
  const { refreshToken } = useSelector((state) => state.userReducer);

  function checkCookie() {
    let { cookieEnabled } = navigator;
    if (!cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
    }
    return cookieEnabled || false;
  }

  if (checkCookie()) {
    useEffect(() => {
      if (localStorage.getItem('token')) {
        dispatch(setUserData(localStorage.getItem('token')));
      }
      document.addEventListener('contextmenu', disableDefaultEvent);
      return () => {
        document.removeEventListener('contextmenu', disableDefaultEvent);
      };
    }, []);

    const setUserDataCallback = React.useCallback(() => {
      if (!localStorage.getItem('token') && !refreshToken) {
        clearInterval(setUserDataServiceInterval);
      }
      setUserDataServiceInterval = setInterval(async () => {
        if (localStorage.getItem('token') || refreshToken) {
          const res = dispatch(setUserData(refreshToken));
          if (res) {
            res.then((resp) => {
              if (resp && resp.status === statusCode.unauthorized) {
                clearInterval(setUserDataServiceInterval);
              }
            });
          }
        }
      }, 600000);
      return () => {
        clearInterval(setUserDataServiceInterval);
      };
    }, [refreshToken]);

    useEffect(() => {
      clearInterval(setUserDataServiceInterval);
      setUserDataCallback();
    }, [refreshToken]);

    return (
      <BrowserRouter>
        <Route
          exact
          path="/"
          render={() => (
            <Sentry.ErrorBoundary fallback={({ resetError }) => <NotFoundPage resetError={resetError} />}>
              <MainPage />
            </Sentry.ErrorBoundary>
          )}
        />
        <Redirect to="/" />
      </BrowserRouter>
    );
  }
  return (
    <BrowserRouter>
      <Route exact path="/" component={CookieHandlerPage} />
    </BrowserRouter>
  );
}

export default App;
