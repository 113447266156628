import ClearFalseArgs from '../../../templates/phaseZero/clear';

class Clear {
  constructor(command, parameters, currentCommand) {
    this.command = command;
    this.parameters = parameters;
    this.currentCommand = currentCommand;
  }

  execCommand() {
    if (this.parameters.length > 0) {
      return {
        template: ClearFalseArgs,
        data: {},
      };
    }
    return {
      template: () => {},
      data: {},
    };
  }
}

export default Clear;
