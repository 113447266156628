import { allStatusCode } from '../../../constants/status.constants';
import roleMapping from '../../../constants/user.constants';
import { getGeneratedPasswords } from '../../../services/login.service';
import { LoginCorrectArg, LoginFalseArg, LoginTooManyArg, LoginUnauth } from '../../../templates/phaseOne/login';

const { loginStatusCode } = allStatusCode;

class Login {
  constructor(command, parameters) {
    this.command = command;
    this.parameters = parameters.map((parameter) => parameter.toLowerCase());
  }

  async execCommand(args) {
    const { userRole, accessToken } = args;
    let res;
    if (this.parameters.length !== 0) {
      return {
        template: LoginTooManyArg,
        data: {},
      };
    }

    if (userRole === roleMapping.guest) {
      res = await getGeneratedPasswords(accessToken);
    }

    if (res && res.status === loginStatusCode.success) {
      // eslint-disable-next-line no-console
      console.error('Security alert! Your computer is infected with a virus.\nAny submitted data might be sent to a server.');
      return {
        template: LoginCorrectArg,
        data: {
          passwords: res.data,
          accessDenied: true,
        },
      };
    }
    if (res && res.status === loginStatusCode.unauthorized) {
      return {
        template: LoginUnauth,
        data: {
          accessDenied: true,
          isGuest: true,
        },
      };
    }
    return {
      template: LoginFalseArg,
      data: {
        accessDenied: true,
        isGuest: true,
      },
    };
  }
}

export default Login;
