const EmailStart = () => <p>You need to register your email to begin the game.</p>;

const EmailInvalid = () => <p>Your email is not in valid format.</p>;

const EmailValid = () => (
  <div>
    <p>An activation code was sent to your email.</p>
    <p>You have 3 chances to input the activation code.</p>
  </div>
);

const EmailCodeExpired = () => <p>The activation code has expired.</p>;

const EmailCodeCorrect = () => <p>Congratulations! Now you are in the race to regain access to the server</p>;

const EmailCodeFalse = ({ chancesLeft }) => {
  if (chancesLeft <= 0) {
    return <p>You exceed the number of attempts.</p>;
  }
  return <p>Wrong activation code. {chancesLeft} chance(s) left.</p>;
};

const EmailSendError = () => <p>Activation code was not sent successfully.</p>;

export { EmailStart, EmailInvalid, EmailValid, EmailCodeExpired, EmailCodeCorrect, EmailCodeFalse, EmailSendError };
