/* eslint-disable import/no-cycle */
import { SudoNoArg, SudoNotFound } from '../../../templates/phaseTwo/sudo';
import getUsableCommands from '../../../utils/command.utils';
import getCommandFromTextStrategy from '../../../utils/getCmdClass.utils';

class Sudo {
  constructor(command, parameters, currentCommand) {
    this.currentCommand = currentCommand;
    this.command = command.toLowerCase();
    this.sudoCmd = '';
    this.parameters = [];
    if (parameters.length === 1) {
      this.parameters = [];
      this.sudoCmd = parameters[0].toLowerCase();
    } else if (parameters.length > 1) {
      this.parameters = parameters.slice(1).map((parameter) => parameter.toLowerCase());
      this.sudoCmd = parameters[0].toLowerCase();
    }
  }

  async execCommand(args) {
    const { userRole, currentPath, accessToken } = args;
    if (this.parameters.length === 0 && this.sudoCmd === '') {
      return {
        template: SudoNoArg,
        data: {},
      };
    }

    const usableCommands = getUsableCommands(userRole);
    if (!usableCommands.commands.includes(this.sudoCmd) || this.sudoCmd === 'sudo') {
      return {
        template: SudoNotFound,
        data: {
          command: this.sudoCmd,
        },
      };
    }

    const cmdStrategy = getCommandFromTextStrategy(this.sudoCmd, this.parameters, userRole, '', this.currentCommand);
    const templateResult = await cmdStrategy.execCommand({ currentPath, userRole, isAuthorized: true, accessToken });
    return templateResult;
  }
}

export default Sudo;
