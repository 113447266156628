import { sendActivationCodeToCheck, sendEmailForCode } from '../../../services/player.service';
import { EmailInvalid, EmailValid, EmailSendError, EmailCodeCorrect, EmailCodeExpired, EmailCodeFalse } from '../../../templates/phaseOne/email';
import { emailProcess, emailHoldersList } from '../../../constants/email.constants';
import { allStatusCode } from '../../../constants/status.constants';
import { ExceptionTemplate } from '../../../templates/exceptions';
import { EMAIL_REGEX } from '../../../constants/regex/user.regex';

const { emailStatusCode } = allStatusCode;

class Email {
  constructor(inputFromUser) {
    this.inputFromUser = inputFromUser.trimStart().trimEnd();
  }

  async initialEmailProcess(accessToken) {
    const email = this.inputFromUser;
    if (!email) {
      return {
        template: () => {},
        data: {},
      };
    }
    if (EMAIL_REGEX.test(email)) {
      const res = await sendEmailForCode(email, accessToken);
      if (res && res.status === emailStatusCode.success) {
        this.email = email;
        return {
          template: EmailValid,
          data: {
            emailPhase: emailProcess.firstValid,
            emailHolder: emailHoldersList.activationCode,
          },
        };
      }
      return {
        template: EmailSendError,
        data: {
          emailHolder: emailHoldersList.emailRetry,
        },
      };
    }
    return {
      template: EmailInvalid,
      data: {
        emailHolder: emailHoldersList.emailRetry,
      },
    };
  }

  static checkEmailPhase(emailPhase) {
    switch (emailPhase) {
      case emailProcess.firstValid:
        return {
          template: EmailCodeFalse,
          data: {
            emailPhase: emailProcess.firstInvalid,
            chancesLeft: 2,
          },
        };
      case emailProcess.firstInvalid:
        return {
          template: EmailCodeFalse,
          data: {
            emailPhase: emailProcess.secondInvalid,
            chancesLeft: 1,
          },
        };
      case emailProcess.secondInvalid:
        return {
          template: EmailCodeFalse,
          data: {
            emailPhase: emailProcess.thirdInvalid,
            chancesLeft: 0,
          },
        };
      default:
        break;
    }
    return {
      template: () => {},
      data: {},
    };
  }

  async checkValidEmailProcess(email, emailPhase, accessToken) {
    const activationCode = this.inputFromUser;
    if (activationCode.trim() !== '') {
      const res = await sendActivationCodeToCheck(email, activationCode, accessToken);
      if (res) {
        if (res.status === emailStatusCode.success) {
          if (!localStorage.getItem('token')) {
            localStorage.setItem('token', res.data.refresh);
          }
          return {
            template: EmailCodeCorrect,
            data: {
              emailPhase: emailProcess.finalValid,
              emailHolder: '',
              accessToken: res.data.access || '',
              refreshToken: res.data.refresh || '',
            },
          };
        }
        if (res.status === emailStatusCode.expired) {
          return {
            template: EmailCodeExpired,
            data: {
              emailPhase: emailProcess.expiredInvalid,
            },
          };
        }
        if (res.status === emailStatusCode.notFound) {
          return Email.checkEmailPhase(emailPhase);
        }
      }
      return {
        template: ExceptionTemplate,
        data: {},
      };
    }
    return {
      template: () => {},
      data: {},
    };
  }

  async execCommand(args) {
    const { email, emailPhase, accessToken } = args;
    const { initial, firstValid, firstInvalid, secondInvalid } = emailProcess;
    switch (emailPhase) {
      case initial: {
        const result = await this.initialEmailProcess(accessToken);
        return result;
      }
      case firstValid:
      case firstInvalid:
      case secondInvalid: {
        const result = await this.checkValidEmailProcess(email, emailPhase, accessToken);
        return result;
      }
      default:
        return {
          template: () => {},
          data: {},
        };
    }
  }
}

export default Email;
