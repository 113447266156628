import { allStatusCode } from '../../../constants/status.constants';
import { checkFirewall, restartFirewall } from '../../../services/firewall.service';
import { getPlayerUsername } from '../../../services/player.service';
import { ExceptionTemplate } from '../../../templates/exceptions';
import { RestartFalseArg, RestartDenied, RestartActive, RestartInactive } from '../../../templates/phaseTwo/restart';
import addToRestartSuccessEvent from '../../../utils/dataLayer/restartSuccess';

const { firewallStatusCode, userStatusCode } = allStatusCode;

class Restart {
  constructor(command, parameters, currentCommand) {
    this.command = command;
    this.parameters = parameters.map((parameter) => parameter.toLowerCase());
    this.currentCommand = currentCommand;
  }

  async execCommand(args) {
    const { isAuthorized, accessToken } = args;
    if (isAuthorized) {
      if (this.parameters.length > 0) {
        return {
          template: RestartFalseArg,
          data: {},
        };
      }
      const resIsActive = await checkFirewall(accessToken, isAuthorized);
      if (resIsActive && resIsActive.status === firewallStatusCode.success) {
        const restartRes = await restartFirewall(accessToken, isAuthorized);
        if (restartRes && restartRes.status === firewallStatusCode.success) {
          const milestones = restartRes.data.record;
          if (restartRes.data.status) {
            const usernameRes = await getPlayerUsername(accessToken);
            if (usernameRes?.status === userStatusCode.success && usernameRes?.data?.username) {
              addToRestartSuccessEvent(usernameRes.data.username, milestones);
              return {
                template: RestartActive,
                data: {
                  isResetSession: true,
                  username: usernameRes.data.username,
                  milestones,
                },
              };
            }
            if (usernameRes?.status === userStatusCode.success && !usernameRes?.data?.username) {
              return {
                template: RestartActive,
                data: {
                  isReset: true,
                  milestones,
                },
              };
            }
          }
          if (!restartRes.data.status) {
            return {
              template: RestartActive,
              data: {
                isReset: true,
                milestones,
              },
            };
          }
        }
      }
      if (resIsActive && resIsActive.status === firewallStatusCode.notacceptable) {
        return {
          template: RestartInactive,
          data: {},
        };
      }
      return {
        template: ExceptionTemplate,
        data: {},
      };
    }
    return {
      template: RestartDenied,
      data: {},
    };
  }
}

export default Restart;
