import axios from './authAxios';
import { Options } from '../interfaces/services.interface';
import getOptions from '../utils/services.utils';

export async function sendEmailForCode(email: string, accessToken: string): Promise<unknown> {
  const backendUrl = 'player/activation/';
  const options: Options = getOptions(accessToken);

  try {
    const res = await axios(options).post(backendUrl, { email });
    return res;
  } catch (error) {
    return error.response?.data;
  }
}

export async function sendActivationCodeToCheck(email: string, activationCode: string, accessToken: string): Promise<unknown> {
  const backendUrl = 'player/verify/';
  const options: Options = getOptions(accessToken);

  try {
    const res = await axios(options).post(backendUrl, { email, activation_code: activationCode });
    return res;
  } catch (error) {
    return error.response;
  }
}

export async function refreshAccessToken(refreshToken: string): Promise<unknown> {
  const backendUrl = 'player/token/refresh/';
  const options: Options = getOptions('');

  try {
    const res = await axios(options).post(backendUrl, { refresh: refreshToken });
    return res;
  } catch (error) {
    return error.response;
  }
}

export async function blacklistToken(accessToken: string, refreshToken: string): Promise<unknown> {
  const backendUrl = 'player/blacklist/';
  const options: Options = getOptions(accessToken);
  try {
    const res = await axios(options).post(backendUrl, { refresh: refreshToken });
    return res;
  } catch (error) {
    return error.response;
  }
}

export async function getUserRole(accessToken: string): Promise<unknown> {
  const backendUrl = 'player/get_role';
  const options: Options = getOptions(accessToken);

  try {
    const res = await axios(options).get(backendUrl);
    return res;
  } catch (error) {
    return error.response;
  }
}

export async function getRanking(): Promise<unknown> {
  const backendUrl = 'player/ranking';
  const options: Options = getOptions('');

  try {
    const res = await axios(options).get(backendUrl);
    return res;
  } catch (error) {
    return error.response;
  }
}

export async function getPlayerUsername(accessToken: string): Promise<unknown> {
  const backendUrl = 'player/get_username/';
  const options: Options = getOptions(accessToken);

  try {
    const res = await axios(options).get(backendUrl);
    return res;
  } catch (error) {
    return error.response;
  }
}
