import { Link } from 'react-router-dom';
import LogoContainer from '../../components/LogoTextComponent';
import { TerminalPageContainer, TextContainer } from '../MainPage/styles';

export default function CookieHandlerPage(props) {
  const { resetError } = props;
  return (
    <TerminalPageContainer>
      <LogoContainer />
      <TextContainer>
        <h5>Warning!!! An error has occured on the server!</h5>
        <h3 style={{ maxWidth: 900 }}>
          Please check if your browser is blocking any cookies; accessing local storage is required to store your session here on our server!
        </h3>
      </TextContainer>
      <p>
        After enabling cookies, try accessing the server again at{' '}
        {resetError ? (
          <Link to="/" onClick={resetError}>
            https://serverheist.com
          </Link>
        ) : (
          <Link to="/">https://serverheist.com</Link>
        )}
      </p>
    </TerminalPageContainer>
  );
}
