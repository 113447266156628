import { allStatusCode } from '../../../constants/status.constants';
import { checkProcessKilled, killAProcess } from '../../../services/process.service';
import {
  KillFalseArg,
  KillNoArg,
  KillDenied,
  KillCorrectArg,
  KillConnection,
  KillMultiples,
  KillCurrentProcess,
  KillConnectionWithArg,
} from '../../../templates/phaseTwo/kill';
import getUniqueValues from '../../../utils/getUniqueValue.utils';

const { killStatusCode } = allStatusCode;

class Kill {
  constructor(command, parameters) {
    this.command = command;
    const uniqueParameters = getUniqueValues(parameters);
    this.parameters = uniqueParameters.map((parameter) => parameter.toLowerCase());
    this.arr = [];
  }

  killProcesses(processResponse, resKilled) {
    if (processResponse) {
      let processesData = processResponse.map((res) => {
        const availableStatus = Object.keys(killStatusCode).map((key) => killStatusCode[key]);
        if (res) {
          return {
            status: res?.status,
            data: availableStatus.includes(res.status) ? res?.data : undefined,
          };
        }
        return undefined;
      });

      const sortedParameters = [];
      const templateData = [];
      let virusKilledFlag = false;

      const unsuccessData = processesData.filter((res, idx) => {
        if (res && res.status === killStatusCode.notfound) {
          sortedParameters.push(this.parameters[idx]);
        }
        return res && res.status === killStatusCode.notfound;
      });
      const successData = processesData.filter((res, idx) => {
        if (res && res.status === killStatusCode.success) {
          sortedParameters.push(this.parameters[idx]);
        }
        return res && res.status === killStatusCode.success;
      });
      const unacceptedData = processesData.filter((res, idx) => {
        if (res && res.status === killStatusCode.notacceptable) {
          sortedParameters.push(this.parameters[idx]);
        }
        return res && res.status === killStatusCode.notacceptable;
      });

      processesData = unacceptedData.concat(unsuccessData);
      processesData = processesData.concat(successData);
      processesData.forEach(async (res, idx) => {
        if (res) {
          if (res && res.status === killStatusCode.success) {
            if (resKilled && resKilled.status === killStatusCode.success) {
              virusKilledFlag = true;
            }
            templateData.push({
              template: KillCorrectArg,
              data: {
                argument: sortedParameters[idx],
              },
            });
          } else if (res && res.status === killStatusCode.notfound) {
            templateData.push({
              template: KillFalseArg,
              data: {
                argument: sortedParameters[idx],
              },
            });
          } else if (res && res.status === killStatusCode.notacceptable) {
            templateData.push({
              template: KillCurrentProcess,
              data: {},
            });
          } else {
            templateData.push({
              template: KillConnectionWithArg,
              data: {
                argument: sortedParameters[idx],
              },
            });
          }
        }
      });
      return {
        template: KillMultiples,
        data: {
          isKilled: virusKilledFlag,
          templateData,
        },
      };
    }

    return {
      template: KillConnection,
      data: {},
    };
  }

  async getAllProcesses(processes, accessToken, isAuthorized) {
    for (let i = 0; i < processes.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      this.arr.push(await killAProcess(processes[i], accessToken, isAuthorized));
    }
  }

  async execCommand(args) {
    const { accessToken, isAuthorized } = args;
    const template = {
      template: () => {},
      data: {},
    };
    if (this.parameters.length === 0) {
      return {
        template: KillNoArg,
        data: {},
      };
    }
    if (!isAuthorized) {
      return {
        template: KillDenied,
        data: {
          argument: this.parameters,
        },
      };
    }
    if (isAuthorized) {
      const uniqueParameters = getUniqueValues(this.parameters);
      await this.getAllProcesses(uniqueParameters, accessToken, isAuthorized);

      const resKilled = await checkProcessKilled(accessToken, isAuthorized);
      return this.killProcesses(this.arr, resKilled);
    }
    return template;
  }
}

export default Kill;
