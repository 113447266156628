import { ExceptionTemplate } from '../../../templates/exceptions';
import { ExitCorrectArg, ExitTooManyArg } from '../../../templates/phaseTwo/exit';

class Exit {
  constructor(command, parameters, currentCommand) {
    this.command = command;
    this.parameters = parameters.map((parameter) => parameter.toLowerCase());
    this.currentCommand = currentCommand;
  }

  async execCommand(args) {
    const { accessToken } = args;
    if (this.parameters.length > 0) {
      return {
        template: ExitTooManyArg,
        data: {},
      };
    }
    if (accessToken && accessToken !== '') {
      return {
        template: ExitCorrectArg,
        data: {
          isResetSession: true,
        },
      };
    }

    if (!accessToken || accessToken === '') {
      return {
        template: ExitCorrectArg,
        data: {
          isResetSession: true,
        },
      };
    }

    return {
      template: ExceptionTemplate,
      data: {},
    };
  }
}

export default Exit;
