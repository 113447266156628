import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import TagManager from 'react-gtm-module';
import rootReducer from './redux/rootReducer';
import App from './App';
import { ENV, SENTRY_DSN } from './constants/env.constants';

const tagManagerArgs = {
  gtmId: 'GTM-5QCPQ2H',
};

TagManager.initialize(tagManagerArgs);

// START ENTRY
Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENV || 'unknown',
  integrations: [new Integrations.BrowserTracing({})],
  tracesSampleRate: 1.0,
});
// END SENTRY

let composeEnhancers = compose;
if (ENV === 'development') {
  composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
}
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
