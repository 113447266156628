import usernameHoldersList from '../../../constants/username.constants';
import { UsernameInvalid, UsernameValid, ExistedUsername } from '../../../templates/phaseTwo/username';
import sendUsername from '../../../services/username.service';
import { allStatusCode } from '../../../constants/status.constants';
import addToRestartSuccessEvent from '../../../utils/dataLayer/restartSuccess';
import { restartFirewall } from '../../../services/firewall.service';
import { USERNAME_REGEX, USERNAME_REGEX_SUB } from '../../../constants/regex/user.regex';

const { usernameStatusCode, restartStatusCode } = allStatusCode;

class Username {
  constructor(inputFromUser, email) {
    this.inputFromUser = inputFromUser.trimStart().trimEnd();
    this.email = email;
  }

  checkValidationUsername() {
    const username = this.inputFromUser;
    const valid = username.match(USERNAME_REGEX);
    if (valid !== null && username.match(USERNAME_REGEX_SUB)?.length >= 2) {
      return true;
    }
    return false;
  }

  async execCommand(args) {
    const { accessToken } = args;
    const template = {
      template: () => {},
      data: {
        usernameHolder: usernameHoldersList.usernameRetry,
        usernameFinal: false,
      },
    };
    if (this.inputFromUser.trim('') === '') return template;
    if (this.checkValidationUsername()) {
      const res = await sendUsername(this.inputFromUser, accessToken);
      if (res) {
        if (res.status === usernameStatusCode.accepted) {
          const restartRes = await restartFirewall(accessToken, true);
          const milestones = restartRes.data.record;
          if (restartRes?.status === restartStatusCode.success) {
            addToRestartSuccessEvent(this.inputFromUser, milestones);
          } else {
            addToRestartSuccessEvent(this.inputFromUser, null);
          }
          return {
            template: UsernameValid,
            data: {
              usernameFinal: true,
              isValid: true,
              isResetSession: true,
            },
          };
        }
        if (res.status === usernameStatusCode.notacceptable) {
          return {
            template: UsernameInvalid,
            data: {
              usernameHolder: usernameHoldersList.usernameRetry,
              usernameFinal: false,
            },
          };
        }
        if (res.status === usernameStatusCode.conflict) {
          return {
            template: ExistedUsername,
            data: {
              username: this.inputFromUser,
              usernameHolder: usernameHoldersList.usernameRetry,
              usernameFinal: false,
            },
          };
        }
      }
    }
    return {
      template: UsernameInvalid,
      data: {
        usernameHolder: usernameHoldersList.usernameRetry,
        usernameFinal: false,
      },
    };
  }
}

export default Username;
