import axios, { AxiosInstance } from 'axios';
import { BACKEND_URL } from '../constants/env.constants';
import { Options } from '../interfaces/services.interface';

export default function authAxios(options: Options): AxiosInstance {
  const { headers } = options;

  return axios.create({
    baseURL: BACKEND_URL,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  });
}
