const ExitCorrectArg = () => {
  return (
    <>
      <p>
        We are sorry to see you leave. <br />
        Try better next time to complete the mission. <br />
        See you again soon!
      </p>
    </>
  );
};

const ExitTooManyArg = () => <>exit: Too many arguments</>;

export { ExitCorrectArg, ExitTooManyArg };
