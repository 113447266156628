import {
  UPDATE_TERMINAL,
  UPDATE_TERMINAL_CLEAR,
  UPDATE_CURRENT_PROMPT,
  CLEAR_SCREEN,
  UPDATE_EMPTY_TERMINAL,
  UPDATE_COMMAND_AND_RESULTS,
} from '../constants/terminal.constants';

function updateTerminal() {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TERMINAL,
    });
  };
}

function updateTerminalClear(currentCommand, isClearCmd) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TERMINAL_CLEAR,
      payload: {
        currentCommand,
        isClearCmd,
      },
    });
  };
}

function updateCurrentPrompt(currentCommand, command, result, template) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CURRENT_PROMPT,
      payload: {
        currentCommand,
        command,
        result,
        template,
      },
    });
  };
}

function updateCommandsAndResults(currentCommand, command, templateResult, type) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_COMMAND_AND_RESULTS,
      payload: {
        currentCommand,
        command,
        templateResult,
        type,
      },
    });
  };
}

function updateEmptyTerminal() {
  return (dispatch) => {
    dispatch({
      type: UPDATE_EMPTY_TERMINAL,
    });
  };
}

function clearScreen() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SCREEN,
    });
  };
}

export { updateTerminal, updateTerminalClear, updateCurrentPrompt, updateEmptyTerminal, clearScreen, updateCommandsAndResults };
