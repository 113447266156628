const KillCorrectArg = ({ argument }) => {
  return <>Process {argument} has been stopped.</>;
};

const KillNoArg = () => <>kill: Missing argument</>;

const KillFalseArg = ({ argument }) => <>kill: {argument}: Process not found</>;

const KillCurrentProcess = () => <>Action terminated. Can not kill current running process.</>;

const KillDenied = ({ argument }) => argument?.map((item) => <p key={`denied-${item}`}>kill: {item}: Access denied</p>);

const KillConnection = () => <>Killing process was not successful. Please try again!</>;

const KillConnectionWithArg = ({ argument }) => <>Killing process was not successful for PID {argument}. Please try again!</>;

const KillMultiples = ({ templateData }) => {
  const deniedIdx = templateData?.findIndex((data) => {
    return data.template.name === 'KillFalseArg';
  });
  const acceptIdx = templateData?.findIndex((data) => {
    return data.template.name === 'KillCorrectArg';
  });
  return templateData?.map(({ template, data }, idx) => {
    if (idx === acceptIdx && deniedIdx !== -1) {
      return (
        <>
          <br />
          <div key={`cmd-multiple-kill-${idx}`}>{template(data)}</div>
        </>
      );
    }

    return <div key={`cmd-multiple-kill-${idx}`}>{template(data)}</div>;
  });
};

export { KillCorrectArg, KillNoArg, KillFalseArg, KillCurrentProcess, KillDenied, KillConnection, KillConnectionWithArg, KillMultiples };
