import { ReactNode } from 'react';

const CatNoArgs = (): ReactNode => <>cat: Missing argument</>;

const CatFolderArg = ({ directory }: { directory: string }): ReactNode => <>cat: {directory}: Is a directory</>;

const CatFalseArgs = ({ argument }: { argument: string }): ReactNode => <>cat: {argument}: No such file or directory</>;

const CatCorrectArg = ({ fileContent }: { fileContent: string }): ReactNode => <>{fileContent}</>;

const CatAccessDeny = ({ argument }: { argument: string }): ReactNode => <>cat: {argument}: Access denied</>;

const CatAudioArgs = ({ argument }: { argument: string }): ReactNode => <>cat: {argument}: Operation not allowed</>;

export { CatNoArgs, CatFolderArg, CatFalseArgs, CatCorrectArg, CatAccessDeny, CatAudioArgs };
