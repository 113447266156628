import { ReactNode } from 'react';

const OpenNoArgs = (): ReactNode => <>open: Missing argument</>;

const OpenNotAudioArg = ({ directory }: { directory: string }): ReactNode => <>open: {directory}: Not an audio file</>;

const OpenCorrectArg = ({ fileContent }: { fileContent: string }): ReactNode => {
  const base64 = `data:audio/ogg;base64,${fileContent}`;
  return (
    <audio controls>
      <track kind="captions" />
      <source src={base64} type="audio/mp3" />
      Your browser does not support html5 audio
    </audio>
  );
};

const OpenNoFileFound = ({ argument }: { argument: string }): ReactNode => <>open: {argument}: No such file or directory</>;

const OpenAccessDeny = ({ argument }: { argument: string }): ReactNode => <>open: {argument}: Access denied</>;

export { OpenNoArgs, OpenNotAudioArg, OpenCorrectArg, OpenAccessDeny, OpenNoFileFound };
