import CommandController from '../models/CommandStrategy';
import InvalidCommand from '../models/commands/phaseZero/InvalidCommand';

import Cat from '../models/commands/phaseZero/Cat';
import Cd from '../models/commands/phaseZero/Cd';
import Clear from '../models/commands/phaseZero/Clear';
import Help from '../models/commands/phaseZero/Help';
import Ls from '../models/commands/phaseZero/Ls';
import Pwd from '../models/commands/phaseZero/Pwd';
import Tab from '../models/commands/phaseZero/Tab';
import Login from '../models/commands/phaseOne/Login';
import Open from '../models/commands/phaseOne/Open';
import Mv from '../models/commands/phaseOne/Mv';
import Ps from '../models/commands/phaseOne/Ps';
import Kill from '../models/commands/phaseTwo/Kill';
import Ufw from '../models/commands/phaseTwo/Ufw';
import Restart from '../models/commands/phaseTwo/Restart';
import Editor from '../models/commands/phaseTwo/Editor';
import Ranking from '../models/commands/phaseTwo/Ranking';
import Exit from '../models/commands/phaseTwo/Exit';
// eslint-disable-next-line import/no-cycle
import Sudo from '../models/commands/phaseTwo/Sudo';
import { adminCmd, baseCmd, guestCmd, initialCmd, loginUserCmd } from '../constants/commands.constants';
import roleMapping from '../constants/user.constants';

const allCommandClasses = {
  help: (cmd, args) => new Help(cmd, args),
  cd: (cmd, args) => new Cd(cmd, args),
  clear: (cmd, args, currentCommand) => new Clear(cmd, args, currentCommand),
  pwd: (cmd, args) => new Pwd(cmd, args),
  ls: (cmd, args) => new Ls(cmd, args),
  cat: (cmd, args) => new Cat(cmd, args),
  login: (cmd, args) => new Login(cmd, args),
  open: (cmd, args) => new Open(cmd, args),
  mv: (cmd, args) => new Mv(cmd, args),
  sudo: (cmd, args, currentCommand) => new Sudo(cmd, args, currentCommand),
  ps: (cmd, args) => new Ps(cmd, args),
  kill: (cmd, args) => new Kill(cmd, args),
  ufw: (cmd, args) => new Ufw(cmd, args),
  restart: (cmd, args, currentCommand) => new Restart(cmd, args, currentCommand),
  editor: (cmd, args) => new Editor(cmd, args),
  ranking: (cmd, args) => new Ranking(cmd, args),
  exit: (cmd, args, currentCommand) => new Exit(cmd, args, currentCommand),
};

function getCommandFromTextStrategy(cmd, args, userRole, exceptions, currentCommand = '') {
  const commandStrategy = new CommandController();
  const lowercaseCmd = cmd.toLowerCase();
  const baseCmds = baseCmd;
  if (exceptions === 'tab') {
    commandStrategy.strategy = new Tab(cmd, args);
    return commandStrategy;
  }

  let acceptableCommands = initialCmd.commands;

  if (userRole === roleMapping.guest) {
    acceptableCommands = { ...initialCmd }.commands.concat(guestCmd.commands);
  } else if (userRole === roleMapping.user) {
    acceptableCommands = { ...initialCmd }.commands.concat(loginUserCmd.commands);
  } else if (userRole === roleMapping.admin) {
    acceptableCommands = { ...initialCmd }.commands.concat(adminCmd.commands);
  }

  if (baseCmds.commands.includes(lowercaseCmd) && !acceptableCommands.includes(lowercaseCmd)) {
    commandStrategy.strategy = new InvalidCommand(lowercaseCmd, args, true);
    return commandStrategy;
  }

  if (acceptableCommands.includes(lowercaseCmd)) {
    if (lowercaseCmd === 'sudo' || lowercaseCmd === 'clear' || lowercaseCmd === 'restart' || lowercaseCmd === 'exit') {
      commandStrategy.strategy = allCommandClasses[lowercaseCmd](lowercaseCmd, args, currentCommand);
    } else commandStrategy.strategy = allCommandClasses[lowercaseCmd](lowercaseCmd, args);
    return commandStrategy;
  }

  commandStrategy.strategy = new InvalidCommand(cmd, args, false);
  return commandStrategy;
}

export default getCommandFromTextStrategy;
