import styled from 'styled-components';
import colors from '../constants/colors';
import { checkBrowser } from '../utils/browser.utils';
import textMapping from '../constants/browser.constants';

const SHLogoContainer = styled.div`
  color: ${colors.logoColor};
  margin-right: 2.5rem;
  margin-bottom: 1rem;
  p {
    font-size: ${(props) => (textMapping[props.browser] ? textMapping[props.browser].mobileFontSize : textMapping.default.mobileFontSize)};
    line-height: ${(props) => (textMapping[props.browser] ? textMapping[props.browser].mobileLineHeight : textMapping.default.mobileLineHeight)};
    font-weight: bold;
  }

  @media (min-width: 420px) {
    p {
      font-size: ${(props) => (textMapping[props.browser] ? textMapping[props.browser].laptopFontSize : textMapping.default.laptopFontSize)};
      line-height: ${(props) => (textMapping[props.browser] ? textMapping[props.browser].laptopLineHeight : textMapping.default.laptopLineHeight)};
    }
  }

  @media (min-width: 1600px) {
    p {
      font-size: ${(props) => (textMapping[props.browser] ? textMapping[props.browser].desktopFontSize : textMapping.default.desktopFontSize)};
      line-height: ${(props) => (textMapping[props.browser] ? textMapping[props.browser].desktopLineHeight : textMapping.default.desktopLineHeight)};
    }
  }
`;

const SHTextContainer = styled.div`
  color: ${colors.shLogoColor};
  margin-bottom: 1rem;
  p {
    font-size: 2vw;
    line-height: 2vw;
    vertical-align: middle;
  }

  @media (min-width: 420px) {
    p {
      font-size: 0.75rem;
      line-height: 0.75rem;
    }
  }

  @media (min-width: 576px) {
    p {
      font-size: 0.9rem;
      line-height: 0.9rem;
    }
  }
`;

const FlexContainer = styled.div`
  @media (min-width: 576px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
`;

export default function LogoTextComponent() {
  const browser = checkBrowser();

  return (
    <FlexContainer>
      <SHLogoContainer browser={browser}>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; `:shyo:` &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </p>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;./yhddddyd/ &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </p>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;ohhdddyo/.-do. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;dyod+:` &nbsp; .d+:++: &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </p>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; .:+do.h &nbsp; &nbsp; &nbsp;.d/ &nbsp;`-o+/- &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </p>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;.:o+-`do.h &nbsp; &nbsp; &nbsp;.d/ &nbsp; &nbsp; `./o+-` &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </p>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;`-+o:.` &nbsp; do.h &nbsp; &nbsp; &nbsp;.d/ &nbsp; &nbsp; &nbsp; &nbsp;`.:/+:. &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; &nbsp;`/oo/-`./o+-` &nbsp; &nbsp; &nbsp; do.h &nbsp; &nbsp; &nbsp;.d/ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;`-/o/-` &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        </p>
        <p>
          &nbsp; -+//-``-/oy+. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;do.h &nbsp; &nbsp; &nbsp;.d/ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; `.:++:.
          &nbsp; `. &nbsp;&nbsp;
        </p>
        <p>
          +s+. &nbsp; &nbsp; &nbsp; &nbsp;.:+/:.` &nbsp; &nbsp; &nbsp;do.h &nbsp; &nbsp; &nbsp;.d/ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; .:/+++yhyo`
        </p>
        <p>
          hhys+.` &nbsp; &nbsp; &nbsp; &nbsp;`.+o/-` &nbsp; do.h &nbsp; &nbsp; &nbsp;.d/ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; .:ohdddhyd/
        </p>
        <p>
          hs.:+yyo:` &nbsp; &nbsp; &nbsp; &nbsp;`.-+o/.do.h &nbsp; &nbsp; &nbsp;.d/ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          .shddhy+:.-d/
        </p>
        <p>
          hs /++//oys/- &nbsp; &nbsp; &nbsp; &nbsp; `-:ys/h &nbsp; &nbsp; &nbsp;.d/ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ::/d/-`
          &nbsp; .d/
        </p>
        <p>
          hs +--/o///osy+:` &nbsp; &nbsp; &nbsp; &nbsp;`.:o//. &nbsp; .d/ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; :.`d &nbsp; &nbsp;
          &nbsp;.d/
        </p>
        <p>hs ++ &nbsp;`.:/o//+sso/. &nbsp; &nbsp; &nbsp; &nbsp; `-///-:d/ &nbsp; &nbsp;.:+/-` &nbsp; &nbsp; &nbsp; :.`d &nbsp; &nbsp; &nbsp;.d/</p>
        <p>hs ++ &nbsp; &nbsp; .dy/o/:+osy+-` &nbsp; &nbsp; &nbsp; &nbsp;`./oy+../oys+osy+:.` &nbsp; :.`d &nbsp; &nbsp; &nbsp;.d/</p>
        <p>hs ++ &nbsp; `./yho////+//osyo:.` &nbsp; &nbsp; &nbsp; &nbsp;`-+shs- &nbsp; `.:sys/-`:.`d &nbsp; &nbsp; &nbsp;.d/</p>
        <p>hs +o`-++:.`./sys/://///+sys/-` &nbsp; &nbsp; &nbsp; `-oh/ &nbsp; &nbsp; &nbsp;`.-syyy.`d &nbsp; &nbsp; &nbsp;.d/</p>
        <p>hs +h+-. &nbsp; &nbsp; &nbsp; .:osyo:/+////oys+:. `-oyhdds.` &nbsp; &nbsp; &nbsp; &nbsp;`-o.`d &nbsp; &nbsp; &nbsp;.d/</p>
        <p>yy/:///-.` &nbsp; &nbsp; &nbsp; &nbsp;`-/sss+-/+//:+sysyhdhhdo//+/.` &nbsp; &nbsp; &nbsp; :.`d &nbsp; &nbsp; &nbsp;.d/</p>
        <p>.:ohy+////:.` &nbsp; &nbsp; &nbsp; &nbsp; .:oss+//++:sdhy/--+syo///+/:. &nbsp; &nbsp;:.`d &nbsp; &nbsp; &nbsp;.d/</p>
        <p>&nbsp; &nbsp;+o+sys/://+-` &nbsp; &nbsp; &nbsp; &nbsp; `-+sso/./:.` &nbsp; &nbsp;`./sys+:++/:.`:.`d &nbsp; &nbsp; &nbsp;.d/</p>
        <p>
          &nbsp; &nbsp;++ &nbsp;.:oyy/////:.` &nbsp; &nbsp; &nbsp;`.:hdy &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; `-oyyo/:++o.`d &nbsp; &nbsp;
          &nbsp;.d/
        </p>
        <p>
          &nbsp; &nbsp;++ &nbsp; &nbsp; `-/sys::///.``./shddds &nbsp; &nbsp; &nbsp; ` &nbsp; &nbsp; &nbsp; &nbsp;`./sys+:``d &nbsp; &nbsp; &nbsp;.d/
        </p>
        <p>&nbsp; &nbsp;++ &nbsp; `-` &nbsp;`.:sys+-/+ydddhho:. &nbsp; `.:oso/- &nbsp; &nbsp; &nbsp; &nbsp; `:odo`d &nbsp; &nbsp; &nbsp;.d/</p>
        <p>&nbsp; &nbsp;+o-/syyy+:.` &nbsp;.-+ysydhy+:. &nbsp; `./shddhyhh` &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; hs`d &nbsp; &nbsp; &nbsp;.d/</p>
        <p>&nbsp;.:yhyo:.`.:sys/.` &nbsp;`-//- &nbsp; &nbsp; `+ydddhs+-`+d` &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; hs`d &nbsp; &nbsp; &nbsp;.d/</p>
        <p>
          ohho-` &nbsp; &nbsp; &nbsp; .-syyo-` &nbsp; &nbsp; &nbsp; &nbsp; :y/hy/. &nbsp; &nbsp;+d` &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; hs`d &nbsp;
          &nbsp; &nbsp;.d/
        </p>
        <p>
          hy/++:.` &nbsp; &nbsp; &nbsp; &nbsp;`-/yys+.` &nbsp; &nbsp; :y /o &nbsp; &nbsp; &nbsp;+d` &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; hs`d &nbsp;
          &nbsp; &nbsp;-d/
        </p>
        <p>
          +ys+//+o/.` &nbsp; &nbsp; &nbsp; &nbsp; .:+yso.` &nbsp;:y /o &nbsp; &nbsp; &nbsp;+d` &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; hs`d &nbsp;``-oyy:
        </p>
        <p>
          &nbsp; -+syo//+++-` &nbsp; &nbsp; &nbsp; &nbsp; `-/yyo//y /o &nbsp; &nbsp; &nbsp;+d` &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;`hy:d.:syy/.`&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; &nbsp;`/oys+:+++:.` &nbsp; &nbsp; &nbsp; &nbsp;`./ohy /o &nbsp; &nbsp; &nbsp;+d` &nbsp; &nbsp; &nbsp; .://:oyhy+:. &nbsp;
          &nbsp;&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp; `:oyyo::+o/-` &nbsp; &nbsp; &nbsp; &nbsp; /y /o &nbsp; &nbsp; &nbsp;+d` &nbsp; `-//+.` &nbsp; &nbsp;.` &nbsp;
          &nbsp; &nbsp; &nbsp;
        </p>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; .+syy/:+o+:.` &nbsp; &nbsp; :y /o &nbsp; &nbsp; &nbsp;+d.`-/o:` &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp;
        </p>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;`-oyy+::oo+-` &nbsp;:y /o &nbsp; &nbsp; &nbsp;+d+/-` &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;./yhs/-:oo-/y /o &nbsp; &nbsp; &nbsp;+d. &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </p>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; `:ohy+:-++ /o &nbsp; .:ohy` &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </p>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; -+yhs/./o./shs+- &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </p>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;.:ohhhyo/` &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        </p>
        <p>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;-. &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        </p>
      </SHLogoContainer>
      <SHTextContainer>
        <p>░██████╗░███████╗░██████╗░░██╗░░░██╗░███████╗░██████╗░ </p>
        <p>██╔════╝░██╔════╝░██╔══██╗░██║░░░██║░██╔════╝░██╔══██╗ </p>
        <p>╚█████╗░░█████╗░░░██████╔╝░╚██╗░██╔╝░█████╗░░░██████╔╝ </p>
        <p>░╚═══██╗░██╔══╝░░░██╔══██╗░░╚████╔╝░░██╔══╝░░░██╔══██╗ </p>
        <p>██████╔╝░███████╗░██║░░██║░░░╚██╔╝░░░███████╗░██║░░██║ </p>
        <p>╚═════╝░░╚══════╝░╚═╝░░╚═╝░░░░╚═╝░░░░╚══════╝░╚═╝░░╚═╝ </p>
        <br />
        <p>██╗░░██╗░███████╗░██╗░░██████╗░████████╗</p>
        <p>██║░░██║░██╔════╝░██║░██╔════╝░╚══██╔══╝</p>
        <p>███████║░█████╗░░░██║░╚█████╗░░░░░██║░░░</p>
        <p>██╔══██║░██╔══╝░░░██║░░╚═══██╗░░░░██║░░░</p>
        <p>██║░░██║░███████╗░██║░██████╔╝░░░░██║░░░</p>
        <p>╚═╝░░╚═╝░╚══════╝░╚═╝░╚═════╝░░░░░╚═╝░░░</p>
      </SHTextContainer>
    </FlexContainer>
  );
}
