// Opera, Chrome, Edge, Blisk: default
// IE: not tested
export default {
  default: {
    mobileFontSize: '0.25rem',
    mobileLineHeight: '0.3rem',
    laptopFontSize: '0.3rem',
    laptopLineHeight: '0.37rem',
  },
  safari: {
    mobileFontSize: '0.2rem',
    mobileLineHeight: '0.35rem',
    laptopFontSize: '0.23rem',
    laptopLineHeight: '0.4rem',
    desktopFontSize: '0.23rem',
    desktopLineHeight: '0.35rem',
  },
  firefox: {
    mobileFontSize: '0.25rem',
    mobileLineHeight: '0.28rem',
    laptopFontSize: '0.3rem',
    laptopLineHeight: '0.33rem',
  },
  IE: {
    mobileFontSize: '0.25rem',
    mobileLineHeight: '0.22rem',
    laptopFontSize: '0.3rem',
    laptopLineHeight: '0.25rem',
  },
};
