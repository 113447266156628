import axios from './authIPAxios';
import getOptions from '../utils/services.utils';
import { Options } from '../interfaces/services.interface';

export default async function postSuspiciousMessage(accessToken: string): Promise<unknown> {
  const endpoint = '/salvadordali/';
  const options: Options = getOptions(accessToken);

  try {
    const res = await axios(options).post(endpoint, JSON.stringify({ job: 'salva.py is sending data to the server' }));
    return res;
  } catch (error) {
    return error.response;
  }
}
