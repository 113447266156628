interface Description {
  help?: string;
  cd?: string;
  clear?: string;
  pwd?: string;
  ls?: string;
  cat?: string;
  login?: string;
  ps?: string;
  open?: string;
  mv?: string;
  sudo?: string;
  kill?: string;
  ufw?: string;
  editor?: string;
  restart?: string;
  ranking?: string;
  exit?: string;
}

export interface CommandInfo {
  commands: string[];
  description: Description;
}

const initialCmd: CommandInfo = {
  commands: ['help', 'cd', 'clear', 'pwd', 'ls', 'cat', 'ranking', 'exit'],
  description: {},
};

const baseCmd: CommandInfo = {
  commands: ['help', 'cd', 'clear', 'pwd', 'ls', 'cat', 'login', 'open', 'mv', 'sudo', 'ps', 'kill', 'ufw', 'editor', 'restart', 'ranking', 'exit'],
  description: {
    help: 'help - Provide users with specific information about a command.',
    cd: 'cd - Change working directory.',
    clear: 'clear - Clean the screen leaving a new command prompt ready.',
    pwd: 'pwd - Display the path of your current working directory.',
    ls: 'ls - List out information about files and directories in a directory.',
    cat: 'cat - List contents in a file.',
    login: 'login - Generate a set of passwords.',
    ps: `ps - Displays information about processes that are bound by the controlling terminal.\nTo see the current state of system’s running processes, use command option: <b>ps aux</b>`,
    open: 'open - Opens audio file.',
    mv: 'mv - Moves files from one location to another.\nUsage: mv [filename] [destination_directory]',
    sudo: 'sudo - Runs command with the security privilege.\nUsage: sudo [command]',
    kill: 'kill - Stops a given process.\nUsage: kill <PID>',
    ufw: 'ufw - Sets up a firewall.\nOptions:\n<b>ufw status verbose</b>: check ufw status\n<b>ufw enable</b>: turn on the firewall',
    editor: 'editor - Edit text file',
    restart: 'restart - Restart the machine',
    ranking: 'ranking - Ranking dashboard of the game’s participants',
    exit: 'exit - Exit the machine',
  },
};

const guestCmd: CommandInfo = {
  commands: ['login', 'ps'],
  description: {
    login: 'login - Generate a set of passwords.',
    ps: `ps - Displays information about processes that are bound by the controlling terminal.\nTo see the current state of system’s running processes, use command option: <b>ps aux</b>`,
  },
};

const loginUserCmd: CommandInfo = {
  commands: ['login', 'open', 'mv', 'ps'],
  description: {
    login: 'help: the “login” operation is no longer valid.',
    open: 'open - Opens audio file.',
    mv: 'mv - Moves files from one location to another.\nUsage: mv [filename] [destination_directory]',
    ps: `ps - Displays information about processes that are bound by the controlling terminal.\nTo see the current state of system’s running processes, use command option: <b>ps aux</b>`,
  },
};

const adminCmd: CommandInfo = {
  commands: ['login', 'open', 'sudo', 'mv', 'ps', 'kill', 'ufw', 'editor', 'restart'],
  description: {
    open: 'open - Opens audio file.',
    mv: 'mv - Moves files from one location to another.\nUsage: mv [filename] [destination_directory]',
    sudo: 'sudo - Runs command with the security privilege.\nUsage: sudo [command]',
    kill: 'kill - Stops a given process.\nUsage: kill <PID>',
    ps: `ps - Displays information about processes that are bound by the controlling terminal.\nTo see the current state of system’s running processes, use command option: <b>ps aux</b>`,
    ufw: 'ufw - Sets up a firewall.\nOptions:\n<b>ufw status verbose</b>: check ufw status\n<b>ufw enable</b>: turn on the firewall',
    editor: 'editor - Edit text file',
    restart: 'restart - Restart the machine',
  },
};

export { initialCmd, baseCmd, guestCmd, loginUserCmd, adminCmd };
