const colors: { [key: string]: string } = {
  black: 'black',
  white: 'white',
  red: 'red',
  yellow: '#ffed00',
  aqua: '#00fff5',
  lightgray: '#e2e2e2',

  textColor: 'white',
  resultColor: 'white',

  logoColor: '#0061d6',
  shLogoColor: '#004aa5',
};

export default colors;
