import {
  PATH_FULL_DOTS,
  PATH_OND_DOT_SLASH,
  PATH_DOTS_SLASH,
  PATH_DOTS_SLASH_TWO_WORD,
  PATH_DOTS_SLASH_TWO_WORD_1,
  PATH_DOTS_SLASH_TWO_WORD_2,
  PATH_DOTS_SLASH_ONE_WORD,
  PATH_DOTS_SLASH_DOTS_WORD,
} from '../constants/regex/path.regex';

function lsWithFullDots(actualPath, parameters, currentPath, parameter) {
  switch (true) {
    case parameter === '.': {
      actualPath.push(currentPath);
      parameters.push(parameter);
      break;
    }
    case parameter === '..': {
      actualPath.push('');
      parameters.push(parameter);
      break;
    }
    default: {
      const divider = parameter.length - 2;
      let dotPath = '..';
      for (let i = 0; i < divider; i += 1) {
        dotPath += '/..';
      }
      actualPath.push('');
      parameters.push(dotPath);
      break;
    }
  }
  return { actualPath, parameters };
}

function lsWithDotsSlash(actualPath, parameters, currentPath, parameter) {
  /*
   * regex for "[one or two dots then slash character],
   * on [repeat], or [ends with one or two dots]"
   */
  parameters.push(parameter);
  switch (true) {
    case parameter === './':
      // stays same folder
      actualPath.push(currentPath);
      break;
    case PATH_OND_DOT_SLASH.test(parameter):
      /*
       * regex for "[one dot then slash character],
       * on [repeat], or [ends with one dot]"
       */
      actualPath.push(currentPath);
      break;
    case parameter === '../':
      // back by one: case is always to root since hierachy only has 2 levels
      actualPath.push('');
      break;
    default:
      // back by multiple
      actualPath.push('');
      break;
  }
  return { actualPath, parameters };
}

function lsWithDotsSlashAndTwoWord(actualPath, parameters, currentPath, parameter) {
  const pathArr = parameter.split('/');
  const subFolder = pathArr[pathArr.length - 2];
  let count = 0;
  let countTwoDots = 0;
  pathArr.forEach((item) => {
    if (item === '.') {
      count += 1;
    }
    if (item === '..') {
      countTwoDots += 1;
    }
  });
  if (currentPath !== '' && count === 1 && countTwoDots === 0) {
    actualPath.push(currentPath);
  } else {
    actualPath.push(subFolder);
  }
  parameters.push(parameter);
  return { actualPath, parameters };
}

function lsWithDotsSlashAndAWord(actualPath, parameters, currentPath, parameter) {
  const pathArr = parameter.split('/');
  const subFolder = pathArr[pathArr.length - 1];
  parameters.push(parameter);
  let count = 0;
  let countTwoDots = 0;
  pathArr.forEach((item) => {
    if (item === '.') {
      count += 1;
    }
    if (item === '..') {
      countTwoDots += 1;
    }
  });
  if (currentPath !== '' && count === 1 && countTwoDots === 0) {
    actualPath.push(currentPath);
  } else {
    actualPath.push(subFolder);
  }
  return { actualPath, parameters };
}

function lsWithDotsSlashAndDotsWord(actualPath, parameters, currentPath, parameter) {
  const splitDotParameters = parameter.split('/');
  /**
   * case 1: 'ls ../' at not-root, 'ls ./' at root
   * case 2: 'ls ./' at not-root
   * case 3: other cases that matches regex will by default be null, since root is highest
   */
  parameters.push(parameter);
  if (
    (currentPath !== '' && splitDotParameters.length === 2 && splitDotParameters[0] === '..') ||
    (currentPath === '' && splitDotParameters.length === 2 && splitDotParameters[0] === '.')
  ) {
    actualPath.push(splitDotParameters[1]);
  } else if (currentPath !== '' && splitDotParameters.length === 2 && splitDotParameters[0] === '.') {
    actualPath.push(`${currentPath}/${splitDotParameters[1]}`);
  } else {
    actualPath.push(splitDotParameters[splitDotParameters.length - 1]);
  }

  return { actualPath, parameters };
}

function lsWithDotsOther(actualPath, parameters, currentPath, parameter) {
  parameters.push(parameter);
  if (currentPath !== '') {
    actualPath.push(currentPath);
  } else {
    actualPath.push(parameter);
  }
  return { actualPath, parameters };
}

function lsWithDots(currentPath, originalParameters) {
  let parameters = [];
  let actualPath = [];
  originalParameters.forEach((parameter) => {
    let result = {};
    if (PATH_FULL_DOTS.test(parameter)) {
      result = lsWithFullDots(actualPath, parameters, currentPath, parameter);
    } else if (PATH_DOTS_SLASH.test(parameter)) {
      result = lsWithDotsSlash(actualPath, parameters, currentPath, parameter);
    } else if (PATH_DOTS_SLASH_TWO_WORD.test(parameter)) {
      result = lsWithDotsSlashAndTwoWord(actualPath, parameters, currentPath, parameter);
    } else if (PATH_DOTS_SLASH_ONE_WORD.test(parameter)) {
      result = lsWithDotsSlashAndAWord(actualPath, parameters, currentPath, parameter);
    } else if (PATH_DOTS_SLASH_DOTS_WORD.test(parameter)) {
      result = lsWithDotsSlashAndDotsWord(actualPath, parameters, currentPath, parameter);
    } else if (PATH_DOTS_SLASH_TWO_WORD_1.test(parameter)) {
      result = lsWithDotsSlashAndTwoWord(actualPath, parameters, currentPath, parameter);
    } else if (PATH_DOTS_SLASH_TWO_WORD_2.test(parameter)) {
      result = lsWithDotsSlashAndTwoWord(actualPath, parameters, currentPath, parameter);
    } else {
      result = lsWithDotsOther(actualPath, parameters, currentPath, parameter);
    }
    parameters = [...result.parameters];
    actualPath = [...result.actualPath];
  });
  return {
    actualPath,
    parameters,
  };
}

export {
  lsWithDotsOther,
  lsWithFullDots,
  lsWithDotsSlashAndAWord,
  lsWithDotsSlashAndTwoWord,
  lsWithDotsSlashAndDotsWord,
  lsWithDotsSlash,
  lsWithDots,
};
