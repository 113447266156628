import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getCommandFromTextStrategy from '../../../../utils/getCmdClass.utils';
import Input from '../../../../components/Input';
import { PromptContainer } from '../../styles';
import getUsableCommands from '../../../../utils/command.utils';
import { handleOnClickFocusCallback } from '../../../../utils/input.callback';
import { setSecretFiles } from '../../../../redux/actions/directory.actions';
import roleMapping from '../../../../constants/user.constants';
import addToTypingACommandEvent from '../../../../utils/dataLayer/typingACommand';

const InputContainers = React.forwardRef((props, ref) => {
  const inputRef = ref;
  const dispatch = useDispatch();
  const { terminal, emailPos, passwordPos, usernamePos } = useSelector((state) => state.terminalReducer);
  const { directories } = useSelector((state) => state.directoryReducer);
  const { accessToken, userRole } = useSelector((state) => state.userReducer);
  const { terminalSize, directoryString, commandAndResults } = terminal;
  const {
    isInputEmail,
    isInputPassword,
    isInputUsername,
    setIsInputEmail,
    setIsInputPassword,
    setIsInputUsername,
    updateTerminal,
    renderEmail,
    renderPassword,
    renderUsername,
    generateEditor,
  } = props;

  const [commandsPos, setCommandPos] = useState(commandAndResults.trackableCommands.length);
  const [inputFromUser, setInputFromUser] = useState('');
  const [key, setKey] = useState('');

  useEffect(() => {
    setCommandPos(commandAndResults.trackableCommands.length);
  }, [commandAndResults]);

  const createArrayWithKeyword = (keyword, array, userInput) => {
    let keys = [];
    let input = userInput;
    if (keyword !== '') {
      input = keyword;
    } else {
      setKey(userInput);
    }
    if (input === '' || keyword === 'empty') {
      keys = array;
      setKey('empty');
    } else {
      array.forEach((word) => {
        if (input === word.slice(0, input.length)) {
          keys.push(word);
        }
      });
    }
    return keys;
  };

  const trackKeywordInArray = (keyword, array, userInput) => {
    if (!array) return userInput;
    const arr = createArrayWithKeyword(keyword, array, userInput);
    if (arr.length === 0) {
      return userInput;
    }
    for (let i = 0; i < arr.length; i += 1) {
      if (userInput === arr[i] && i !== arr.length - 1) {
        return arr[i + 1];
      }
    }
    return arr[0];
  };

  function handleOutputOnTab(arr, leaf, cmd, args, root, lastCharacter) {
    if (inputRef.current.textContent.slice(-1) === ' ') {
      if (arr !== '' && leaf === '') {
        inputRef.current.textContent = `${cmd} ${arr.trim()} ${args[args.length - 1]} ${trackKeywordInArray(key, root, '')}`;
      } else if (arr === '' && leaf === '') {
        inputRef.current.textContent = `${cmd} ${args[args.length - 1]} ${trackKeywordInArray(key, root, '')}`;
      }
      handleOnClickFocusCallback(inputRef);
    } else if (inputRef.current.textContent.slice(-1) === '/' && inputRef.current.textContent.slice(-2, -1) !== ' ') {
      if (arr !== '' && leaf !== '') {
        inputRef.current.textContent = `${cmd} ${arr.trim()} ${leaf}${trackKeywordInArray(key, root, '')}`;
      } else if (arr === '' && leaf !== '') {
        inputRef.current.textContent = `${cmd} ${leaf}${trackKeywordInArray(key, root, '')}`;
      }
      handleOnClickFocusCallback(inputRef);
    } else {
      if (arr !== '' && leaf === '') {
        inputRef.current.textContent = `${cmd} ${arr.trim()} ${trackKeywordInArray(key, root, args[args.length - 1])}`;
      } else if (arr === '' && leaf === '') {
        inputRef.current.textContent = `${cmd} ${trackKeywordInArray(key, root, args[args.length - 1])}`;
      } else if (arr !== '' && leaf !== '') {
        inputRef.current.textContent = `${cmd} ${arr.trim()} ${leaf}${trackKeywordInArray(key, root, lastCharacter)}`;
      } else {
        inputRef.current.textContent = `${cmd} ${leaf}${trackKeywordInArray(key, root, lastCharacter)}`;
      }
      handleOnClickFocusCallback(inputRef);
    }
  }

  async function handleTabComplete(cmd, args) {
    const listArgForUfw = ['status verbose', 'enable'];
    const listArgForPs = ['aux'];
    let root = '';
    let arr = '';
    let leaf = '';
    let lastCharacter = '';
    let leafs = '';

    if (!cmd) {
      const commandStrategy = getCommandFromTextStrategy(cmd, args, userRole, 'tab');
      setIsInputEmail([...isInputEmail, false]);
      setIsInputPassword([...isInputPassword, false]);
      setIsInputUsername([...isInputUsername, false]);
      updateTerminal(commandStrategy);
    } else if (cmd && args.length === 0 && inputRef.current.textContent.slice(-1) !== ' ') {
      const { commands } = getUsableCommands(userRole);
      inputRef.current.textContent = trackKeywordInArray(key, commands, cmd);
      handleOnClickFocusCallback(inputRef);
    } else if (cmd && args.length === 0 && inputRef.current.textContent.slice(-1) === ' ') {
      if (cmd === 'sudo') {
        const { commands } = getUsableCommands(userRole);
        inputRef.current.textContent = `${cmd} ${trackKeywordInArray(key, commands, '')}`;
        handleOnClickFocusCallback(inputRef);
        return;
      }
      if (cmd === 'help') {
        const { commands } = getUsableCommands(userRole);
        inputRef.current.textContent = `${cmd} ${trackKeywordInArray(key, commands, '')}`;
        handleOnClickFocusCallback(inputRef);
        return;
      }
      if (cmd === 'ufw') {
        inputRef.current.textContent = `${cmd} ${trackKeywordInArray(key, listArgForUfw, '')}`;
        handleOnClickFocusCallback(inputRef);
        return;
      }
      if (cmd === 'ps') {
        inputRef.current.textContent = `${cmd} ${trackKeywordInArray(key, listArgForPs, '')}`;
        handleOnClickFocusCallback(inputRef);
        return;
      }
      root = Object.keys(directories);
      if (directoryString[directoryString.length - 1]) {
        root = directories[directoryString[directoryString.length - 1]];
      }
      inputRef.current.textContent = `${cmd} ${trackKeywordInArray(key, root, '')}`;
      handleOnClickFocusCallback(inputRef);
    } else if (cmd && args.length > 0) {
      if (cmd === 'ufw') {
        let userInput = '';
        if (args.length === 1 && args[0] === 'enable') {
          userInput = 'enable';
        } else if (args.length === 2 && args[0] === 'status' && args[1] === 'verbose') {
          userInput = 'status verbose';
        }
        if (userInput !== '') {
          inputRef.current.textContent = `${cmd} ${trackKeywordInArray(key, listArgForUfw, userInput)}`;
        } else {
          inputRef.current.textContent = `${cmd} ${trackKeywordInArray(key, listArgForUfw, args[args.length - 1])}`;
        }
        handleOnClickFocusCallback(inputRef);
        return;
      }
      if (cmd === 'ps') {
        inputRef.current.textContent = `${cmd} ${trackKeywordInArray(key, listArgForPs, args[args.length - 1])}`;
        handleOnClickFocusCallback(inputRef);
        return;
      }
      root = Object.keys(directories);
      if (directoryString[directoryString.length - 1]) {
        root = directories[directoryString[directoryString.length - 1]];
      }
      if (args.length > 1) {
        for (let i = 0; i < args.length; i += 1) {
          if (i !== args.length - 1) {
            arr = `${arr} ${args[i]}`;
          }
        }
      }
      if (inputRef.current.textContent.slice(-1) !== ' ') {
        leafs = args[args.length - 1].split('/');
        if (leafs.length === 2 && leafs[leafs.length - 1] !== '') {
          lastCharacter = leafs.pop();
          leaf = leafs.toString();
          leaf = `${leaf.replaceAll(',', '/')}/`;
        } else if (leafs.length === 2 && inputRef.current.textContent.slice(-1) === '/') {
          leaf = `${leafs[0]}/`;
        }
      }
      root = Object.keys(directories);
      if (leaf !== '') {
        root = directories[leaf.slice(0, -1)];
      } else if (directoryString[directoryString.length - 1]) {
        root = directories[directoryString[directoryString.length - 1]];
      }
      if (cmd === 'sudo' && args.length >= 1) {
        if (args[0] === 'ufw' && args.length >= 2) {
          let userInput = '';
          if (args.length === 2 && args[1] === 'enable') {
            userInput = 'enable';
          } else if (args.length === 3 && args[1] === 'status' && args[2] === 'verbose') {
            userInput = 'status verbose';
          }
          if (userInput !== '') {
            inputRef.current.textContent = `${cmd} ufw ${trackKeywordInArray(key, listArgForUfw, userInput)}`;
          } else {
            inputRef.current.textContent = `${cmd} ufw ${trackKeywordInArray(key, listArgForUfw, args[args.length - 1])}`;
          }
          handleOnClickFocusCallback(inputRef);
          return;
        }
        if (args[0] === 'ufw' && inputRef.current.textContent.slice(-1) === ' ') {
          inputRef.current.textContent = `${cmd} ufw ${trackKeywordInArray(key, listArgForUfw, '')}`;
          handleOnClickFocusCallback(inputRef);
          return;
        }
        if (args[0] === 'help' && args.length >= 2) {
          const { commands } = getUsableCommands(userRole);
          inputRef.current.textContent = `${cmd} help ${trackKeywordInArray(key, commands, args[args.length - 1])}`;
          handleOnClickFocusCallback(inputRef);
          return;
        }
        if (args[0] === 'help' && inputRef.current.textContent.slice(-1) === ' ') {
          const { commands } = getUsableCommands(userRole);
          inputRef.current.textContent = `${cmd} help ${trackKeywordInArray(key, commands, '')}`;
          handleOnClickFocusCallback(inputRef);
          return;
        }
        if (args[0] === 'ps' && args.length >= 2) {
          inputRef.current.textContent = `${cmd} ps ${trackKeywordInArray(key, listArgForPs, args[args.length - 1])}`;
          handleOnClickFocusCallback(inputRef);
          return;
        }
        if (args[0] === 'ps' && inputRef.current.textContent.slice(-1) === ' ') {
          inputRef.current.textContent = `${cmd} ps ${trackKeywordInArray(key, listArgForPs, '')}`;
          handleOnClickFocusCallback(inputRef);
          return;
        }
        if (args.length === 1 && inputRef.current.textContent.slice(-1) !== ' ') {
          if (inputRef.current.textContent.slice(-1) !== ' ') {
            const { commands } = getUsableCommands(userRole);
            inputRef.current.textContent = `${cmd} ${trackKeywordInArray(key, commands, args[0])}`;
            handleOnClickFocusCallback(inputRef);
          }
          return;
        }
        let directoriesTmp = '';
        if (userRole === roleMapping.admin) {
          try {
            const res = await dispatch(setSecretFiles(accessToken, true));
            const files = res.map((result) => result.name);
            directoriesTmp = { ...directories, blackhat: files };
          } catch (err) {
            directoriesTmp = { ...directories };
          }
        }
        if (directoriesTmp === '') {
          directoriesTmp = directories;
        }
        if (args.length >= 2 || (args.length === 1 && inputRef.current.textContent.slice(-1) === ' ')) {
          root = Object.keys(directories);
          if (leaf !== '') {
            root = directoriesTmp[leaf.slice(0, -1)];
          } else if (directoryString[directoryString.length - 1]) {
            root = directoriesTmp[directoryString[directoryString.length - 1]];
          }
          handleOutputOnTab(arr, leaf, cmd, args, root, lastCharacter);
        }
        return;
      }
      if (cmd === 'help' && args.length >= 1) {
        if (args.length === 1 && inputRef.current.textContent.slice(-1) !== ' ') {
          if (inputRef.current.textContent.slice(-1) !== ' ') {
            const { commands } = getUsableCommands(userRole);
            inputRef.current.textContent = `${cmd} ${trackKeywordInArray(key, commands, args[args.length - 1])}`;
            handleOnClickFocusCallback(inputRef);
          }
        }
        return;
      }
      handleOutputOnTab(arr, leaf, cmd, args, root, lastCharacter);
    }
  }

  function addToDataLayer(cmdStrategy) {
    if (cmdStrategy.command === 'sudo') {
      addToTypingACommandEvent(
        cmdStrategy.command,
        userRole,
        cmdStrategy.sudoCmd ? cmdStrategy.parameters : cmdStrategy.parameters.slice(1),
        cmdStrategy.sudoCmd || cmdStrategy.parameters[0]
      );
    } else {
      addToTypingACommandEvent(cmdStrategy.command, userRole, cmdStrategy.parameters);
    }
  }

  async function handleCommand(commandText, exceptions) {
    const commandArgs = commandText.replace(/^\s+|\s+$/g, '').split(/\s+/);
    const cmd = commandArgs[0];
    commandArgs.shift();
    const args = commandArgs;
    let commandStrategy = getCommandFromTextStrategy(cmd, args, userRole, null, commandText);
    const { cmdStrategy } = commandStrategy;
    if (exceptions === 'tab') {
      if (!cmd) {
        commandStrategy = getCommandFromTextStrategy(cmd, args, userRole, 'tab');
        setIsInputEmail([...isInputEmail, false]);
        setIsInputPassword([...isInputPassword, false]);
        setIsInputUsername([...isInputUsername, false]);
        updateTerminal(commandStrategy);
      } else {
        handleTabComplete(cmd, args);
      }
    } else if (exceptions === 'breakTabComplete') {
      setKey('');
      setCommandPos(commandAndResults.trackableCommands.length);
    } else if ((cmdStrategy.command === 'sudo' && cmdStrategy.sudoCmd === 'editor') || cmdStrategy.command === 'editor') {
      addToDataLayer(cmdStrategy, userRole);
      setIsInputEmail([...isInputEmail, false]);
      setIsInputPassword([...isInputPassword, false]);
      setIsInputUsername([...isInputUsername, false]);
      generateEditor(commandStrategy);
      setKey('');
    } else {
      addToDataLayer(cmdStrategy, userRole);
      setIsInputEmail([...isInputEmail, false]);
      setIsInputPassword([...isInputPassword, false]);
      setIsInputUsername([...isInputUsername, false]);
      updateTerminal(commandStrategy);
      setKey('');
    }
  }

  function handleArrowUp() {
    const { trackableCommands } = commandAndResults;
    if (trackableCommands.length !== 0) {
      if (commandsPos === 0) {
        const firstCommand = trackableCommands[0];
        inputRef.current.textContent = firstCommand;
      } else {
        const sum = commandsPos - 1;
        inputRef.current.textContent = trackableCommands[sum];
        setCommandPos(sum);
      }
      handleOnClickFocusCallback(inputRef);
    }
  }

  function handleArrowDown() {
    const { trackableCommands } = commandAndResults;
    if (commandsPos === trackableCommands.length) {
      setInputFromUser(inputRef.current.textContent);
    }
    if (trackableCommands.length !== 0) {
      if (commandsPos !== trackableCommands.length) {
        const sum = commandsPos + 1;
        inputRef.current.textContent = trackableCommands[sum];
        setCommandPos(sum);
        if (inputRef.current.textContent === '') {
          inputRef.current.textContent = inputFromUser;
        }
      }
      handleOnClickFocusCallback(inputRef);
    }
  }

  function renderTerminal() {
    const { results } = commandAndResults;
    const { size, offset } = terminalSize;
    const newArray = [];

    for (let idx = 0; idx < size; idx += 1) {
      const inputEle = (
        <div key={`input-container-${idx}-${offset}`}>
          <Input
            type="command"
            ref={inputRef}
            onInput={setInputFromUser}
            onHandleCommand={handleCommand}
            onHandleTab={handleCommand}
            onControlCPressed={handleCommand}
            onArrowUpPressed={handleArrowUp}
            onArrowDownPressed={handleArrowDown}
            textHolder={!directoryString[idx] ? 'ServerHeist $ ' : `ServerHeist:${directoryString[idx]} $ `}
          />
          <PromptContainer>{results[idx]}</PromptContainer>
          {isInputEmail[idx] && renderEmail(emailPos[idx])}
          {isInputPassword[idx] && renderPassword(passwordPos[idx])}
          {isInputUsername[idx] && renderUsername(usernamePos[idx])}
        </div>
      );
      newArray.push(inputEle);
    }
    return newArray;
  }

  return <>{renderTerminal()}</>;
});

export default InputContainers;
