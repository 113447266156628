import { ReactNode } from 'react';

const CdFalseArgs = ({ argument }: { argument: string }): ReactNode => <>cd: {argument}: No such file or directory</>;

const CdFolderArg = ({ argument }: { argument: string }): ReactNode => <>{argument}</>;

const CdCorrectArg = ({ currentPath }: { currentPath: string }): ReactNode => <>{currentPath}</>;

const CdFileArg = ({ argument }: { argument: string }): ReactNode => <>cd: {argument}: Not a directory </>;

const CdAccessDeny = ({ argument }: { argument: string }): ReactNode => <>cd: {argument}: Access denied</>;

export { CdFalseArgs, CdFolderArg, CdCorrectArg, CdFileArg, CdAccessDeny };
