/* Definition: Switch to the current command in case the user try to
 * inject 'contenteditable' property to the html on the browser
 */
const switchFocusToFinalCommandCallback = (inputRef) => (e) => {
  e.preventDefault();
  inputRef?.current?.focus();
};

const removePreviousInputEvent = (inputRef) => {
  const previousCommand = inputRef?.current;
  previousCommand.removeAttribute('contentEditable');
  previousCommand.removeAttribute('spellCheck');
  previousCommand.removeAttribute('autoCapitalize');
  previousCommand.removeAttribute('autoComplete');
};

const resetCaretPositionCallback = (inputRef) => {
  const currentCommand = inputRef?.current;
  const { length } = currentCommand?.textContent || 0;
  return currentCommand.textContent[length - 1];
};

const handleKeyUpCallback = (inputRef) => {
  const currentCommand = inputRef?.current;
  let caretOffset = 0;
  if (typeof window.getSelection !== 'undefined') {
    const range = window.getSelection().getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(currentCommand);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    caretOffset = preCaretRange.toString().length;
  } else if (typeof document.selection !== 'undefined' && document.selection.type !== 'Control') {
    const textRange = document.selection.createRange();
    const preCaretTextRange = document.body.createTextRange();
    preCaretTextRange.moveToElementText(currentCommand);
    preCaretTextRange.setEndPoint('EndToEnd', textRange);
    caretOffset = preCaretTextRange.text.length;
  }
  return currentCommand.textContent[caretOffset] || '';
};

const inputCallbackCreator = (inputRef, textContent = '') => {
  const currentCommand = inputRef?.current;
  currentCommand.focus();
  if (textContent === '' || !textContent) {
    currentCommand.textContent = '';
  } else {
    currentCommand.textContent = textContent;
  }

  currentCommand.setAttribute('contenteditable', true);
  const switchFocusToFinalCommand = (e) => switchFocusToFinalCommandCallback(inputRef)(e);
  const resetCaretPosition = () => resetCaretPositionCallback(inputRef);

  currentCommand.addEventListener('click', resetCaretPosition);
  currentCommand.addEventListener('click', switchFocusToFinalCommand);

  return () => {
    currentCommand.removeEventListener('click', switchFocusToFinalCommand);
    currentCommand.removeEventListener('click', resetCaretPosition);
  };
};

/* Definition: Handle pasting to only paste plain text into the input container */
const handlePasteCallback = (e) => {
  e.preventDefault();
  document.execCommand('inserttext', false, e.clipboardData.getData('text/plain'));
};

const handleOnClickFocusCallback = (inputRef) => {
  const ele = inputRef?.current;
  const inputFromUser = ele?.textContent;
  const start = window.getSelection();
  const range = document.createRange();
  if (inputFromUser.length > 0) {
    range.setStart(ele, 1);
    range.setEnd(ele, 1);
    start.removeAllRanges();
    start.addRange(range);
  } else {
    range.selectNodeContents(ele);
    start.removeAllRanges();
    start.addRange(range);
  }
};

export {
  switchFocusToFinalCommandCallback,
  inputCallbackCreator,
  handleKeyUpCallback,
  resetCaretPositionCallback,
  handlePasteCallback,
  handleOnClickFocusCallback,
  removePreviousInputEvent,
};
