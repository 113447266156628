import { SET_EMAIL, UPDATE_EMAIL_SIZE, UPDATE_EMAIL_CLEAR, UPDATE_EMAIL_CONTAINERS_SIZE } from '../constants/email.constants';

function setEmail(currentEmail) {
  return (dispatch) => {
    dispatch({
      type: SET_EMAIL,
      payload: {
        currentEmail,
      },
    });
  };
}

function updateEmailSize(emailTemplate) {
  const { emailPhase, emailHolder } = emailTemplate.data;
  return (dispatch) => {
    dispatch({
      type: UPDATE_EMAIL_SIZE,
      payload: {
        emailPhase,
        emailHolder,
        emailResult: emailTemplate.template(emailTemplate.data),
      },
    });
  };
}

function updateEmailClear() {
  return (dispatch) => {
    dispatch({
      type: UPDATE_EMAIL_CLEAR,
    });
  };
}

function updateEmailContainersSize() {
  return (dispatch) => {
    dispatch({
      type: UPDATE_EMAIL_CONTAINERS_SIZE,
    });
  };
}

export { setEmail, updateEmailSize, updateEmailClear, updateEmailContainersSize };
