import { directoryTree } from '../../constants/directotry.constants';
import { GridContainer } from '../../pages/MainPage/styles';

const LsDefault = ({ folderContent }) => {
  const pathItems = folderContent?.map((path) => <p key={`cmd-ls-${path.name}-${Math.floor(Math.random() * 100) + 1}`}>{path.name}</p>);
  return <GridContainer>{pathItems}</GridContainer>;
};

const LsNoFile = ({ parameter }) => <>ls: {parameter}: No such file or directory</>;

const LsUnauthorized = ({ parameter }) => <>ls: {parameter}: Access denied</>;

const LsMultipleFolder = ({ parameters, parameter, folderContent }) => {
  let pathItems = folderContent;
  if (folderContent && (folderContent.length !== 5 || !directoryTree.includes(folderContent[0].name))) {
    pathItems = folderContent?.sort((file1, file2) => file1.name.localeCompare(file2.name));
  }
  const pathItemsSorted = pathItems?.map((path) => <p key={`cmd-ls-${path.name}}-${Math.floor(Math.random() * 100) + 1}`}>{path.name}</p>);
  return (
    <div key={`cmd-ls-${parameter}-${Math.floor(Math.random() * 100) + 1}}`} style={{ marginBottom: `${parameters.length > 1 ? '1rem' : '0'}` }}>
      {parameters.length > 1 && <strong>{parameter}:</strong>}
      <GridContainer>{pathItemsSorted}</GridContainer>
    </div>
  );
};

const MultipleLsTemplate = ({ templateData }) => {
  const correctTemplateData = templateData.filter((template) => template.template === LsMultipleFolder);
  const falseTemplateData = templateData.filter((template) => template.template !== LsMultipleFolder);
  const finalTemplateData = falseTemplateData.concat(correctTemplateData);
  const results = finalTemplateData.map(({ template, data }, idx) => <div key={`cmd-multiple-ls-${idx}`}>{template(data)}</div>);
  return results;
};

const LsInnerFile = ({ file }) => <>{file}</>;

export { LsDefault, LsNoFile, LsUnauthorized, LsMultipleFolder, LsInnerFile, MultipleLsTemplate };
