import resetGTMDataLayer from './dataLayer.utils';

function addToRestartSuccessEvent(username, originalMilestones) {
  resetGTMDataLayer();
  const milestones = originalMilestones;
  if (originalMilestones && originalMilestones.length !== 0) {
    originalMilestones.forEach((milestone, idx) => {
      milestones[idx] = {
        milestone: milestone.milestone,
        time_record: milestone.time_record,
      };
    });
  }
  window.dataLayer.push({ event: 'restart_success', username, milestones });
}

export default addToRestartSuccessEvent;
