import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { TerminalPageContainer, TextContainer } from './styles';
import { handleOnClickFocusCallback } from '../../utils/input.callback';
import roleMapping from '../../constants/user.constants';
import InputContainers from './components/InputContainers.js';
import EmailContainers from './components/EmailContainers';
import PasswordContainers from './components/PasswordContainers';
import UsernameContainers from './components/UsernameContainers';
import LogoContainer from '../../components/LogoTextComponent';
import { updateCommandsAndResults, updateCurrentPrompt, updateEmptyTerminal, updateTerminalClear } from '../../redux/actions/terminal.actions';
import { updatePasswordClear, updatePasswordContainersSize } from '../../redux/actions/password.actions';
import { updateUsernameClear, updateUsernameContainersSize } from '../../redux/actions/username.actions';
import { clearUserData, updateUserRole } from '../../redux/actions/user.actions';
import { updateEmailClear } from '../../redux/actions/email.actions';
import { setDirectoryFolders } from '../../redux/actions/directory.actions';
import postSuspiciousMessage from '../../services/error.service';

const Editor = React.lazy(() => import('./components/Editor/Editor'));

let errorServiceInterval;

export default function TerminalPage() {
  const dispatch = useDispatch();
  const { terminal, isCleared } = useSelector((state) => state.terminalReducer);
  const { userRole, accessToken, refreshToken } = useSelector((state) => state.userReducer);
  const { directoryString } = terminal;
  const [isInputEmail, setIsInputEmail] = useState([]);
  const [isFileEditing, setIsFileEditing] = useState({
    isEditing: false,
    fileContent: '',
  });
  const [isInputPassword, setIsInputPassword] = useState([]);
  const [isInputUsername, setIsInputUsername] = useState([]);
  const [docTitle, setDocTitle] = useState('Server Heist');
  const [, setProcessKilled] = useState(false);
  const [currentCommandStrategy, setCurrentCommandStrategy] = useState(null);
  let inputRef = useRef(null);
  const editorRef = useRef(null);
  const containerRef = useRef(null);

  const errorCallback = React.useCallback(
    (killed) => {
      if (killed) {
        clearInterval(errorServiceInterval);
      }
      if (!killed) {
        errorServiceInterval = setInterval(async () => {
          await postSuspiciousMessage(accessToken);
        }, 5000);
      }
      return () => {
        clearInterval(errorServiceInterval);
      };
    },
    [isInputPassword]
  );

  function unauthorizedGuest(cmdStrategy, templateResult) {
    const guestCmd = ['cd', 'cat', 'ls'];
    const userCmd = ['login', 'sudo'];
    const sudoCmd = ['restart'];
    if (!guestCmd.includes(cmdStrategy.command) && !userCmd[0].includes(cmdStrategy.command) && !sudoCmd[0].includes(cmdStrategy.sudoCmd)) {
      return false;
    }

    if (guestCmd.includes(cmdStrategy.command) && templateResult.data?.accessDenied) {
      const currentCommand = inputRef.current.textContent;
      dispatch(updateCommandsAndResults(currentCommand, cmdStrategy, templateResult, 'email'));
      setIsInputEmail([...isInputEmail, true]);
      setIsInputPassword([...isInputPassword, false]);
      setIsInputUsername([...isInputUsername, false]);
      return true;
    }
    // Case: Operation denined, role does not have access (not guest)
    if (userCmd[0].includes(cmdStrategy.command) && templateResult.data?.unauth) {
      const currentCommand = inputRef.current.textContent;
      dispatch(updateCommandsAndResults(currentCommand, cmdStrategy, templateResult, 'password-error'));
      setIsInputPassword([...isInputPassword, false]);
      setIsInputUsername([...isInputUsername, false]);
      setIsInputEmail([...isInputEmail, false]);
      dispatch(updatePasswordContainersSize());
      dispatch(updateUsernameContainersSize());
      dispatch(updateEmptyTerminal());
      return true;
    }
    // Case: User is guest, and login opeartion is allowed
    if (
      userCmd[0].includes(cmdStrategy.command) &&
      templateResult.data?.accessDenied &&
      userRole === roleMapping.guest &&
      !templateResult.data.isGuest
    ) {
      const currentCommand = inputRef.current.textContent;
      dispatch(updateCommandsAndResults(currentCommand, cmdStrategy, templateResult, 'password'));
      setIsInputPassword([...isInputPassword, true]);
      setIsInputUsername([...isInputUsername, false]);
      setIsInputEmail([...isInputEmail, false]);
      errorCallback(false);
      return true;
    }
    // Case : User is admin, and restart session = true
    if (
      userCmd[1].includes(cmdStrategy.command) &&
      sudoCmd.includes(cmdStrategy.sudoCmd) &&
      userRole === roleMapping.admin &&
      templateResult.data?.isReset
    ) {
      const currentCommand = inputRef.current.textContent;
      dispatch(updateCommandsAndResults(currentCommand, cmdStrategy, templateResult, 'username'));
      setIsInputPassword([...isInputPassword, false]);
      setIsInputUsername([...isInputUsername, true]);
      setIsInputEmail([...isInputEmail, false]);
      return true;
    }

    return false;
  }

  function clearTerminalCommand(currentCommand, isClearCmd) {
    setIsInputEmail([]);
    setIsInputPassword([]);
    setIsInputUsername([]);
    dispatch(updateTerminalClear(currentCommand, isClearCmd));
    dispatch(updateEmailClear());
    dispatch(updatePasswordClear());
    dispatch(updateUsernameClear());
    inputRef = React.createRef();
  }

  async function updateTerminal({ cmdStrategy }) {
    const currentDirectoryString = directoryString[directoryString.length - 1];
    const { command, parameters } = cmdStrategy;
    if ((command === 'clear' && parameters.length === 0) || (command === 'sudo' && cmdStrategy.sudoCmd === 'clear' && parameters.length === 0)) {
      clearTerminalCommand(cmdStrategy.currentCommand, true);
    } else {
      const templateResult = await cmdStrategy.execCommand({ currentPath: currentDirectoryString, userRole, isAuthorized: false, accessToken });
      if (templateResult.data?.isResetSession) {
        // exiting or restarting
        dispatch(clearUserData(accessToken, refreshToken));
      } else if (templateResult.data?.isKilled) {
        // killing a process successfully
        setProcessKilled(templateResult.data?.isKilled);
        errorCallback(templateResult.data?.isKilled);
      }
      if (!unauthorizedGuest(cmdStrategy, templateResult)) {
        const currentCommand = inputRef.current.textContent;
        dispatch(updateCurrentPrompt(currentCommand, cmdStrategy, templateResult.template(templateResult.data), templateResult));
        if (templateResult.data.allowRoot) {
          dispatch(updateUserRole(accessToken, templateResult.data.allowRoot));
        }

        if (cmdStrategy.constructor.name !== 'ExceptionCommand') {
          const { command: cmd } = cmdStrategy;
          if (cmd === 'mv') {
            dispatch(setDirectoryFolders(userRole, accessToken, false));
          }
        }
      }
    }
  }

  function handleFileEditingDone() {
    updateTerminal(currentCommandStrategy.commandStrategy);
  }

  async function generateEditor(commandStrategy) {
    const currentDirectoryString = directoryString[directoryString.length - 1];
    const { cmdStrategy } = commandStrategy;
    const templateResult = await cmdStrategy.execCommand({ currentPath: currentDirectoryString, userRole, isAuthorized: false, accessToken });
    if (templateResult.data?.isFileEditor) {
      setIsFileEditing({
        isEditing: true,
        fileContent: templateResult.data?.fileContent,
      });
      setCurrentCommandStrategy({ commandStrategy, templateResult });
    } else {
      updateTerminal(commandStrategy);
    }
  }

  function renderEmail(emailPosition) {
    return <EmailContainers ref={inputRef} emailPosition={emailPosition} isInputEmail={isInputEmail} setIsInputEmail={setIsInputEmail} />;
  }

  function renderPassword(passwordPosition) {
    return (
      <PasswordContainers
        ref={inputRef}
        passwordPosition={passwordPosition}
        isInputPassword={isInputPassword}
        setIsInputPassword={setIsInputPassword}
      />
    );
  }

  function renderUsername(usernamePosition) {
    return (
      <UsernameContainers
        ref={inputRef}
        usernamePosition={usernamePosition}
        isInputUsername={isInputUsername}
        setIsInputUsername={setIsInputUsername}
      />
    );
  }

  function renderTerminal() {
    return (
      <InputContainers
        ref={inputRef}
        isInputEmail={isInputEmail}
        setIsInputEmail={setIsInputEmail}
        isInputPassword={isInputPassword}
        setIsInputPassword={setIsInputPassword}
        isInputUsername={isInputUsername}
        setIsInputUsername={setIsInputUsername}
        updateTerminal={updateTerminal}
        renderEmail={renderEmail}
        renderPassword={renderPassword}
        renderUsername={renderUsername}
        generateEditor={generateEditor}
        isFileEditing={isFileEditing}
      />
    );
  }

  useEffect(() => {
    dispatch(setDirectoryFolders(userRole, accessToken, false));
  }, [accessToken, userRole]);

  function setDocTitleLeave() {
    setDocTitle('Hey, come back!');
  }

  function setDocTitleIn() {
    setDocTitle('Server Heist');
  }

  useEffect(() => {
    window.addEventListener('blur', setDocTitleLeave);
    window.addEventListener('focus', setDocTitleIn);
    return () => {
      window.removeEventListener('blur', setDocTitleLeave);
      window.removeEventListener('focus', setDocTitleIn);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{docTitle}</title>
      </Helmet>
      {isFileEditing.isEditing && (
        <React.Suspense fallback={<></>}>
          <Editor
            ref={editorRef}
            setIsFileEditing={setIsFileEditing}
            isFileEditing={isFileEditing}
            onFileEditingDone={handleFileEditingDone}
            accessToken={accessToken}
            templateResult={currentCommandStrategy?.templateResult}
          />
        </React.Suspense>
      )}

      <TerminalPageContainer
        isEditing={isFileEditing.isEditing}
        onMouseUp={() => {
          const sel = document.getSelection();
          if (sel.type === 'Caret' || sel.type === 'None') {
            handleOnClickFocusCallback(inputRef);
          }
        }}
      >
        <LogoContainer />
        {/* Intro container */}
        {!isCleared && (
          <TextContainer>
            <h3>Welcome to Server Heist!</h3>
            <h5>Warning!!! This server has been hacked!</h5>
            <p>We need you to infiltrate to reboot the server.</p>
            <p>But be aware, the server is corrupted with obstacles and infected elements.</p>
            <p>The worthy ones will be rewarded. Good luck!</p>
          </TextContainer>
        )}
        {/* Terminal container */}
        <div ref={containerRef}>{renderTerminal()}</div>
      </TerminalPageContainer>
    </>
  );
}
