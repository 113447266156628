import { ReactNode } from 'react';

interface ITestCase {
  execution_time: number;
  expected_output: never;
  input: never;
  status: string;
}

const UfwNotActive = (): ReactNode => <>Status: inactive</>;

const UfwActive = (): ReactNode => <>Status: active</>;

const UfwEnableNotKilled = (): ReactNode => <>The machine is not ready.</>;

const UfwFailedTestCase = ({
  testCases,
  numberHidden,
  numberTestCase,
  passHidden,
  passTestCase,
}: {
  testCases: ITestCase[];
  numberHidden: number;
  numberTestCase: number;
  passHidden: number;
  passTestCase: number;
}): ReactNode => {
  return (
    <>
      <p>Command cannot be operated because newbegin.py has not been resolved</p>
      <div>
        <p>Summary: </p>
        <div>
          <p>
            Pass {passTestCase} out of {numberTestCase} test cases.
          </p>
          <p>
            Pass {passHidden} out of {numberHidden} hidden test cases.
          </p>
        </div>
        <p>Evaluation: </p>
        <div>
          {testCases.map((testCase: ITestCase, index: number) => {
            return (
              <div>
                <p>Test case number {index + 1}:</p>
                <div>
                  <p> - Input: {testCase.input}</p>
                  <p> - Expected output: {testCase.expected_output}</p>
                  <p> - Status: {testCase.status}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const UfwCompilerError = ({ error }: { error: string }): ReactNode => {
  return (
    <>
      <p>Command cannot be operated because newbegin.py has not been resolved</p>
      <p>Error: {error}</p>
    </>
  );
};

const UfwResolved = (): ReactNode => <>Firewall is on</>;

const UfwFalseArg = ({ argument }: { argument: string }): ReactNode => <>ufw: {argument === '' ? '' : `${argument}: `}Operation not supported</>;

const UfwEmptyArg = (): ReactNode => <>ufw: Missing argument</>;

const UfwInvalid = (): ReactNode => <>ufw: Invalid command</>;

const UfwDenied = (): ReactNode => <>ufw: Access denied</>;

const UfwMissingArg = ({ argument }: { argument: string }): ReactNode => <>ufw: {argument}: Missing argument</>;

const FirewallException = (): ReactNode => <>Something went wrong while activating firewall. Please try again!</>;

export {
  UfwNotActive,
  UfwActive,
  UfwEnableNotKilled,
  UfwFailedTestCase,
  UfwCompilerError,
  UfwResolved,
  UfwFalseArg,
  UfwEmptyArg,
  UfwDenied,
  UfwInvalid,
  UfwMissingArg,
  FirewallException,
};
