import { emailHoldersList, emailProcess } from '../../constants/email.constants';
import { SET_EMAIL, UPDATE_EMAIL_SIZE, UPDATE_EMAIL_CLEAR, UPDATE_EMAIL_CONTAINERS_SIZE } from '../constants/email.constants';

const initialData = {
  currentEmail: '',
  emailResults: [[]],
  emailHolders: [[emailHoldersList.email]],
  emailSize: [1],
  emailPhase: emailProcess.initial,
};

const emailReducer = (state = initialData, action) => {
  switch (action.type) {
    case SET_EMAIL: {
      const { currentEmail } = action.payload;
      return {
        ...state,
        currentEmail,
      };
    }
    case UPDATE_EMAIL_SIZE: {
      const emailSize = [...state.emailSize];
      const emailPos = emailSize.length - 1;
      const size = emailSize[emailPos];
      const emailHolders = [...state.emailHolders];
      const emailResults = [...state.emailResults];

      let { emailPhase, emailResult, emailHolder } = action.payload;
      if (!emailHolder && emailHolder !== '') emailHolder = emailHolders[emailPos][emailHolders[emailPos].length - 1];
      if (!emailResult) emailResult = '';
      if (!emailPhase) emailPhase = state.emailPhase;

      emailResults[emailPos].push(emailResult);
      if (
        emailPhase === emailProcess.thirdInvalid ||
        emailPhase === emailProcess.finalValid ||
        emailPhase === emailProcess.expiredInvalid ||
        emailPhase === emailProcess.expiredInvalid
      ) {
        return {
          ...state,
          emailHolders,
          emailResults,
          emailSize,
          emailPhase,
        };
      }

      emailSize[emailPos] = size + 1;
      emailHolders[emailPos].push(emailHolder);
      return {
        ...state,
        emailHolders,
        emailResults,
        emailSize,
        emailPhase,
      };
    }
    case UPDATE_EMAIL_CONTAINERS_SIZE: {
      const emailSize = [...state.emailSize];
      const emailHolders = [...state.emailHolders];
      const emailResults = [...state.emailResults];

      emailSize.push(1);
      emailHolders.push([emailHoldersList.email]);
      emailResults.push([]);
      return {
        ...state,
        emailResults,
        emailHolders,
        emailSize,
        emailPhase: emailProcess.initial,
      };
    }
    case UPDATE_EMAIL_CLEAR: {
      return {
        ...state,
        emailResults: [[]],
        emailHolders: [[emailHoldersList.email]],
        emailSize: [1],
        emailPhase: emailProcess.initial,
      };
    }
    default:
      return { ...state };
  }
};

export default emailReducer;
