const GTM_ID = 'GTM-5QCPQ2H';

const resetGTMDataLayer = () => {
  if (window.dataLayer !== undefined && window.google_tag_manager !== undefined) {
    window.dataLayer.length = 0;
    window.google_tag_manager[GTM_ID]?.dataLayer?.reset();
  }
};

export default resetGTMDataLayer;
