import { restrictedFolder } from '../../constants/directotry.constants';
import { availableStatus, allStatusCode } from '../../constants/status.constants';
import { getDirectoryByPath } from '../../services/directory.service';
import { SET_DIRECTORY_FOLDERS, SET_INNER_FILES, SET_SECRET_FILES } from '../constants/directory.constants';

const { lsStatusCode } = allStatusCode;

const setDirectoryFolders = (userRole, accessToken, isRootAccess) => async (dispatch) => {
  try {
    const res = await getDirectoryByPath('', accessToken, isRootAccess);
    const directories = {};
    if (res && res.status === lsStatusCode.success) {
      const folders = res.data?.map((folder) => {
        directories[folder.name] = [];
        return { name: folder.name, role: folder.role };
      });
      const results = await Promise.all(
        folders.map(async (folder) => {
          if (folder.role <= userRole && folder.role < 3) {
            const fileResults = await getDirectoryByPath(folder.name, accessToken, isRootAccess);
            return fileResults;
          }
          return {
            data: [],
          };
        })
      );
      results.forEach((result, idx) => {
        if (result && result.status === lsStatusCode.success) {
          result.data?.forEach((fileName) => directories[folders[idx].name].push(fileName.name));
        }
      });
    }

    Object.keys(directories).forEach((key) => {
      let curDirArr = [...directories[key]];
      curDirArr = curDirArr.sort((itemA, itemB) => itemA.localeCompare(itemB));
      directories[key] = curDirArr;
    });

    await dispatch({
      type: SET_DIRECTORY_FOLDERS,
      payload: {
        directories,
      },
    });
    return res;
  } catch (err) {
    return {};
  }
};

const setSecretFiles = (accessToken, isRootAccess) => {
  return async (dispatch) => {
    try {
      const res = await getDirectoryByPath(restrictedFolder, accessToken, isRootAccess);
      dispatch({
        type: SET_SECRET_FILES,
        payload: {
          files: availableStatus.includes(res.status) ? res?.data : undefined,
        },
      });
      return res?.data;
    } catch (e) {
      return undefined;
    }
  };
};

const setInnerFiles = (folderName, files) => {
  return (dispatch) => {
    dispatch({
      type: SET_INNER_FILES,
      payload: {
        folderName,
        files,
      },
    });
  };
};

export { setDirectoryFolders, setInnerFiles, setSecretFiles };
