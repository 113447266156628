class CommandController {
  constructor() {
    this.cmdStrategy = null;
  }

  set strategy(strategyParam) {
    this.cmdStrategy = strategyParam;
  }

  get strategy() {
    return this.cmdStrategy;
  }

  execCommand(args) {
    if (!this.cmdStrategy.execCommand) {
      throw Object.assign(new Error('execCommand is not implemented'), { code: 404 });
    }
    return this.cmdStrategy.execCommand(args);
  }
}

export default CommandController;
