import { TableMini, TableLarge } from '../../pages/MainPage/styles';
import getFormattedDate from '../../utils/date.utils';
import processesKeys from '../../constants/process.constants';

const PsFalseArg = ({ argument }) => <>ps: {argument}: keyword not supported</>;

const PsNoArg = () => (
  <TableMini style={{ borderCollapse: 'collapse' }}>
    <thead>
      <tr>
        <th>PID</th>
        <th>TTY</th>
        <th>TIME</th>
        <th>CMD</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>2604</td>
        <td>ttys000</td>
        <td>0:00.02</td>
        <td>-zsh</td>
      </tr>
    </tbody>
  </TableMini>
);

const PsCorrectArg = ({ allProcesses }) => {
  let processes = [];
  if (allProcesses) {
    let sortedProcesses = allProcesses;
    sortedProcesses = sortedProcesses?.map((sorted) => {
      const tmpObj = {};
      processesKeys?.forEach((processKey) => {
        tmpObj[processKey] = sorted[processKey];
      });
      return tmpObj;
    });
    processes = sortedProcesses;
  }

  return (
    <TableLarge>
      <thead>
        <tr>
          {/* Table Headings */}
          {processesKeys.map((process) => {
            if (process === 'cpu' || process === 'mem') {
              return <th key={`th-process-${process}`}>%{process.toUpperCase()}</th>;
            }
            return <th key={`th-process-${process}`}>{process.toUpperCase()}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {processes?.map((process, idx) => {
          if (process) {
            const processKeys = Object.keys(process).map((key) => key);
            const processInfo = processKeys.map((key) => {
              // Value of "Started" key
              if (key === 'started') {
                return <td key={`td-process-${key}`}>{getFormattedDate(new Date(process[key]))}</td>;
              }
              // Value of key beside "Started"
              return <td key={`td-process-${key}`}>{process[key]}</td>;
            });
            return <tr key={`tr-process-${idx}`}>{processInfo}</tr>;
          }
          return <tr />;
        })}
      </tbody>
    </TableLarge>
  );
};

const PsErrorArg = () => <>Getting processes was not successful. Please try again!</>;

const PsTwoManyArg = () => <>ps aux: Too many arguments</>;

export { PsFalseArg, PsNoArg, PsCorrectArg, PsErrorArg, PsTwoManyArg };
