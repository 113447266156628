import roleMapping from '../constants/user.constants';
import { adminCmd, baseCmd, guestCmd, loginUserCmd } from '../constants/commands.constants';

function getUsableCommandsGuest(desc) {
  const tmpDesc = { ...desc };
  Object.keys(guestCmd.description).forEach((item) => {
    tmpDesc[item] = guestCmd.description[item];
  });
  return tmpDesc;
}

function getUsableCommandsUser(desc) {
  const tmpDesc = { ...desc };
  Object.keys(loginUserCmd.description).forEach((item) => {
    tmpDesc[item] = loginUserCmd.description[item];
  });
  return tmpDesc;
}

function getUsableCommandsAdmin(desc) {
  const tmpDesc = { ...desc };
  Object.keys(adminCmd.description).forEach((item) => {
    tmpDesc[item] = adminCmd.description[item];
  });
  return tmpDesc;
}

function getUsableCommands(userRole = 0) {
  const { commands, description } = baseCmd;
  const sortedCommands = commands.sort((itemA, itemB) => itemA.localeCompare(itemB));
  let desc = { ...description };
  if (userRole === roleMapping.guest) {
    desc = getUsableCommandsGuest(desc);
    return {
      commands: sortedCommands,
      description: desc,
    };
  }
  if (userRole === roleMapping.user) {
    desc = getUsableCommandsGuest(desc);
    desc = getUsableCommandsUser(desc);
    return {
      commands: sortedCommands,
      description: desc,
    };
  }
  if (userRole === roleMapping.admin) {
    desc = getUsableCommandsUser(desc);
    desc = getUsableCommandsAdmin(desc);
    return {
      commands: sortedCommands,
      description: desc,
    };
  }
  return { commands, description: desc };
}

export default getUsableCommands;
