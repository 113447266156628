import { UPDATE_USERNAME_SIZE, UPDATE_USERNAME_CLEAR, UPDATE_USERNAME_CONTAINERS_SIZE } from '../constants/username.constants';

function updateUsernameSize(usernameTemplate) {
  const { currentUsername, usernameHolder } = usernameTemplate.data;
  return (dispatch) => {
    dispatch({
      type: UPDATE_USERNAME_SIZE,
      payload: {
        currentUsername,
        usernameHolder,
        usernameResult: usernameTemplate.template(usernameTemplate.data),
        usernameFinal: usernameTemplate.data.usernameFinal,
      },
    });
  };
}

function updateUsernameClear() {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USERNAME_CLEAR,
    });
  };
}

function updateUsernameContainersSize() {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USERNAME_CONTAINERS_SIZE,
    });
  };
}

export { updateUsernameSize, updateUsernameClear, updateUsernameContainersSize };
